import React from 'react'
// @mui
import {Box, Container, Link, Typography} from '@mui/material'
import { RegisterForm } from '../components/Forms/RegisterForm'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import {GradientBackground, StyledContent, StyledRoot, StyledRootUpMD} from '../theme/standarts_styles'
import {AspexIcon} from "../constants";
import LinearProgress from "@mui/material/LinearProgress";
import {LoginForm} from "../components/Forms/LoginForm";
import {useTheme} from "@mui/material/styles";

const Register = () => {

  const location = useLocation()
  const currentLocation = location.pathname === '/register'
  const { t } = useTranslation()
  const theme = useTheme();

  return (
    <>
      <StyledRoot>
        <Container sx={{ padding: "0px !important" }}>
          <GradientBackground>
            <Box
              sx={{
                height: { xs: 14, sm: 14, md: 18, lg: 20, xl: 20 },
              }}
            >
              <AspexIcon
                color={theme.palette.primary.main}
                height="18"
              />
            </Box>

            <div>
              <Box
                sx={(theme) => ({
                  margin: { xs: 2, sm: 3, md: 4, lg: 6 },
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: "layout.main",
                  width: {
                    xs: "90vw",
                    sm: "80vw",
                    md: "50vw",
                    lg: "40vw",
                    xl: "30vw",
                  },
                  px: { xs: 3, sm: 4, md: 4 },
                  pb: { xs: 3, sm: 4, md: 4, lg: 6, xl: 8 },
                  pt: { xs: 2, sm: 2, md: 2, lg: 3, xl: 4 },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                })}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <LinearProgress color="secondary" sx={{opacity:'0.4', borderRadius:'90px', width: '13%'}} value={100} variant='determinate' />

                  <Typography
                    align="center" color="text.secondary" component="p"
                    sx={{
                      mb: {xs:3, sm: 3, md: 3, lg: 4, xl: 5},
                      mt: {xs:3, sm: 3, md: 3, lg: 4, xl: 5}
                    }}
                    variant="h4Wide"
                  >
                    {
                      currentLocation
                        ? t('src.pages.Register.registerPageText')
                        : t('src.pages.Register.changePasswordPageText')
                    }
                  </Typography>

                  <RegisterForm currentLocation={currentLocation}/>

                </Box>
              </Box>
            </div>

            <Typography gutterBottom mb={0} variant="body3">
              © 2023-2025, ASPEX
            </Typography>
          </GradientBackground>
        </Container>
      </StyledRoot>
    </>
  )
}

export default Register
