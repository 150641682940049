import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

const GREY = {
  0: "#FFFFFF",
  100: "rgba(0,0,0,0.1)",
  200: "rgba(0,0,0,0.2)",
  300: "rgba(0,0,0,0.3)",
  400: "rgba(0,0,0,0.4)",
  500: "rgba(0,0,0,0.5)",
  600: "rgba(0,0,0,0.6)",
  700: "rgba(0,0,0,0.7)",
  800: "rgba(0,0,0,0.8)",
  900: "rgba(0,0,0,0.9)",
};

const PRIMARY = {
  lighter: "#bbe1ff",
  light: "#009dff",
  main: "#067bf8",
  dark: "#1369e6",
  darker: "#1b48c7",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#F6F6F6",
  light: "#c5c5c5",
  main: "#737373",
  dark: "#525252",
  darker: "#404040",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const palette = {
  common: {
    black: "#000",
    white: "#fff",
    layout: "#fff",
  },
  layout: {
    main: "#fff",
    element: "#373737",
    default: GREY[200],
    accent_element: "#009dff",
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: "#EFEFEF",
  text: {
    primary: "#373737",
    secondary: GREY[600],
    disabled: GREY[400],
  },
  background: {
    paper: "#ffffff",
    default: "#FCFCFC",
    neutral: "#fafafa",
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.4),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
