import React, {useEffect, useState} from 'react'
import {Box, Button, InputAdornment, Typography} from '@mui/material'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import AlertMessage from '../../components/Alert/AlertMessage'
import CustomTextField from '../../components/Fields/CustomTextField'
import {useActions} from '../../hook/useActions'
import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import {$authHost} from '../../http'
import {Navigate, useNavigate} from 'react-router';
import {useSelector} from "react-redux";
import {jwtDecode} from "jwt-decode";

const CompletionRegistrationForm = () => {
  const {t} = useTranslation()

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowNewPassword = () => setShowNewPassword(prevState => !prevState)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(prevState => !prevState)
  const navigate = useNavigate()

  const {
    locked_flags,
  } = useSelector(state => state.authReducer)

  const methods = useForm({
    mode: 'onBlur',
  })
  const {
    setError,
    control,
    handleSubmit,
  } = methods

  const newPassword = useWatch({control, name: 'newPassword', defaultValue: ''})

  const {setToken, setRefreshToken, setTokenLifetime, setStartTime} = useActions()

  const onSubmit = async (data) => {  //TODO доделать после получениея рута
    const {newPassword, confirmPassword, ...restData} = data
    const processedData = {
      ...restData,
      password: newPassword,
    }
    try {
      const response = await $authHost.patch(`/user/complete_registration`, processedData)
      if (response.status === 201) {
        const {
          data: {token, refresh_token},
          headers: {date},
        } = response;

        const startTime = new Date(date).getTime(); //localTimeMilliseconds

        const decodedToken = jwtDecode(token);
        const tokenLifetime =
          decodedToken.expires * 1000 - (Date.parse(date) || Date.now());

        setToken(token)
        setRefreshToken(refresh_token)
        setTokenLifetime(tokenLifetime)
        setStartTime(startTime)

        navigate('/')
      }
    } catch (e) {
      if (e.response.status === 401 && e.response.data.detail === 'This is user already exist') {
        setError('newPassword', {
          type: 'manual',
          message: t('src.pages.CompletionRegistration.differentPassword')
        })
      }
    }
  }

  // useEffect(() => {
  //   if (status === 'Active') {
  //     return <Navigate to="/"/>
  //   }
  // }, [])

  return (
    <>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 2}}>
          <Typography variant="body2" sx={{mb: 2}}>
            {t('src.pages.CompletionRegistration.followingInformation')}
          </Typography>
          <CustomTextField
            name="name"
            label={t('src.pages.AccountPage.UserData.name')}
            registerOptions={{
              required: t('main.another.requiredField'),
            }}
            sx={{width: '100%', mb: 3}}
            autoComplete="given-name"
          />
          <CustomTextField
            name="last_name"
            label={t('src.pages.AccountPage.UserData.last_name')}
            registerOptions={{
              required: t('main.another.requiredField'),
            }}
            sx={{width: '100%', mb: 3}}
            autoComplete="family-name"
          />
          <Typography variant="body2" sx={{mb: 2}}>
            {t('src.pages.CompletionRegistration.changePassword')}
          </Typography>
          <CustomTextField
            name="newPassword"
            label={t('src.pages.AccountPage.ChangePassword.newPassword')}
            secondErrorText={t('src.pages.AccountPage.ChangePassword.uncorrectedError')}
            registerOptions={{
              pattern: {
                value: /^[!-~]{1,32}$/i,
                message: t('src.pages.AccountPage.ChangePassword.uncorrectedError'),
              },
              required: t('main.another.requiredField'),
            }}
            // required
            sx={{width: '100%', mb: 3}}
            type={showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>,
            }}
          />
          <CustomTextField
            name="confirmPassword"
            label={t('src.pages.AccountPage.ChangePassword.confirmPassword')}
            registerOptions={{
              validate: (value) => {
                return value === newPassword || t('src.pages.AccountPage.ChangePassword.validPassword')
              },
              required: t('main.another.requiredField'),
            }}
            // required
            sx={{width: '100%', mb: 3}}
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mb: 2}}
          >
            {t('main.another.save')}
          </Button>
          <AlertMessage/>
        </Box>
      </FormProvider>
    </>
  )
}

export default CompletionRegistrationForm