import React, { useEffect } from 'react'
import {Box, Button, Card, Container, Grid, Typography} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {StyledContent, StyledRoot, StyledRootUpMD} from '../theme/standarts_styles'
import { useActions } from '../hook/useActions'
import {useSelector} from "react-redux";
import ChangePasswordForm from "./forms/ChangePasswordForm";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import {RegisterForm} from "../components/Forms/RegisterForm";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { isDarkTheme } = useSelector(state => state.serviceReducer)

  const {
    setOpenNav,
  } = useActions()

  useEffect(() => {
    setOpenNav(false)
  }, [])

  return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display:'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={(theme) => ({
            margin: { xs: 2, sm: 3, md: 4, lg: 6 },
            borderRadius: "10px",
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: "layout.main",
            width: {
              xs: "90vw",
              sm: "80vw",
              md: "50vw",
              lg: "40vw",
              xl: "30vw",
            },
            px: { xs: 3, sm: 4, md: 4 },
            pb: { xs: 3, sm: 4, md: 4, lg: 6, xl: 8 },
            pt: { xs: 2, sm: 2, md: 2, lg: 3, xl: 4 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          })}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <LinearProgress color="secondary" sx={{opacity:'0.4', borderRadius:'90px', width: '13%'}} value={100} variant='determinate' />

            <Typography
              align="center" color="text.secondary" component="p"
              sx={{
                mb:1,
                mt: {xs:3, sm: 3, md: 3, lg: 4, xl: 5}
              }}
              variant="h4Wide"
            >
              Ошибка 404
            </Typography>
            <Typography
              align="center"
              color="action.disabled"
              component="p"
              sx={{
                maxWidth: { xs: "240px", sm: "310px", md: "350px" },
              }}
              variant="body3"
            >
              {t('src.pages.NotFoundPage.pageNotFoundMessage')}
            </Typography>

            <Button
              size="small"
              variant="contained"
              href="/"
              color="primary"
              sx={{
                borderRadius: '90px',
                boxShadow:'none',
                mt: {xs:3, sm: 3, md: 3, lg: 4, xl: 5},
                px: { xs: 2, sm: 4, md: 5 },
              }}
              endIcon={<HomeOutlinedIcon/>}
            >
              {t('src.pages.NotFoundPage.toGeneral')}
            </Button>


          </Box>
        </Box>
      </div>

  )
}

export default NotFoundPage

