import {useSelector} from 'react-redux'
import {RequireRedirect} from './requireRedirect'
import {Navigate, useLocation} from 'react-router'

const RequireAuth = ({children}) => {
  const {pathname} = useLocation();
  const {
    token,
  } = useSelector(state => state.authReducer)

  if (!token) {
    return <Navigate to={`/login?redirect=${encodeURIComponent(pathname)}`}/>
  }

  return (
    <RequireRedirect>
      {children}
    </RequireRedirect>
  )
}

export {RequireAuth}
