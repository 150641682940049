import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TableCellsWrap } from '../../components/TableCellsWrap'
import {Box, Button, Card, Divider, Grid, IconButton, Stack, Switch, Tooltip, Typography} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../components/modals/loadingSpinner'
import Iconify from '../../components/iconify'
import AlertMessage from '../../components/Alert/AlertMessage'
import MaterialTable from '../../components/MaterialTable'
import BaseAddEditForm from '../../components/Forms/BaseAddEditForm'
import AccessGroupsFields from '../../components/Forms/FormFields/AccessGroupsFields'
import AddEditFormButtonGroup from '../../components/Buttons/AddEditFormButtonGroup'
import {ButtonStack, RowOptionsStack} from '../../theme/standarts_styles'
import { $authHost } from '../../http'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import AddEditForm from "../../components/Forms/AddEditForm";
import AddEditButtonForm from "../../components/Forms/AddEditButtonForm";
import TableActions from "../../components/TableActions";
import CloseIcon from "@mui/icons-material/Close";
import useResponsive from "../../hook/useResponsive";

const notDeletedGroups = ['Администратор', 'Пользователь']
const notEditGroups = ['Администратор']
const fixRoles = ['base'];
// const fixRoles = ['base'];
const fixGroup = ['Пользователь'];
const AccessGroupsPage = () => {

	const { t } = useTranslation()
	const [selectedRow, setSelectedRow] = useState(null)
	const [loading, setLoading] = useState(false)
	const [openForm, setOpenForm] = useState('')
	const [groupData, setGroupData] = useState('')
	const [roles, setRoles] = useState('')
	const [confirmationId, setConfirmationId] = useState(null)
	const [confirmationForEditForm, setConfirmationForEditForm] = useState(null)
	const fieldsRef = useRef(null)
	const fieldsHeight = fieldsRef?.current?.clientHeight || null
	const isMobile = useResponsive('down', 'sm')


	const tableColumns = [
		{
			accessorKey: 'name',
			header: t('src.pages.AccessGroupsPage.groupName', 'Наименование группы'),
			Cell: ({ renderedCellValue, row }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},
		{
			accessorKey: 'roles',
			header: t('src.pages.AccessGroupsPage.roles', 'Роли'),
			Cell: ({ renderedCellValue, row }) => (
				<TableCellsWrap>
					<div dangerouslySetInnerHTML={{
						__html: [...renderedCellValue].map(role => role.description).sort().join(';<br />'),
					}}/>
				</TableCellsWrap>
			),
		},
		{
			accessorKey: 'group_sso',
			header: t('src.pages.AccessGroupsPage.ssoGroup', 'Группа SSO'),
			Cell: ({ renderedCellValue, row }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},
		{
			accessorKey: 'actions',
			size: 120,
			enableColumnFilter: false,
			enableSorting: false,
			Cell: ({ renderedCellValue, row }) =>(
				<TableActions
					confirmationId={confirmationId}
					deleteFn={handleDeleteRow}
					editFn={() => handleGoToEditPage(row.original)}
					row={row}
					setConfirmationId={setConfirmationId}
					showDelete={!notDeletedGroups.includes(row.original.name)}
				/>
			)
		},
	]

	function handleGoToEditPage (row) {
		setSelectedRow(row)
		setOpenForm('Update')
	}

	const handleAdd = () => {
		// setSelectGeneralData(null)
		setOpenForm(prevState => {
			return prevState === 'Add' ? '' : 'Add'
		})
		setSelectedRow(null)
	}

	async function handleDeleteRow (id_row) {
		setLoading(true)
		try {
			await $authHost.delete(`/group/${id_row}/delete`)
			loadData()
			if (id_row === selectedRow.id) {
				setOpenForm('')
			}
		} catch (e) {
		} finally {
			setLoading(false)
		}
	}

	const getDropdownData = useCallback(async () => {
		try {
			const response = await $authHost.get(`/group/get_roles`)
			setRoles(response.data)
		} catch (e) {
		} finally {
		}
	}, [])

	const onSubmit = async (data) => {
		setLoading(true)
		if (openForm === 'Update') {
			setLoading(true)
			const { updated_at, created_at, id, ...update_data } = data
			if (!update_data.id && !!update_data._id) {
				update_data.id = update_data._id  //Костыль для монго дб
			}
			try {
				await $authHost.patch(`/group/${id}/update`, update_data)
				loadData()
				setOpenForm('')
			} catch (e) {
			} finally {
				setLoading(false)
			}
		} else if (openForm === 'Add') {
			const { id, updated_at, created_at, users, ...add_data } = data
			try {
				await $authHost.post(`/group/create`, add_data)
				loadData()
				setOpenForm('')
			} catch (e) {
			} finally {
			}
		}
		setLoading(false)
	}

	const loadData = useCallback(() => {
		try {
			const newChannelList = async () => {
				const response = await $authHost.get('/group/get_all')
				return response.data
			}
			newChannelList().then((data) => {
				setGroupData(data)
			})
		} catch (e) {
		}
	}, [])

	useEffect(() => {
		loadData()
	}, [])
	useEffect(() => {
		getDropdownData()
	}, [getDropdownData])

	return (
		<Box sx={{pb:2}}>
			{loading && <LoadingSpinner/>}
			<>
				<ButtonStack sx={{mb:2}}>
					<Button
						onClick={handleAdd}
						aria-haspopup="true"
						variant="outlined"
						size="small"
						startIcon={<Iconify icon="eva:plus-fill"/>}
					>
						{t('src.pages.AccessGroupsPage.addGroup')}
					</Button>
				</ButtonStack>
				<AlertMessage/>
				{(openForm) &&
					<Card sx={{mb:2}}>
						<Stack
							alignItems="center"
							direction="row"
							justifyContent="space-between"
							sx={{
								p:2
							}}
						>
							<Typography variant="subtitle2">
								{openForm === 'Add' ?
									t('main.another.add')
									:
									t('main.another.edit')
								}
							</Typography>
							{isMobile ?
								<IconButton color="primary" onClick={() => setOpenForm('')} sx={{ p: 0 }}>
									<CloseIcon/>
								</IconButton>
								:
								<Button
									color="primary"
									onClick={() => setOpenForm('')}
									size="small"
									startIcon={
										<CloseIcon/>
									}
									variant="text"
								>
									{t('src.pages.Channels.cancel')}
								</Button>
							}
						</Stack>
						<Divider />
						<Box sx={{p:2}}>
							<AddEditForm onSubmit={onSubmit}
															 selectedRow={selectedRow}
															 action={openForm}
															 fieldsRef={fieldsRef}
											 handleClose={() => setOpenForm('')}

							>
								<AccessGroupsFields
									autocompleteOptions={roles}
									selectedRow={selectedRow}
									baseGroups={notDeletedGroups}
									notEditGroups={notEditGroups}
									fixRoles={fixRoles}
									fixGroup={fixGroup}
								/>
								<AddEditButtonForm
									action={openForm}
									handleClose={() => setOpenForm('')}
									handleDeleteRow={() => handleDeleteRow(selectedRow.id)}
									confirmationForEditForm={confirmationForEditForm}
									setConfirmationForEditForm={setConfirmationForEditForm}
									selectedRow={selectedRow}
									baseGroups={notDeletedGroups}
								/>
							</AddEditForm>
						</Box>
					</Card>
				}
				<Card>
					<MaterialTable
						data={groupData}
						columns={tableColumns}
					/>
				</Card>
			</>
		</Box>
	)
}

export default AccessGroupsPage