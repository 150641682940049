// @mui
import {Box, Container, Link, Typography} from '@mui/material'
// sections
import {LoginForm} from '../components/Forms/LoginForm'
import {useTranslation} from 'react-i18next'
import {GradientBackground, StyledContent, StyledRoot} from '../theme/standarts_styles'
import React from 'react'
import LinearProgress from "@mui/material/LinearProgress";
import {AspexIcon} from "../constants";
import {useSelector} from "react-redux";
import {Navigate, useLocation} from "react-router";
import {useTheme} from "@mui/material/styles";
import useResponsive from "../hook/useResponsive";

export default function Login() {
  const {t} = useTranslation()
  const mdUp = useResponsive('up', 'md')
  const theme = useTheme();
  const {search} = useLocation()
  const {token} = useSelector((state) => state.authReducer);

  if (token) {
    const params = new URLSearchParams(search);
    const redirectPath = params.get("redirect") || "/";

    return <Navigate to={redirectPath}/>;
  }

  return (
    <>
      <StyledRoot>
        <Container sx={{ padding: "0px !important" }}>
          <GradientBackground>
            <Box
              sx={{
                height: {xs: 14, sm: 14, md: 18, lg: 20, xl: 20},
              }}
            >
              <AspexIcon
                color={theme.palette.primary.main}
                height="18"
              />
            </Box>

            <div>
              <Box
                sx={(theme) => ({
                  margin: {xs: 2, sm: 3, md: 4, lg: 6},
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: "layout.main",
                  width: {
                    xs: "90vw",
                    sm: "80vw",
                    md: "50vw",
                    lg: "40vw",
                    xl: "30vw",
                  },
                  px: {xs: 3, sm: 4, md: 4},
                  pb: {xs: 3, sm: 4, md: 4, lg: 6, xl: 8},
                  pt: {xs: 2, sm: 2, md: 2, lg: 3, xl: 4},
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                })}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <LinearProgress color="secondary" sx={{opacity: '0.4', borderRadius: '90px', width: '13%'}}
                                  value={100} variant='determinate'/>

                  <Typography
                    align="center" color="text.secondary" component="p"
                    sx={{
                      mb: {xs: 3, sm: 3, md: 3, lg: 4, xl: 5},
                      mt: {xs: 3, sm: 3, md: 3, lg: 4, xl: 5}
                    }}
                    variant="h4Wide"
                  >
                    {t('src.pages.Login.authorization')}
                  </Typography>

                  <LoginForm/>

                </Box>
              </Box>
            </div>

            <Typography gutterBottom mb={0} variant="body3">
              © 2023-2025, ASPEX
            </Typography>
          </GradientBackground>
        </Container>
      </StyledRoot>
    </>
  )
}
