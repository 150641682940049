import React, {useEffect} from 'react'
import {Navigate} from 'react-router'
import {Grid, IconButton, InputAdornment, Link} from '@mui/material'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import {$host} from '../../http'
import {useTranslation} from 'react-i18next'
import {useActions} from '../../hook/useActions'
import AlertMessage from '../Alert/AlertMessage'
import {FormProvider, useForm} from 'react-hook-form'
import CustomTextField from '../Fields/CustomTextField'
import {useSelector} from 'react-redux'
import Stack from "@mui/material/Stack";
import {jwtDecode} from "jwt-decode";

export function LoginForm() {
  const {t} = useTranslation()

  const {
    logIn,
    setToken,
    setRefreshToken,
    setTokenLifetime,
    setStartTime,
    setInfoAlertMessage,
    resetAllAlertMessage,
  } = useActions()

  const [showPassword, setShowPassword] = React.useState(false)
  const {
    userId,
  } = useSelector(state => state.authReducer)

  const methods = useForm({
    mode: 'onBlur',
  })
  const {
    handleSubmit,
  } = methods

  const handleClickShowPassword = () => setShowPassword(prevState => !prevState)

  const onSubmit = async (data) => {
    try {
      const response = await $host.post('/user/login', data)
      const {
        data: {token, refresh_token},
        headers: {date},
      } = response;

      const startTime = new Date(date).getTime(); //localTimeMilliseconds

      const decodedToken = jwtDecode(token);
      const tokenLifetime =
        decodedToken.expires * 1000 - (Date.parse(date) || Date.now());

      setToken(token)
      setRefreshToken(refresh_token)
      setTokenLifetime(tokenLifetime)
      setStartTime(startTime)
    } catch (e) {
      if (e.response.status === 403) {
        setInfoAlertMessage(t('src.sections.auth.login.wrongLoginPassword'))
      }
    }
  }

  useEffect(() => {
    resetAllAlertMessage()
    if (userId) {
      return <Navigate to="/"/>
    }
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{
            width: '80%'
          }}
        >
          <CustomTextField
            name="email"
            label={t('src.sections.auth.login.login')}
            registerOptions={{
              required: t('main.another.requiredField'),
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t('main.another.wrongInput'),
              },
            }}
            required
            // sx={{ mb: 2 }}
            autoComplete="email"
            autoFocus
            size="small"
          />
          <CustomTextField
            name="password"
            label={t('src.sections.auth.login.password')}
            registerOptions={{
              required: t('main.another.requiredField'),
              pattern: {
                value: /^[!-~]{1,32}$/,
                message: t('main.another.wrongInput'),
              },
            }}
            required
            size="small"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>,
            }}
            // sx={{ mb: 2 }}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            size="small"
            variant="contained"
            sx={{mb: 2, borderRadius: '90px', boxShadow: 'none'}}
          >
            {t('src.sections.auth.login.LoginForm.goInto')}
          </Button>
          <Stack>
            <Link href="/reset-password" variant="body2">
              {t('src.pages.Register.forgotPassword')}
            </Link>
            {/*<Box>*/}
            {/*  <Link href="/register" variant="body2">*/}
            {/*    {t('src.pages.Login.noAccountYet')}*/}
            {/*    {t('src.pages.Login.registerNow')}*/}
            {/*  </Link>*/}
            {/*</Box>*/}
          </Stack>


          <AlertMessage/>
        </Box>
      </FormProvider>
    </>
  )
}
