import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {Tab, Tabs, Tooltip} from "@mui/material";

export function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 0}}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DynamicTabs = ({components}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider', mb:2}}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {components.map((component, index) => {
            if (component) {
              return (
                <Tooltip title={component.tooltipText}>
                  <Tab
                    key={index}
                    label={component.label}
                    disabled={component.disabled}
                    {...a11yProps(index)}
                    sx={{pointerEvents: "auto !important"}}
                  />
                </Tooltip>
              )
            }
          })}
        </Tabs>
      </Box>
      {components.map((component, index) => (
        <TabPanel key={index} value={value} index={index}>
          {component.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default DynamicTabs;
