import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Box, Button, Card, Container, IconButton, Stack, Switch, Tooltip, Typography} from '@mui/material'
import Iconify from '../components/iconify'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import {useNavigate} from 'react-router'
import {useActions} from '../hook/useActions'
import {useSelector} from 'react-redux'
import {$authHost} from '../http'
import LoadingSpinner from '../components/modals/loadingSpinner'
import {TableCellsWrap} from '../components/TableCellsWrap'
import MaterialTable from '../components/MaterialTable'
import {ButtonStack, RowOptionsStack, TitleStack} from "../theme/standarts_styles";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import useResponsive from "../hook/useResponsive";
import TitleAndButtonComponent from "../components/TitleAndButtonComponent";
import TableActions from "../components/TableActions";

const Intelligence = () => {
  const {t} = useTranslation()
  const [confirmationId, setConfirmationId] = useState(null);
  const isMobile = useResponsive('down', 'md')


  const tableColumns = [
    {
      accessorKey: 'name',
      header: t('src.pages.Intelligence.name'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'type',
      header: t('src.pages.ModelManagement.type'),
      Cell: ({ renderedCellValue, row }) => (
          <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'channels',
      header: t('src.pages.Intelligence.channels'),
      accessorFn: (row) => {
        const managerName = row.channels;
        return (
          managerName.map((el, index) => <Chip key={index} label={el} sx={{mr: 1}}/>)
        );
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'prompt_main',
    //   header: t('src.pages.Intelligence.retriever'),
    //   Cell: ({ renderedCellValue, row }) => (
    //       <TableCellsWrap>{row.original.main_assistant?.prompt.toString()}</TableCellsWrap>
    //   ),
    // },
    // {
    //   accessorKey: 'prompt_breaker',
    //   header: t('src.pages.Intelligence.breaker'),
    //   Cell: ({ renderedCellValue, row }) => (
    //     <TableCellsWrap>{row.original.breaker_assistant?.prompt.toString()}</TableCellsWrap>
    //   ),
    // },
    // {
    //   accessorKey: 'prompt_output',
    //   header: t('src.pages.Intelligence.stylist'),
    //   Cell: ({ renderedCellValue, row }) => (
    //       <TableCellsWrap>{row.original.output_assistant?.prompt.toString()}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: 'actions',
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({renderedCellValue, row}) => (
        <TableActions
          confirmationId={confirmationId}
          deleteFn={handleDeleteRow}
          editFn={() => handleGoToManagePage(row.original.id)}
          row={row}
          setConfirmationId={setConfirmationId}
          showDelete={true}
          customElement={
            <Tooltip title={row.original.is_run ? t('src.pages.Channels.turnOff') : t('src.pages.Channels.turnOn')}>
              <Switch
                checked={String(row.original.is_run) === 'true'} size="small" color="secondary"
                      onChange={(event, checked) => handleIsRun(row.original.id, checked)}/>
            </Tooltip>
          }
        />
      )
    },
  ]

  const {
    setIntelligens,
    setSelectedIntellegence,
    setModelTypeFormIsAdd
  } = useActions()

  const {
    intelligences,
    selectedIntellegence,
  } = useSelector(state => state.intelligensReducer)

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get('/intellect/')
        return response.data
      }
      newChannelList().then((data) => {
        setIntelligens(data)
      })
    } catch (e) {
    }
  }, [])

  const handleAddUser = (event) => {
    setModelTypeFormIsAdd(true);
    navigate(`/model-management/new/settings`)
  }

  async function handleIsRun(id_row, is_run) {
    setLoading(true)
    try {
      await $authHost.patch(`/intellect/set_is_run?id=${id_row}&bool=${is_run}`).then(response => {
        loadData()
      })
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  async function handleDeleteRow(id_row) {
    setLoading(true)
    try {
      await $authHost.delete(`/intellect/delete_row?id=${id_row}`).then(response => {
        loadData()
      })
    } catch (e) {
    } finally {
      setLoading(false)
    }

  }

  const handleGoToManagePage = (id) => {
    navigate(`/model-management/edit/${id}`)
    setSelectedIntellegence(id)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Box sx={{pb:2}}>
      {loading && <LoadingSpinner/>}
      <TitleAndButtonComponent
        action={handleAddUser}
        back={() => {
          navigate("/");
        }}
        backTitle={t('main.routes.home')}
        name={t('src.pages.Intelligence.addIntelligence')}
        title={t('src.pages.Intelligence.header')}
        type={true}
      />
      <Card>
        <MaterialTable data={intelligences} columns={tableColumns}/>
      </Card>
    </Box>

  )
}

export default Intelligence