import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Stack, Typography} from "@mui/material";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CardRule from "./CardRule";
import ParserForms from "./ParserForms";
import {useActions} from "../../../hook/useActions";
import {useSelector} from "react-redux";
import {random} from "lodash";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ContentGateComponent = ({...props}) => {

    var id_gates = props.selectedOutputs.map(function (el) { return el.target; });
    let OutputsNodes = props.nodes.filter((el) => id_gates.includes(el.id)) || []

    const {
        setEditParserRule,
        setParserRules,
        setSelectedParserRule,
        moveRules
    } = useActions()

    const {
        selectedParserRule,
        parserRules,
    } = useSelector(state => state.diagramReducer)

    // console.log(OutputsNodes)

    return (
        <>
            <Typography variant="subtitle1" sx={{mb: 2}}>
                Gate - свойства
            </Typography>
            {OutputsNodes && OutputsNodes.map((out, index) => (
                <Card key={index} sx={{p: 2, background: "text.primary", mb: 2}}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <div>
                            <Typography variant="subtitle1">
                                {out.data.label}
                            </Typography>
                            <Typography variant="subtitle2">
                                type: {out.type}
                            </Typography>
                        </div>
                        <div>
                            <IconButton onClick={() => {
                            }} size="small">
                                <EditIcon fontSize="inherit"/>
                            </IconButton>
                        </div>

                    </Stack>
                </Card>
            ))}
        </>
    );
};

export default ContentGateComponent;