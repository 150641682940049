import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 'max-content',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.grey[400],
          },
        },
        sizeLarge: {
          height: 48,
        },
        sizeSmall:{
          paddingLeft: 16,
          paddingRight: 16,
        },
        // containedInherit: {
        //   color: theme.palette.grey[800],
        //   boxShadow: theme.customShadows.z4,
        //   '&:hover': {
        //     backgroundColor: theme.palette.grey[400],
        //   },
        // },
        textPrimary: {
          '&:hover': {
            backgroundColor: `${alpha(theme.palette.primary.light, 0.1)}`,
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: `${alpha(theme.palette.primary.light, 0.1)}`,
            border: `1px solid ${alpha(theme.palette.primary.main, 0.9)}`,
          },
        },
        outlinedSecondary: {
          '&:hover': {
            backgroundColor: `${alpha(theme.palette.secondary.light, 0.6)}`,
            border: `1px solid ${alpha(theme.palette.secondary.main, 0.9)}`,
          },
        },
        // containedSecondary: {
        //   boxShadow: theme.customShadows.layout_element,
        // },
        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.9)}`,
          '&:hover': {
            backgroundColor:  theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        // textPrimary: {
        //   color: theme.palette.layout.element,
        //   '&:hover': {
        //     backgroundColor: theme.palette.action.hover,
        //   },
        // }
      },
    },
  };
}
