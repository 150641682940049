import React, {useEffect, useState} from 'react';

import {Icon} from "@iconify/react";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import Link from '@mui/material/Link';
import Stack from "@mui/material/Stack";
import $ from "jquery";

import SkeletonInput from "./SkeletonInput";
import {$authHost} from "../http";

const InfoAboutComponent = ({
                              text,
                              label,
                              loading = false,
                              sx = {},
                              type = "text",
                              path = "",
                              copyButton = false,
                              files = [],

}) => {

  const [copied, setCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = getText();
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена: ", error);
      });
  };

  const getText = () => {
    if (text && (typeof text === "string" || typeof text === "number")) {
      return text;
    }
    return "-";
  };


  const renderText = () => {
    if (text && (typeof text === "string" || typeof text === "number")) {
      return (
        <Typography
          align="right" className="text-justify" color="text.primary" variant="body2"
        >
          {text}
        </Typography>
      );
    }
    return "-";
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await $authHost.get(
        `/compensation/download_file/${id}/`,
        { responseType: "blob" },
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    }
  };

  const toggleText = (e) => {
    let icon = e.currentTarget;
    $(icon).parents(".parent").toggleClass("active");
    $(icon).toggleClass("act");

    switch ($(icon).is(".act")) {
      case true: {
        setIsOpen(true)
        $(icon).siblings('.text').find('.text-justify').css({"whiteSpace":"inherit","overflow":"inherit"});
        break;
      }
      case false: {
        setIsOpen(false)
        $(icon).siblings('.text').find('.text-justify').css({"whiteSpace":"nowrap","overflow":"hidden"});
        break;
      }
      default:
        break;

    }
  }



  const renderContent = () => {

    switch (type) {
      case "link": {
        return (
          <Stack
            direction="row"
            sx={{
              justifyContent:'flex-end',
              alignItems:"center",
            }}
          >
            <Box
              sx={{
                overflow:'hidden',
              }}
            >
              <Link href={`${path}`} rel="noreferrer" target="_blank">
                {renderText()}
              </Link>
            </Box>
          </Stack>
        )
      }
      case "file": {
        return (
            <Stack
              direction="row"
              spacing={{ xs: 1, sm: 1 }}
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
              }}
              useFlexGap
            >
              {Array.isArray(files) && files.length > 0
                ? files.map((file) => (
                  <Box
                    key={file.id}
                    //mt={1}
                    onClick={() => downloadFile(file.id, file.display_name)}
                    p={0.5}
                    sx={{
                      border: "1px solid #dce0e4",
                      borderRadius: 1,
                      cursor: "pointer",
                      "&:hover": { borderColor: "#000000" },
                    }}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <Stack alignItems="center" direction="row" spacing={1}>
                        <FilePresentIcon fontSize="16px"/>
                        <Typography variant="body3">{file.display_name}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                ))
                : (
                  <Typography variant="body2">-</Typography>
                )}
            </Stack>
        )
      }
      case "text":{
        return (
          <Stack
            className="parent"
            direction="row"
            sx={{
              justifyContent:'flex-end',
              alignItems:isOpen ? "flex-start" : "center",
            }}
          >
            <Box
              className="text"
              sx={{
                overflow:'auto',
                whiteSpace:"normal"
              }}
            >
                {renderText()}
            </Box>
            <IconButton
              className="rmore"
              color="primary"
              onClick={(e) => toggleText(e)}
              size="small"
              sx={{
                display:'none',
                paddingRight:'0px'
              }}
            >
              <Icon
                icon={
                isOpen ?
                  "material-symbols-light:keyboard-arrow-up-rounded"
                  : "material-symbols-light:keyboard-arrow-down-rounded"
                }
              />
            </IconButton>
            {copyButton && !copied && (
              <Tooltip title="Копировать">
                <IconButton
                  color="primary"
                  disabled={
                    !(
                      text &&
                      (typeof text === "string" || typeof text === "number")
                    )
                  }
                  onClick={() => copyToClipboard()}
                  size="small"
                  sx={{paddingRight:'0px'}}
                >
                  <Icon icon="material-symbols-light:file-copy-outline-rounded"/>
                </IconButton>
              </Tooltip>
            )}
            {copyButton && copied && (
              <IconButton
                color="primary"
                onClick={() => copyToClipboard()}
                size="small"
                sx={{paddingRight:'0px'}}
              >
                <Icon icon="material-symbols-light:check"/>
              </IconButton>
            )}
          </Stack>

        );
      }
      default:
        return <></>;
    }
  };

  const isEllipsisActive = (e) => e.offsetWidth < e.scrollWidth;

  useEffect(() => {
    document.querySelectorAll('.text-justify').forEach((elem) => {
      if(isEllipsisActive(elem)) {
        $(elem).parent().siblings().css('display', 'block');
      }
    });
  }, [label])


  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        width: '100%',
        justifyContent: "space-between",
        alignItems: (isOpen || type === 'file') ? "flex-start" : "center",
        ...sx
      }}
    >
      <Box
        sx={{
          width:'35%',
          display:'flex',
          alignItems:'center',
          justifyContent:'flex-start',
          overflow:'hidden',
        }}
      >
        <Typography align="center" color="text.secondary" variant="body2">
          {label}
        </Typography>
      </Box>
      <Box
        sx={{
          width:'65%'
        }}
      >
        <SkeletonInput loading={loading}>
          {renderContent()}
        </SkeletonInput>
      </Box>

    </Stack>
  );
};

export default InfoAboutComponent;