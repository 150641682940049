import React, { useCallback, useEffect, useRef, useState } from 'react'
import {Avatar, Badge, Box, Button, Card, Divider, Grid, Typography} from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { $authHost } from '../../http'
import LoadingSpinner from '../modals/loadingSpinner'
import { useActions } from '../../hook/useActions'
import Chip from '@mui/material/Chip'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import IconButton from '@mui/material/IconButton'
import CustomTextField from '../Fields/CustomTextField'
import AlertMessage from '../Alert/AlertMessage'
import { useSelector } from 'react-redux'
import { acceptFileType } from '../../constants'
import Stack from "@mui/material/Stack";
import {Icon} from "@iconify/react";
import {useNavigate} from "react-router";
import useResponsive from "../../hook/useResponsive";
import TitleAndButtonComponent from "../TitleAndButtonComponent";
import DynamicTabs from "../Tabs/DynamicTabs";
import Info from "./Info";
import ChangePassword from "./ChangePassword";
import CloseIcon from "@mui/icons-material/Close";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";
import InfoAboutComponent from "../InfoAboutComponent";

const UserData = () => {

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [uploadedAvatar, setUploadedAvatar] = useState(null)
  const [uploadedAvatarLabel, setUploadedAvatarLabel] = useState(null)
  const {
    userId,
    last_name,
    email,
    avatar,
    name,
    roles,
    status,
  } = useSelector(state => state.authReducer)
  const navigate = useNavigate();

  const isMobile = useResponsive("down", "sm");
  const {
    setErrorAlertMessage,
    updateUser,
  } = useActions()

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      email,
      name,
      last_name,
    },
  })
  
  const clearForm = () => {
    reset();
    setUploadedAvatar(null);
    setUploadedAvatarLabel(null);
  }
  
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    reset,
  } = methods

  const inputRef = useRef(null)

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (fileObj && (acceptFileType.indexOf(fileObj.type) !== -1) && (fileObj.size / (1024 ** 2) < 3.6)) {
      const FR = new FileReader()
      FR.addEventListener('load', function (evt) {
        setUploadedAvatar(evt.target.result)
      })
      FR.readAsDataURL(event.target.files[0])
      setUploadedAvatarLabel(fileObj.name)
    } else {
      setErrorAlertMessage(t('src.pages.AccountPage.UserData.extension'))
    }
  }

  const cleanInput = () => {
    setUploadedAvatar(null)
    setUploadedAvatarLabel(null)
  }

  const loadData = useCallback(async () => {
    try {
      const response = await $authHost.get(`/user/me`)
      const { id, last_name, email, avatar, name, roles, status, locked_flags } = response.data
      updateUser({
        userId: id,
        last_name,
        email,
        avatar,
        name,
        roles,
        status,
        locked_flags,
      })
    } catch (e) {
    }
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    var update_data = data
    // update_data['id'] = userId
    update_data['role'] = roles
    update_data['status'] = status
    if (uploadedAvatar !== null) {
      update_data['avatar'] = uploadedAvatar
    } else {
      update_data['avatar'] = avatar
    }
    try {
      await $authHost.patch(`/user/me/update`, update_data)
      loadData()
    } catch (e) {
    } finally {
      setLoading(false)
      cleanInput()
      navigate('/account');
      // document.getElementById('button_send').disabled = true
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email,
        name,
        last_name,
      })
    }
  }, [email, name, last_name])

  return (
    <Box sx={{mb:2}}>
      <TitleAndButtonComponent
        back={() => {
          navigate("/");
        }}
        backTitle={t('main.routes.home')}
        title={t('src.pages.AccountPage.head')}
      />
      <DynamicTabs
        components={[
          {label: t('src.pages.AccountPage.tab1')},
          {label: t('src.pages.AccountPage.tab2'), disabled: true},
        ]}
      />
      <Box>
        {loading && <LoadingSpinner/>}
        <AlertMessage/>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{width:{ xs: "100%", md:"100%" }}}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{
                  p: 2,
                }}
              >
                <Typography variant="subtitle2">
                  {t('src.pages.AccountPage.UserData.infoHead')}
                </Typography>
                {isMobile ? (
                  <IconButton color="primary" onClick={() => navigate("/account")} sx={{ p: 0 }}>
                    <CloseIcon/>
                  </IconButton>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => navigate("/account")}
                    size="small"
                    startIcon={
                      <CloseIcon/>
                    }
                    variant="text"
                  >
                    {t('main.another.cancel')}
                  </Button>
                )}
              </Stack>
              <Divider />
              <Box sx={{p:2}}>
                <RowStack>
                  <ColumnStack customwidth="20%" customspace={1}>
                    <Typography
                      align="left"
                      component="h2"
                      pb={1}
                      variant="miniHeader"
                    >
                      Аватар
                    </Typography>
                    <div className="containerImg">
                      <Badge
                        badgeContent={
                          <IconButton
                            onClick={cleanInput}
                            size="small"
                            sx={{padding:'0px'}}
                          >
                            <Icon color="#fff" icon="material-symbols:close-rounded"/>
                          </IconButton>
                        }
                        color="primary"
                        invisible={uploadedAvatar === null}
                        overlap="circular"
                        sx={{
                          '& .MuiBadge-badge':{
                            padding:'0px'
                          }
                        }}
                      >
                        <Avatar
                          id="img"
                          src={uploadedAvatar || avatar}
                          alt="photoURL"
                          sx={(theme) => ({
                            width: 150, height: 150,
                            border:`2px solid ${theme.palette.secondary.light}`
                          })}
                        />
                      </Badge>
                      <input
                        id="avatar"
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                      />
                      <div className="overlay" onClick={() => {
                        inputRef.current.click()
                      }}>
                        <IconButton aria-label="delete" className="icon">
                          <Icon icon="material-symbols-light:upload-rounded"/>
                        </IconButton>
                      </div>
                    </div>

                  </ColumnStack>
                  <ColumnStack customwidth="80%" customspace={0.5}>
                    <Typography
                      align="left"
                      component="h2"
                      pb={1}
                      variant="miniHeader"
                    >
                      Общие данные
                    </Typography>
                    <CustomTextField
                      name="name"
                      label={t('src.pages.AccountPage.UserData.name')}
                      registerOptions={{
                        required: t('main.another.requiredField'),
                      }}
                      size="small"
                      required
                    />
                    <CustomTextField
                      name="last_name"
                      label={t('src.pages.AccountPage.UserData.last_name')}
                      registerOptions={{
                        required: t('main.another.requiredField'),
                      }}
                      size="small"
                      required
                    />
                    <Typography
                      align="left"
                      component="h2"
                      pb={1}
                      pt={2}
                      variant="miniHeader"
                    >
                      Контакты
                    </Typography>
                    <CustomTextField
                      name="email"
                      size="small"
                      label={t('src.pages.AccountPage.UserData.email')}
                      registerOptions={{
                        required: t('main.another.requiredField'),
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: t('src.pages.AccountPage.UserData.wrongEmail'),
                        },
                      }}
                      disabled={true}
                      required
                    />
                  </ColumnStack>
                </RowStack>
                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    disabled={!isDirty && !uploadedAvatar}
                    onClick={clearForm}
                  >
                    {t('main.another.reset')}
                  </Button>
                  <Button
                    id="button_send"
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    disabled={!isDirty && !uploadedAvatar}
                  >
                    {t('src.pages.AccountPage.UserData.submitButton')}
                  </Button>
                </Stack>

              </Box>
            </Card>
          </form>
        </FormProvider>
      </Box>
    </Box>

  )
}

export default UserData