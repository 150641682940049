import React from 'react'
import {Button, Card, Container, Grid, Stack, Typography} from '@mui/material'
import AlertMessage from '../components/Alert/AlertMessage'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {useAuth} from '../hook/useAuth'
import {StyledContent, StyledRootUpMD} from '../theme/standarts_styles'
import {$authHost} from '../http'
import {useActions} from '../hook/useActions'
import {useSelector} from "react-redux";
import {jwtDecode} from "jwt-decode";

const NewEula = () => {

  const {t} = useTranslation()
  const {signOut} = useAuth()
  const navigate = useNavigate()
  const {setToken, setRefreshToken, setTokenLifetime, setStartTime} = useActions()

  const handleAccept = async () => {
    try {
      const response = await $authHost.patch(`/user/approve_user_agreement`)
      if (response.status === 201) {
        const {
          data: {token, refresh_token},
          headers: {date},
        } = response;

        const startTime = new Date(date).getTime(); //localTimeMilliseconds

        const decodedToken = jwtDecode(token);
        const tokenLifetime =
          decodedToken.expires * 1000 - (Date.parse(date) || Date.now());

        setToken(token)
        setRefreshToken(refresh_token)
        setTokenLifetime(tokenLifetime)
        setStartTime(startTime)
      }
    } catch (e) {
    } finally {
      navigate('/primary-registration')
    }
  }
  const handleReject = () => {
    signOut()
  }
  return (
    <>
      {/*{loading && <LoadingSpinner/>}*/}
      <StyledRootUpMD>
        <Container>
          <StyledContent sx={{maxWidth: 'none', padding: '0'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Typography variant="h4" gutterBottom sx={{paddingTop: '96px'}}>
                {t('src.pages.NewEula.eula')}
              </Typography>
            </div>
            <>
              <AlertMessage/>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Card sx={{pt: 3, pb: 3, pl: 3, pr: 1, mb: 2, height: '65vh'}}>
                    <div style={{
                      overflowY: 'scroll',
                      height: '100%',
                    }}
                    >
                      <Typography>
                        Quaerendum torquent vitae falli vidisse. Mus suas evertitur suas quod quaestio. Hendrerit
                        iisque
                        suscipiantur epicurei legimus necessitatibus blandit conclusionemque. Similique vocibus nisl
                        efficitur prompta. Maiestatis ac habemus vocibus similique fabulas. Doming eros decore habeo
                        aliquet porttitor. Ornatus dolorem theophrastus falli ceteros postea solet lacus ultrices
                        sociosqu. Esse graeci utinam scripta quaerendum purus scripserit percipit morbi. Appetere
                        semper
                        quaeque eos autem. Honestatis oratio dictumst himenaeos instructior voluptatibus quaerendum.
                        Ridens verear posse quaerendum mediocritatem. Dolorem tristique placerat alterum petentium.
                        Tractatos efficiantur adolescens deseruisse fabulas tractatos sadipscing graecis
                        necessitatibus.
                        Oporteat sociosqu invenire habitasse mentitum potenti adipiscing leo accommodare. Urbanitas
                        propriae homero euismod ius voluptatibus eripuit eruditi percipit ornatus. Reprehendunt ei
                        potenti
                        iusto propriae quot interdum sollicitudin integer mauris. Parturient saperet constituam
                        tellus
                        prodesset quo dicit quam maiorum. Blandit tota elit docendi dolorem adolescens netus.
                        Impetus
                        pulvinar signiferumque interesset referrentur netus nisl sea. Et hac habemus ipsum quaestio
                        fuisset necessitatibus. Dolores vis orci sea simul dicunt platonem. Nonumy aeque curabitur
                        eum
                        necessitatibus graeco patrioque dis legimus. Quis eripuit omnesque disputationi offendit a.
                        Molestiae commune unum legere deserunt detracto efficiantur dictumst. Ante signiferumque
                        erroribus
                        veri voluptatibus. Libris regione laoreet lectus habitasse luptatum suscipiantur mutat
                        sociosqu
                        doctus. Splendide enim mel sadipscing egestas tristique euismod. Possim tantas altera doctus
                        dicit. Ornatus invidunt sit elitr iuvaret quo iisque ea felis no. Fabellas tota posse idque
                        graece
                        tantas maiestatis oratio porta etiam. Elit luptatum idque varius sadipscing dicat suas
                        vituperatoribus. Error vero delicata habemus evertitur nec mollis interpretaris molestiae.
                        Homero
                        veritus an singulis ceteros. Facilisi nullam mediocrem facilisi sollicitudin assueverit
                        prompta
                        animal luctus novum. Rhoncus vocent cum et luctus prodesset signiferumque ligula vel.
                        Conceptam
                        no
                        electram phasellus consectetuer dolore condimentum vehicula convenire falli. Habemus meliore
                        non
                        laoreet convallis parturient. Aptent ultrices adhuc quidam populo viris magnis eripuit
                        lectus.
                        Definitiones ullamcorper dicat decore detraxit ubique falli. At fabulas expetendis ligula
                        nam
                        postulant erat necessitatibus fastidii felis. Velit eleifend ponderum aliquam mandamus
                        discere.
                        Reprimique solet quo feugiat elaboraret noluisse detraxit conclusionemque quidam sociis.
                        Eius
                        inciderint putent aeque ornare iuvaret docendi donec. Dicta evertitur consectetuer eleifend
                        habeo
                        melius dicunt. Discere qualisque epicuri fringilla vehicula et viverra postea integer vel.
                        Saepe
                        odio reque instructior placerat. Aenean expetendis civibus adipiscing an quaestio eos.
                        Cursus
                        cras
                        dictum persequeris sollicitudin nascetur dicat. Invenire dolore dis definiebas dicant
                        delenit
                        himenaeos. Senectus feugiat expetenda venenatis at perpetua lacinia. Lacinia mazim facilisis
                        eros
                        ridiculus agam quisque. Appetere graece consetetur maecenas tantas ignota orci intellegebat
                        reque.
                        Theophrastus usu risus urna similique. Prodesset ad sollicitudin aenean dolorum atqui libero
                        aliquet civibus. Torquent sodales torquent necessitatibus class turpis. Reprimique etiam
                        habitant
                        platonem an suavitate vidisse duis iriure. Iuvaret expetendis doctus epicurei condimentum
                        graecis.
                        Ornatus sale porro feugiat detracto corrumpit etiam. Varius ea efficiantur regione bibendum.
                        Facilisi fermentum recteque ludus gravida. Dictum quo fabulas netus suas in. Parturient
                        metus
                        suspendisse nisi sea molestie deterruisset pretium mollis curae. Movet fames eleifend
                        nonumes
                        nostra. Scripserit omittam vituperatoribus reque porro. Indoctum omittantur quem odio
                        inimicus
                        voluptaria. Pericula ei donec duo gloriatur malesuada maecenas deserunt consetetur. Novum
                        civibus
                        autem elaboraret neglegentur. Donec eleifend doming suscipit esse. Antiopam sonet vivamus
                        curae
                        nonumes evertitur definitionem dictum populo malorum. Elaboraret utinam partiendo commune
                        accusata
                        sagittis quas meliore faucibus urna. Dui sale scripta assueverit est eripuit. Erat viderer
                        dui
                        tellus suspendisse. Purus propriae offendit morbi epicurei aperiri penatibus expetendis
                        repudiandae volumus. Legere ancillae mattis tincidunt conubia mediocrem impetus. Fabellas
                        cursus
                        dui neglegentur aliquam molestiae. Sapientem neglegentur neque omittantur curabitur. Dicunt
                        eu
                        ponderum libero arcu. Habitasse interpretaris quem nonumes posse. Deseruisse aperiri sodales
                        utinam aperiri wisi rhoncus eu periculis necessitatibus. Appareat interesset vitae efficitur
                        nam
                        feugait fabellas phasellus parturient. Natum accumsan ornare veri ut ipsum adipisci regione.
                        Elit
                        euismod duis duis aliquid eum latine. Electram nullam ornare integer blandit. Theophrastus
                        maluisset enim meliore placerat assueverit putent. Elitr molestiae regione integer arcu
                        integer
                        agam lorem. Curae splendide reformidans numquam nulla sale voluptaria. Detraxit no justo
                        gubergren
                        brute ea moderatius. Putent pertinax pretium blandit dignissim. Laoreet aenean repudiare
                        lobortis
                        nominavi dapibus fugit dapibus. Ludus senserit bibendum delectus porro vehicula nostra
                        maiorum
                        invidunt. Iriure ne sumo mentitum venenatis detraxit. Omittantur tamquam pri vituperatoribus
                        nostrum fugit vidisse conclusionemque cum. Sanctus reprehendunt mollis errem corrumpit
                        graeci
                        solet. Atqui fringilla verear interdum omittantur repudiare autem periculis iaculis civibus.
                        Phasellus class maximus posuere omittantur utroque corrumpit. Sapientem assueverit eam
                        hendrerit
                        nunc gloriatur velit offendit sanctus. Tantas tractatos tortor euripidis adipisci ancillae
                        his
                        mediocritatem iuvaret. Eloquentiam eruditi nulla id reprehendunt error alterum urna
                        omittantur
                        quaeque. Pellentesque regione venenatis wisi verear tincidunt comprehensam. Voluptatum
                        persequeris
                        efficitur persecuti interesset mucius cubilia. Odio voluptatum malesuada minim commodo
                        gubergren
                        fringilla mandamus. Suas veniam omittam himenaeos esse praesent. Novum posse platea pretium
                        nostra
                        principes tristique primis sapien tempor. Vulputate consectetur dolores utinam atomorum
                        definiebas
                        unum. Iriure mus nascetur nihil hendrerit. Pertinacia urbanitas invenire tibique viris fusce
                        maximus. Tamquam curabitur ultrices dolor mus platea accusata. Sale verterem sit mentitum
                        quidam
                        vituperatoribus hinc. Orci eleifend adipiscing percipit ridens adhuc etiam. Quaerendum mi
                        quisque
                        quot eget. Lacinia persius pertinacia dolores taciti suspendisse tristique sanctus possim.
                        Homero
                        moderatius integer alia ridiculus graeco fugit hendrerit. Esse dicunt omittantur praesent
                        quisque
                        verear his vim. Mea tellus fuisset parturient feugiat vocibus quem viderer viris est. Elit
                        arcu
                        mentitum ut reque falli consetetur. Nulla equidem ocurreret sonet et dicunt. Malorum veritus
                        eget
                        sociis inimicus. Omittantur quaestio euripidis reformidans ultrices. Sapien suas ac causae
                        adhuc
                        sagittis velit reprimique alterum maiestatis. Porta signiferumque persius porro dicit
                        viverra
                        accommodare tristique. Ceteros molestiae metus dictum placerat netus ignota nihil dicat. Mei
                        accommodare himenaeos appareat ipsum facilisis. Delectus pharetra suscipiantur definiebas
                        inani
                        decore sapientem. Epicurei quaeque intellegebat hac dis reprehendunt. Ridens amet postea
                        impetus
                        mazim. Persecuti porttitor elitr leo mei habeo feugiat. Graeco proin volumus perpetua
                        euripidis
                        delectus latine phasellus. Iuvaret veritus litora adipisci putent numquam duis nostrum
                        suscipiantur graeci. Harum mazim legere populo aperiri natoque definitionem mea hendrerit.
                        Duo
                        cetero penatibus quaeque contentiones. Aptent porttitor iusto debet neque an veritus
                        malesuada.
                        Graecis pulvinar commodo his audire faucibus quaerendum. Maluisset detracto consequat ut
                        has.
                        Parturient maluisset intellegat audire ex blandit sodales. Definitiones homero leo movet
                        harum
                        ante pretium mazim. Efficitur legimus legere eleifend per senectus brute mollis luptatum.
                        Nec
                        pri
                        lorem elaboraret lacus commune assueverit praesent quod. Vis theophrastus cetero potenti
                        magna.
                        Quo amet esse magna dolores montes. Evertitur erat volumus tation periculis explicari
                        adipisci
                        sit
                        fastidii. Offendit cubilia alia purus inimicus ut. Senserit verterem lobortis senserit
                        eirmod
                        omnesque. Meliore malesuada suscipit eum laudem. Ocurreret necessitatibus solet iusto
                        indoctum
                        quaeque qualisque. Dicat verear conceptam non dicunt tritani sociosqu. Eius reprimique
                        meliore
                        tibique ea duo vulputate. Utinam habemus proin persecuti nihil nulla adolescens elitr.
                        Posuere
                        vocibus referrentur pro quod nec. Nostrum prodesset ut theophrastus decore interpretaris
                        tale
                        ludus maecenas convenire. Condimentum volumus pertinax adversarium suas.
                      </Typography>
                    </div>

                  </Card>
                </Grid>
              </Grid>
              <Stack direction="row" sx={{height: '70px'}} alignItems="center" justifyContent="end" spacing={3}>
                <Button
                  onClick={handleReject}
                  aria-haspopup="false"
                  variant="text"
                  sx={{mr: 0}}
                >
                  {t('src.pages.NewEula.reject')}
                </Button>
                <Button
                  onClick={handleAccept}
                  aria-haspopup="true"
                  variant="contained"
                  sx={{mr: 0}}
                >
                  {t('src.pages.NewEula.accept')}
                </Button>
              </Stack>
            </>
          </StyledContent>
        </Container>
      </StyledRootUpMD>
    </>
  )
}

export default NewEula