import React, { useEffect, useState } from 'react'
import {Box, Button, Card, Divider, IconButton, InputAdornment, Typography} from '@mui/material'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import { useTranslation } from 'react-i18next'
import { $authHost } from '../../http'
import LoadingSpinner from '../modals/loadingSpinner'
import AlertMessage from '../Alert/AlertMessage'
import CustomTextField from '../Fields/CustomTextField'
import { useActions } from '../../hook/useActions'
import {ColumnStack} from "../../theme/standarts_styles";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";

const ChangePassword = () => {

  const { t } = useTranslation()

  const {
    setSuccessAlertMessage,
    setErrorAlertMessage,
  } = useActions()

  const [showOldPassword, setShowOldPassword] = React.useState(() => { return false })
  const [showNewPassword, setShowNewPassword] = React.useState(() => { return false })
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(() => { return false })
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show)

  const [loading, setLoading] = useState(false)
  const registerOptions = {
    pattern: {
      value: /^[!-~]{1,32}$/i,
      message: t('src.pages.AccountPage.ChangePassword.uncorrectedError'),
    },
    required: t('main.another.requiredField'),
  }

  const methods = useForm({
    mode: 'onBlur',
  })
  const {
    setError,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful },
  } = methods

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await $authHost.patch(`user/change_password`, { 'password': data.newPassword, 'old_password': data.oldPassword }).
        then(response => {
          if (response.status === 201) {
            // reset({})
            setSuccessAlertMessage(t('src.pages.AccountPage.ChangePassword.passwordСhanged'))
          } else {
            setErrorAlertMessage(t('src.pages.AccountPage.ChangePassword.wentWrong'))
          }

        })
    } catch (e) {
      if (e.response.status === 403) {
        setError('oldPassword', {
          type: 'manual',
          message: t('src.pages.AccountPage.ChangePassword.oldPasswordError'),
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const newPassword = useWatch({ control, name: 'newPassword', defaultValue: '' })

  useEffect(() => {
    reset({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
  }, [isSubmitSuccessful])
  return (
    <>
      {loading && <LoadingSpinner/>}
      <Box sx={{mb:2}}>
        <AlertMessage/>
        <Card>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p: 2,
            }}
          >
            <Typography variant="subtitle2">
              {t('src.pages.AccountPage.UserData.passwordChange')}
            </Typography>
          </Stack>
          <Divider/>
          <Box sx={{px:2, pt:3, pb:2}}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnStack full={true} customspace={0.5}>
                  <CustomTextField
                    name="oldPassword"
                    label={t('src.pages.AccountPage.ChangePassword.oldPassword')}
                    registerOptions={registerOptions}
                    // required
                    size="small"
                    type={showOldPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPassword}
                            edge="end"
                          >
                            {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                          </IconButton>
                        </InputAdornment>,
                    }}
                  />
                  <CustomTextField
                    name="newPassword"
                    label={t('src.pages.AccountPage.ChangePassword.newPassword')}
                    secondErrorText={t('src.pages.AccountPage.ChangePassword.uncorrectedError')}
                    registerOptions={registerOptions}
                    // required
                    size="small"
                    type={showNewPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            edge="end"
                          >
                            {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                          </IconButton>
                        </InputAdornment>,
                    }}
                  />
                  <CustomTextField
                    name="confirmPassword"
                    label={t('src.pages.AccountPage.ChangePassword.confirmPassword')}
                    registerOptions={{
                      validate: (value) => {
                        return value === newPassword || t('src.pages.AccountPage.ChangePassword.validPassword')
                      },
                    }}
                    // required
                    size="small"
                    type={showConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                          </IconButton>
                        </InputAdornment>,
                    }}
                  />
                </ColumnStack>
                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={()=>reset()}
                  >
                    {t('main.another.clear')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    {t('src.pages.AccountPage.ChangePassword.submitButton')}
                  </Button>
                </Stack>
              </form>
            </FormProvider>
          </Box>
        </Card>
      </Box>
    </>
  )
}

export default ChangePassword