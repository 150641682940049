import AccessSsoSettingsFields from "../../components/Forms/FormFields/AccessSsoSettingsFields";
import {Box, Button, Card, Divider, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Controller, FormProvider, useForm, useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {$authHost} from "../../http";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import useResponsive from "../../hook/useResponsive";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

const AccessSsoSettingsPage = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  // const {
  //   setSettings,
  // } = useActions();
  //
  // const {
  //   settings,
  // } = useSelector(state => state.ssoReducer)

  const methods = useForm({
    mode: 'onBlur',
  });
  const isMobile = useResponsive('down', 'sm')

  // useEffect(() => {
  //   reset(settings);
  // }, [settings]);

  const {
    handleSubmit,
    reset,
  } = methods;

  // const loadData = async () => {
  //   setLoading(true)
  //   try {
  //     const {data} = await $authHost.get(`/sso/`);
  //     setSettings(data);
  //   } catch (e) {
  //     // console.log(e);
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const onSubmit = async (data) => {
  //   setLoading(true)
  //   try {
  //     await $authHost.patch(`/sso/update`, data)
  //   } catch (e) {
  //     // console.log(e);
  //   } finally {
  //     setLoading(false)
  //   }
  }

  // useEffect(() => {
  //   loadData();
  // }, [])

  return (
    <Card>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AccessSsoSettingsFields/>
          <Box sx={{px:2, pb:2, pt:0}}>
            {isMobile ?
              <Stack
                alignItems="center"
                direction="column"
                justifyContent="center"
                spacing={1}
                sx={{ width: "100%", mt: 0 }}
              >
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{width: '100%'}}
                  disabled={true}
                >
                  {t('main.another.save')}
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  onClick={() => reset()}
                  disabled={true}
                >
                  {t('src.components.Intelligence.reset')}
                </Button>
              </Stack>
              :
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => reset()}
                  // disabled={loading}
                  disabled={true}
                >
                  {t('src.components.Intelligence.reset')}
                </Button>
                <Button variant="contained" type="submit"
                        size="small"
                        disabled={true}
                >
                  {t('main.another.save')}
                </Button>
              </Stack>
            }
          </Box>
        </form>
      </FormProvider>
    </Card>
  )
}

export default AccessSsoSettingsPage;