import { NavLink as RouterLink } from 'react-router';
import {Box, Grid, List, ListItemButton, ListItemText, Typography} from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {StyledChip, StyledNavItem} from "../../theme/standarts_styles";
import { alpha } from "@mui/material/styles";

import {Icon} from "@iconify/react";
import Stack from "@mui/material/Stack";
export default function NavSection ({ ...other }) {

  const { t } = useTranslation()
  const { roles } = useSelector(state => state.authReducer)

  const navConfig = [
    {
      title: t('src.components.nav-section.NavSection.home'),
      path: '/',
      roles: [{ name: 'base' }],
      icon: (
        <Icon
          height="20"
          icon="material-symbols-light:dashboard-outline-rounded"
          width="20"
        />
      ),
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.dialogues'),
      path: '/dialogs',
      roles: [{ name: 'BotManager' }],
      icon: (
        <Icon
          height="20"
          // icon="fluent:chat-multiple-16-regular"
          icon="ph:chat-circle-dots-light"
          width="20"
        />
      ),
      counterChip: true,
    },
    {
      title: t('src.components.nav-section.NavSection.channel'),
      path: '/channels',
      roles: [{ name: 'BotManager' }],
      icon: (
        <Icon
          height="20"
          // icon="fluent:channel-share-20-regular"
          icon="material-symbols-light:family-history-outline-rounded"
          width="20"
        />
      ),
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.knowledgeManagement'),
      path: '/knowledgeManagement',
      roles: [{ name: 'BotManager' }],
      icon: (
        <Icon
          height="20"
          // icon="material-symbols-light:rebase-outline-rounded"
          icon="material-symbols-light:database-outline"
          // icon="material-symbols-light:art-track-outline-rounded"
          width="20"
        />
      ),
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.intelligence'),
      path: '/intelligence',
      roles: [{ name: 'BotManager' }],
      icon: (
        <Icon
          height="20"
          icon="fluent:brain-sparkle-20-regular"
          width="20"
        />
      ),
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.sandbox'),
      path: '/sandbox',
      roles: [{ name: 'BotManager' }],
      icon: (
        <Icon
          height="20"
          // icon="famicons:bug-outline"
          icon="lets-icons:bug-light"
          width="20"
        />
      ),
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.accessControl'),
      path: '/access-control',
      roles: [{ name: 'AccessManager' }],
      icon: (
        <Icon
          height="20"
          icon="material-symbols-light:group-outline-rounded"
          width="20"
        />
      ),
      counterChip: false,
    }
    // {
    //   title: t('src.components.nav-section.NavSection.diagram'),
    //   path: '/diagram',
    //   roles: [{ name: 'base' }],
    //   icon: <InsightsIcon size='small'/>,
    //   counterChip: false,
    // },
    // {
    //   title: t('src.components.nav-section.NavSection.diagram'),
    //   path: '/prompt-constructor',
    //   roles: [{ name: 'base' }],
    //   icon: <InsightsIcon size='small'/>,
    //   counterChip: false,
    // }

  ];
  const chats = useSelector(state => state.dialoguesReducer.chats)
  const unreadChats = useMemo(() => (
    chats.filter(chat => chat.msg_count > 0).length  // TODO Механика новых сообщений
  ), [chats])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 1.5,
        marginTop: 2,
      }}
    >
      <List
        dense={true}
        disablePadding
        sx={{
          // paddingTop:1,
          width: "100%",
        }}
      >
        {navConfig.map((item) => (
            roles.some(role => item.roles?.some(r => r.name === role)) && (  // выводим только те элементы у которых есть совпадение между названиями roles в navConfig и authReducer
                <NavItem
                    key={item.title}
                    item={item}
                    unreadChats={unreadChats}
                />
            )
        ))}
      </List>
    </Box>
  )
}

function NavItem({ item, unreadChats }) {

  const { title, path, icon, info, counterChip } = item;
  const { openNav } = useSelector(state => state.serviceReducer)

  return (
    <StyledNavItem
      component={RouterLink}
      sx={(theme) => ({
        p: 0,
        color: "text.primary",
        "&.active": {
          bgcolor: `${alpha(theme.palette.primary.lighter, 0.25)}`,
          color: "primary.main",
        },
      })}
      to={path}
    >
      { openNav ? (
        <Stack direction="row" px={1} spacing={1}>
          {icon && icon}
          <ListItemText
            disableTypography
            primary={
            <Typography variant="body2">
              {title}
            </Typography>
          }
          />
          {counterChip && unreadChats > 0 && (
            <StyledChip label={unreadChats} size="small" />
          )}
        </Stack>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            direction: "column",
          }}
        >
          {icon && icon}
        </Box>
      )}
    </StyledNavItem>
  )
}
