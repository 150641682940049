import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import {Grid, Typography} from "@mui/material";
import i18next from "i18next";

const handleStyleTop = { zIndex: 50 };
const handleStyleLeft = { left: 0 };
const handleStyleRight = { top: 0 };



function GateNode({ data, isConnectable }) {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="condition-node"
        >
            <Handle type="target"
                    position={Position.Top}
                    style={handleStyleTop}
                    isConnectable={isConnectable}/>
            <div className="condition-node-inner">
                <div className="condition-node-inner-text">
                    <Typography align="center" variant="body25" sx={{fontSize: '4pt'}}>
                        {i18next.t(data.label)}
                    </Typography>
                </div>
            </div>
            <Handle
                type="source"
                id="a"
                // style={handleStyleRight}
                position={Position.Right}
                isConnectable={isConnectable}
            />
            <Handle type="source"
                    id="b"
                    // style={handleStyleLeft}
                    position={Position.Left}
                    isConnectable={isConnectable}
            />
        </Grid>
    );
}

export default GateNode;
