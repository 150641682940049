import {useSelector} from "react-redux";
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router';
import {TitleStack} from "../theme/standarts_styles";
import {Box, Typography} from "@mui/material";
import MuiBreadcrumbs from "../components/breadcrumbs/MuiBreadcrumbs";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import DynamicTabs from "../components/Tabs/DynamicTabs";
import ChannelsForm from "./forms/ChannelsForm";
import ChannelsFormAvailability from "./forms/ChannelsFormAvailability";
import {useEffect, useState} from "react";
import {useActions} from "../hook/useActions";
import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import TitleAndButtonComponent from "../components/TitleAndButtonComponent";

const ChannelPage = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const {
        selectedChannel,
        channelsTypeFormIsAdd,
        disableAvailability,
        isTelegramType
    } = useSelector(state => state.channelsReducer);

    const {
        setChannels,
        setChannelsTypeFormIsAdd,
        setSelectedChannel,
        addQuestions,
        deleteQuestions,
        setSelectedQuestions,
        updateQuestionInChannelsQuestions,
        setExpanded,
        addExpandedPanel,
        setDisableAvailability,
        setIsTelegramType,
    } = useActions()

    // const [disableAvailability, setDisableAvailability] = useState(true);
    // const [isTelegramType, setIsTelegramType] = useState(false);

    const tabs = [
        {
          label: t('src.pages.forms.ChannelsForm.tabGeneral'),
          path: 'general'
        },
        ...[
          isTelegramType && {
              label: t('src.pages.forms.ChannelsForm.tabAvailability'),
              path: 'availability',
              disabled_tab: disableAvailability
          }
        ],
    ]

    useEffect(() => {
        var pathArray = window.location.pathname.split('/')
        switch (pathArray[2]) {
            case 'new':
                setChannelsTypeFormIsAdd(true)
                setSelectedChannel(null)
                setIsTelegramType(false)
                setDisableAvailability(true)
                break
            case 'edit':
                setChannelsTypeFormIsAdd(false)
                setSelectedChannel(pathArray[3])
                if(selectedChannel.channel_type === 'Telegram'){
                    setIsTelegramType(true);
                    setDisableAvailability(false);
                }else{
                    setIsTelegramType(false)
                    setDisableAvailability(true)
                }
                break
            default:
                break
        }

    }, [])

    const handleBack = () => {
        navigate("/channels");
    };

    // console.log(selectedChannel)

    //Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
    if (
        !tabs.some((tab) => location.pathname.endsWith(tab.path)) &&
        !location.pathname.endsWith('new')
    ) {
        let redirectPath = location.pathname;
        if (!location.pathname.endsWith('/')) {
            redirectPath += '/';
        }
        redirectPath += tabs[0].path;
        return <Navigate to={redirectPath} />;
    }



  return (
    <Box sx={{pb:2}}>
        <TitleAndButtonComponent
          back={handleBack}
          backTitle={t('main.routes.backToList')}
          title={channelsTypeFormIsAdd
            ? t('src.pages.forms.ChannelsForm.newChannel')
            : `${t('main.another.manage')} ${selectedChannel?.name}`
          }
        />
      {/*<MuiBreadcrumbs currentLocation={location}></MuiBreadcrumbs>*/}
        <RemoteDynamicTabs tabs={tabs}/>
        <Outlet/>
      {/*<DynamicTabs*/}
      {/*  components={[*/}
      {/*    {*/}
      {/*      label: t('src.pages.forms.ChannelsForm.tabGeneral'),*/}
      {/*      component: <ChannelsForm setDisableAvailability={setDisableAvailability}*/}
      {/*                               setIsTelegramType={setIsTelegramType}/>*/}
      {/*    },*/}
      {/*    ...[*/}
      {/*      isTelegramType && {*/}
      {/*        label: t('src.pages.forms.ChannelsForm.tabAvailability'),*/}
      {/*        component: <ChannelsFormAvailability/>,*/}
      {/*        disabled: disableAvailability,*/}
      {/*        tooltipText: disableAvailability ? t('src.pages.forms.ChannelsForm.tabAvailabilityTooltip') : ''*/}
      {/*      }*/}
      {/*    ],*/}
      {/*  ]}*/}
      {/*/>*/}
    </Box>
  )
}

export default ChannelPage;