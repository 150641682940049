import {useState} from "react";

import { Icon } from "@iconify/react";
import { IconButton, Tooltip } from "@mui/material";

import { RowOptionsStack } from "../theme/standarts_styles";

const TableActions = ({
  confirmationId,
  setConfirmationId,
  row,
  deleteFn,
  editFn,
  showDelete = true,
  showEdit = true,
  customElement
}) => {
  const [showActionsMode, setShowActionsMode] = useState(false);
  if (confirmationId === row.original.id && deleteFn && showDelete) {
    return (
      <RowOptionsStack>
        <Tooltip title="Подтвердить">
          <IconButton
            color="success"
            onClick={() => deleteFn(row.original)}
            size="small"
            sx={(theme) => ({
              borderRadius: "180px",
              border: `1px solid ${theme.palette.success.main}`,
            })}
          >
            <Icon height="20" icon="material-symbols-light:check" width="20" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Отмена">
          <IconButton
            color="error"
            onClick={() => setConfirmationId(null)}
            size="small"
            sx={(theme) => ({
              borderRadius: "180px",
              border: `1px solid ${theme.palette.error.main}`,
            })}
          >
            <Icon height="20" icon="material-symbols-light:close" width="20" />
          </IconButton>
        </Tooltip>
      </RowOptionsStack>
    );
  }

  return (
      <RowOptionsStack>
        {customElement && customElement}
        {editFn && showEdit && (
          <Tooltip title="Редактировать">
            <IconButton
              color="secondary"
              onClick={() => editFn(row.original)}
              size="small"
              sx={(theme) => ({
                borderRadius: "8px",
                border: `1px solid ${theme.palette.secondary.light}`,
              })}
            >
              <Icon
                height="20"
                icon="material-symbols-light:edit-outline-rounded"
                width="20"
              />
            </IconButton>
          </Tooltip>
        )}
        {deleteFn && showDelete && (
          <Tooltip title="Удалить">
            <IconButton
              color="secondary"
              onClick={() => setConfirmationId(row.original?.id)}
              size="small"
              sx={(theme) => ({
                borderRadius: "8px",
                border: `1px solid ${theme.palette.secondary.light}`,
              })}
            >
              <Icon
                height="20"
                icon="material-symbols-light:delete-outline-rounded"
                width="20"
              />
            </IconButton>
          </Tooltip>
        )}
      </RowOptionsStack>


  // <>
  //   {showActionsMode ?
  //     <RowOptionsStack>
  //       <IconButton
  //         color="secondary"
  //         onClick={()=>{setShowActionsMode(false)}}
  //         sx={{
  //           // transform: 'rotate(45deg)',
  //           transition: 'transform .2s ease-in-out',
  //         }}
  //       >
  //         <Icon icon="material-symbols-light:chevron-left-rounded"/>
  //       </IconButton>
  //       {editFn && showEdit && (
  //         <Tooltip title="Редактировать">
  //           <IconButton
  //             color="secondary"
  //             onClick={() => editFn(row.original)}
  //             size="small"
  //             sx={(theme) => ({
  //               borderRadius: "8px",
  //               border: `1px solid ${theme.palette.secondary.light}`,
  //             })}
  //           >
  //             <Icon
  //               height="20"
  //               icon="material-symbols-light:edit-outline-rounded"
  //               width="20"
  //             />
  //           </IconButton>
  //         </Tooltip>
  //       )}
  //       {deleteFn && showDelete && (
  //         <Tooltip title="Удалить">
  //           <IconButton
  //             color="secondary"
  //             onClick={() => setConfirmationId(row.original?.id)}
  //             size="small"
  //             sx={(theme) => ({
  //               borderRadius: "8px",
  //               border: `1px solid ${theme.palette.secondary.light}`,
  //             })}
  //           >
  //             <Icon
  //               height="20"
  //               icon="material-symbols-light:delete-outline-rounded"
  //               width="20"
  //             />
  //           </IconButton>
  //         </Tooltip>
  //       )}
  //     </RowOptionsStack>
  //     :
  //     <RowOptionsStack>
  //       <IconButton
  //         color="secondary"
  //         onClick={()=>{setShowActionsMode(true)}}
  //         sx={{
  //           transform: 'rotate(180deg)',
  //           transition: 'transform .2s ease-in-out',
  //         }}
  //       >
  //         <Icon icon="material-symbols-light:more-vert"/>
  //       </IconButton>
  //     </RowOptionsStack>
  //
  //   }
  // </>

  );
};

export default TableActions;
