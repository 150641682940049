import React from 'react'
import CustomTextField from '../../Fields/CustomTextField'
import { useTranslation } from 'react-i18next'
import CustomMultiTextFieldAutocompleteForFixedObject
    from "../../Fields/CustomMultiTextFieldAutocompleteForFixedObject";

const AccessGroupsFields = ({ autocompleteOptions, selectedRow, baseGroups, notEditGroups, fixRoles, fixGroup }) => {

  const { t } = useTranslation()

  return (
    <>
      <CustomTextField
        name="name"
        label={t('src.pages.AccessGroupsPage.groupName')}
        registerOptions={{
          required: t('main.another.requiredField'),
        }}
        size="small"
        disabled={baseGroups?.includes(selectedRow?.name) || false}
      />
      <CustomMultiTextFieldAutocompleteForFixedObject
        multiple={true}
        name="roles"
        label={t('src.pages.AccessGroupsPage.roles')}
        options={autocompleteOptions}
        rules={{
          required: t('main.another.requiredField'),
        }}
        size="small"
        fixObj={fixRoles}
        fixGroup={fixGroup}
        selectedRow={selectedRow?.name}
        notEditGroups={notEditGroups}
        disabled={notEditGroups?.includes(selectedRow?.name) || false}
      />
      <CustomTextField
        name="group_sso"
        label={t('src.pages.AccessGroupsPage.ssoGroup')}
        registerOptions={{
          required: t('main.another.requiredField'),
        }}
        size="small"
      />
    </>
  )
}

export default AccessGroupsFields