import React, { useState } from 'react';

import {Icon} from "@iconify/react";
import {Avatar, Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";

import useResponsive from "../../hook/useResponsive";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";
import InfoAboutComponent from "../InfoAboutComponent";
import UserData from "./UserData";


const Info = () => {

    const {
        name,
        last_name,
        email,
        avatar,
    } = useSelector(
      (state) => state.authReducer,
    );

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();

  const isMobile = useResponsive("down", "sm");

  return (
    <Box>

        <Card sx={{width:{ xs: "100%", md:"100%" }}}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p: 2,
            }}
          >
            <Typography variant="subtitle2">
              {t('src.pages.AccountPage.UserData.infoHead')}
            </Typography>
            {isMobile ? (
              <IconButton color="primary" onClick={() => navigate("/account/edit/me")} sx={{ p: 0 }}>
                <Icon
                  height="20"
                  icon="material-symbols-light:edit-outline-rounded"
                  width="20"
                />
              </IconButton>
            ) : (
              <Button
                color="primary"
                onClick={() => navigate("/account/edit/me")}
                size="small"
                startIcon={
                  <Icon
                    height="20"
                    icon="material-symbols-light:edit-outline-rounded"
                    width="20"
                  />
                }
                variant="text"
              >
                {t('main.another.edit')}
              </Button>
            )}
          </Stack>
          <Divider />
          <Box p={2}>
            <RowStack>
              <ColumnStack customwidth="20%" customspace={1}>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Аватар
                </Typography>
                <Avatar
                  alt="photoURL"
                  src={avatar}
                  sx={(theme) => ({
                    width: 150, height: 150,
                    border:`2px solid ${theme.palette.secondary.light}`
                  })}
                />
              </ColumnStack>
              <ColumnStack customwidth="80%" customspace={0.5}>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Общие данные
                </Typography>
                <InfoAboutComponent
                  label="Имя"
                  loading={loading}
                  text={name}
                />
                <InfoAboutComponent
                  label="Фамилия"
                  loading={loading}
                  text={last_name}
                />
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  pt={4}
                  variant="miniHeader"
                >
                  Контакты
                </Typography>
                <InfoAboutComponent
                  copyButton={true}
                  label="Email"
                  loading={loading}
                  text={email}
                />
              </ColumnStack>

            </RowStack>
          </Box>
        </Card>

    </Box>
  );
};

export default Info;