import React, {useEffect, useState} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Autocomplete, Box, Button, Card, Divider, Grid, IconButton, TextField, Typography} from "@mui/material";
import CustomTextField from "../Fields/CustomTextField";
import {useTranslation} from "react-i18next";
import Stack from "@mui/material/Stack";
import CustomTextFieldAutocomplete from "../Fields/CustomTextFieldAutocomplete";
import {$authHost} from "../../http";
import {useSelector} from "react-redux";
import useResponsive from "../../hook/useResponsive";
import CloseIcon from "@mui/icons-material/Close";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router";
import {ColumnStack, RowStack} from "../../theme/standarts_styles";

const EducationAddForm = ({handleClose, loadData}) => {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation()
  const isMobile = useResponsive('down', 'md')

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    register,
    formState: {errors, isDirty, isSubmitSuccessful},
    handleSubmit,
    control,
    reset,
    setError,
  } = methods;

  const {
    selectedIntellegence,
  } = useSelector(state => state.intelligensReducer)

  useEffect(() => {
    if (isSubmitSuccessful) {
      handleClose()
    }
  }, [isSubmitSuccessful]);

  const onSubmit = async (data) => {

    setLoading(true);

    var new_data = {
      question: data.question,
      answer: data.answer,
      assistant_type: data.assistant_type,
      assistant_id:
        selectedIntellegence.breaker_assistant.type === data.assistant_type ?
          selectedIntellegence.breaker_assistant.id
          :
          selectedIntellegence.output_assistant.id,
      is_run: false
    }
    try {
      await $authHost.post(`/model_management/add_row`, new_data).then((response) => {
        if (response.status === 201) {
          handleClose();
          loadData();
        }
      })
    } catch (e) {
      setError('root.serverError', {
        type: 'server',
        message: e.message,
      });
    } finally {
      setLoading(false)
    }
  };

  return (
    <Box>
        <Card sx={{mb: 2}}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p: 2
            }}
          >
            <Typography variant="subtitle2">
              {t('main.another.add')}
            </Typography>
            {isMobile ? (
              <IconButton color="primary" onClick={handleClose} sx={{ p: 0 }}>
                <CloseIcon/>
              </IconButton>
            ) : (
              <Button
                color="primary"
                onClick={handleClose}
                size="small"
                startIcon={
                  <CloseIcon/>
                }
                variant="text"
              >
                {t('main.another.cancel')}
              </Button>
            )}
          </Stack>
          <Divider />
          <Box sx={{p:2}}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <RowStack sx={{mt:1, mb:2}}>
                  <ColumnStack full={true} customspace={0.5}>
                    <CustomTextField
                      name="question"
                      label={t('src.pages.ModelManagement.question')}
                      registerOptions={{
                        required: t('main.another.requiredField'),
                      }}
                      required
                      size="small"
                    />
                    <CustomTextField
                      name="answer"
                      label={t('src.pages.ModelManagement.answer')}
                      registerOptions={{
                        required: t('main.another.requiredField'),
                      }}
                      required
                      size="small"
                    />
                    <CustomTextFieldAutocomplete
                      name="assistant_type"
                      label={t('src.pages.ModelManagement.assistant_type')}
                      options={[
                        {name: t('src.pages.Intelligence.breaker'), value: "breaker"},
                        {name: t('src.pages.Intelligence.stylist'), value: "output"},
                      ]}
                      rules={{
                        required: t('main.another.requiredField'),
                      }}
                      size="small"
                    />
                  </ColumnStack>
                </RowStack>
                {isMobile ?
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                      width: '100%',
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      size="small"
                      disabled={loading}
                      sx={{width: '100%'}}
                    >
                      {t('main.another.save')}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={!isDirty || loading}
                      onClick={() => reset()}
                      sx={{width: '100%'}}
                    >
                      {t('main.another.clear')}
                    </Button>
                  </Stack>
                  :
                  <Stack
                    direction="row"
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant='outlined'
                      onClick={() => reset()}
                      disabled={loading}
                      size="small"
                    >
                      {t('main.another.clear')}
                    </Button>
                    <Button
                      variant='contained'
                      type='submit'
                      size="small"
                      disabled={loading}
                    >
                      {t('main.another.save')}
                    </Button>
                  </Stack>
                }
              </form>
            </FormProvider>
          </Box>
        </Card>
    </Box>
  );
};

export default EducationAddForm;