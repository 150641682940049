import React from 'react';
import Stack from "@mui/material/Stack";
import {Box, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import UserData from "../components/Account/UserData";
import ChangePassword from "../components/Account/ChangePassword";
import DynamicTabs from '../components/Tabs/DynamicTabs';
import {TitleStack} from "../theme/standarts_styles";
import TitleAndButtonComponent from "../components/TitleAndButtonComponent";
import {useNavigate} from "react-router";
import Info from "../components/Account/Info";

const AccountPage = () => {

  const {t} = useTranslation()
  const navigate = useNavigate();

  return (
    <Box sx={{mb:2}}>
      <TitleAndButtonComponent
        back={() => {
          navigate("/");
        }}
        backTitle={t('main.routes.home')}
        title={t('src.pages.AccountPage.head')}
      />
      <DynamicTabs
        components={[
          {label: t('src.pages.AccountPage.tab1'), component: <Info/>},
          {label: t('src.pages.AccountPage.tab2'), component: <ChangePassword/>},
        ]}
      />
    </Box>
  );
};

export default AccountPage;
