import {
  Box,
  Button, ButtonGroup,
  Card, Divider,
  Popover,
  Typography,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import {useTranslation} from 'react-i18next';
import {ColumnStack, RowStack } from "../theme/standarts_styles";
import React, {useEffect, useState} from "react";
import {$authHost} from "../http";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import {MonthCalendar, YearCalendar} from "@mui/x-date-pickers";
import Stack from "@mui/material/Stack";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import LoadingIndicator from "../components/LoadingIndicator";
import {useSelector} from "react-redux";
import {useActions} from "../hook/useActions";
import MaterialTable from "../components/MaterialTable";
import {TableCellsWrap} from "../components/TableCellsWrap";
import InfoAboutComponent from "../components/InfoAboutComponent";

const MainPage = () => {
  const {t, i18n} = useTranslation()
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(dayjs());
  const [year, setYear] = useState(dayjs());
  const [anchorMonth, setAnchorMonth] = useState(null);
  const [anchorYear, setAnchorYear] = useState(null);
  const [metriks, setMetriks] = useState({});

  const {details} = useSelector((state) => state.statisticsReducer);
  const {setDetails} = useActions();

  const loadData = async () => {
    setLoading(true);
    try {
      const responseDetails = await $authHost.get('/billing/subscription/details')
      setDetails(responseDetails.data)
      const subscriptionId = responseDetails.data?.SubscriptionID;

      const selectedMonth = dayjs(month).month() + 1;
      const selectedYear = dayjs(year).year();

      const responseMetriks = await $authHost.get(`/billing/grouped_by_date?subscription_id=${subscriptionId}&month=${selectedMonth}&year=${selectedYear}`)
      setMetriks(responseMetriks.data)
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, [month, year])

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
    },
    // plotOptions: {
    //   bar: {
    //     horizontal: false,
    //     dataLabels: {
    //       total: {
    //         enabled: true,
    //         style: {
    //           fontSize: '13px',
    //           fontWeight: 900
    //         }
    //       }
    //     }
    //   },
    // },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'd MMM',
        formatter: function (value, timestamp) {
          return dayjs(timestamp).format('D MMM')
        },
      },
    },
    yaxis: {
      title: {
        text: 'Кол-во токенов'
      },
      decimalsInFloat: 0,
    },
    legend: {
      position: 'right',
      offsetY: 0
    },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: 'Нет данных для отображения',
      style: {
        color: '#000000',
        fontSize: '16px',
      }
    },
    responsive: [{
      breakpoint: 900,
      options: {
        legend: {
          position: 'bottom',
        },
      },
    }]
  }

  const renderTokens = (input, output) => {
    if ((input || input === 0) || (output || output === 0)) {
      return `${input} / ${output}`
    }
    return 'Не найдено'
  }

  const tableColumns = [
    {
      accessorKey: 'date',
      header: 'Дата',
      accessorFn: (row) => dayjs(row.date).format('DD.MM.YYYY') || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intelligence',
      header: 'Интеллект',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'gpt35-standard-input-tokens/gpt35-standard-output-tokens',
      header: 'GPT3.5 (in/out)',
      accessorFn: (row) => renderTokens(row['gpt35-standard-input-tokens'], row['gpt35-standard-output-tokens']),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'gpt4-standard-input-tokens/gpt4-standard-output-tokens',
      header: 'GPT4 (in/out)',
      accessorFn: (row) => renderTokens(row['gpt4-standard-input-tokens'], row['gpt4-standard-output-tokens']),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intelligence1',
      header: 'GPT4o (in/out)',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'gpt35-fine-tuning-input-tokens/gpt35-fine-tuning-output-tokens',
      header: 'ft-GPT3.5 (in/out)',
      accessorFn: (row) => renderTokens(row['gpt35-fine-tuning-input-tokens'], row['gpt35-fine-tuning-output-tokens']),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intelligence2',
      header: 'ft-GPT4 (in/out)',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intelligence3',
      header: 'ft-GPT4o (in/out)',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ]

  const handleClickMonth = (event) => {
    setAnchorMonth(event.currentTarget);
  };

  const handleCloseMonth = () => {
    setAnchorMonth(null);
  };

  const handleClickYear = (event) => {
    setAnchorYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorYear(null);
  };

  const handleNext = () => {
    const nextMonth = dayjs(month).add(1, 'month');

    if (dayjs(nextMonth).month() === 0 && dayjs(month).month() === 11) {
      const nextYear = dayjs(year).add(1, 'year');
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const handlePrev = () => {
    const nextMonth = dayjs(month).subtract(1, 'month');

    if (dayjs(nextMonth).month() === 11 && dayjs(month).month() === 0) {
      const nextYear = dayjs(year).subtract(1, 'year');
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  return (
    <Box
      sx={{
        pb: 3,
      }}
    >
      <Typography
        align="left"
        component="p"
        sx={{
          pt:0.5,
          pb:3
        }}
        variant="h4Wide"
      >
        {t('src.pages.MainPage.head')}
      </Typography>
      <Stack
        spacing={2}
        direction="column"
        sx={{
          width: "100%",

        }}
      >
        <Card
          sx={(theme) => ({
            margin: "0",
            width: { xs: "100%" },
          })}
        >
          <Typography
            sx={{
              padding: {
                xs: "16px 16px 16px 16px",
                sm: "16px 16px 8px 16px",
              },
              mb: 1,
            }}
            variant="subtitle2"
          >
            {t('src.pages.MainPage.abonentTitle')}
          </Typography>
          <Divider />
          <Box sx={{p:2}}>
            <RowStack>
              <ColumnStack customspace={0.5}>
                <Typography variant="miniHeader">ООО ASPEX</Typography>
                <Typography variant="miniHeader">Интегратор IT-решений</Typography>
                <Typography variant="miniHeader">Вадим Лотков</Typography>
                <Typography variant="miniHeader">Генеральный директор</Typography>
                <Typography variant="miniHeader">+7 (987) 654-32-10</Typography>
                <Typography variant="miniHeader">vadim.lotkov@example.com</Typography>
              </ColumnStack>
              <ColumnStack>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={
                    <PhoneInTalkIcon
                      fontSize="small"
                    />
                  }
                >
                  {t('src.pages.MainPage.support')}
                </Button>
                <Typography variant="body2">Режим работы:</Typography>
              </ColumnStack>
            </RowStack>

          </Box>
        </Card>

        <Card
          sx={(theme) => ({
            margin: "0",
            width: { xs: "100%" },
          })}
        >
          <Typography
            sx={{
              padding: {
                xs: "16px 16px 16px 16px",
                sm: "16px 16px 8px 16px",
              },
              mb: 1,
            }}
            variant="subtitle2"
          >
            {t('src.pages.MainPage.statusTitle')}
          </Typography>
          <Divider />
          <Box sx={{p:2}}>
            <RowStack>
              <ColumnStack customspace={0.5}>
                <InfoAboutComponent
                  label="Каналы"
                  text=""
                />
                <InfoAboutComponent
                  label="Наименование канала (тип)"
                  text=""
                />
                <InfoAboutComponent
                  label="Состояние канала"
                  text=""
                />
              </ColumnStack>
              <ColumnStack customspace={0.5}>
                <InfoAboutComponent
                  label="Интеллекты"
                  text=""
                />
                <InfoAboutComponent
                  label="Наименование интеллекта"
                  text=""
                />
                <InfoAboutComponent
                  label="Состояние интеллекта"
                  text=""
                />
              </ColumnStack>
            </RowStack>
          </Box>
        </Card>

        <Card
          sx={(theme) => ({
            margin: "0",
            width: { xs: "100%" },
          })}
        >
          <Typography
            sx={{
              padding: {
                xs: "16px 16px 16px 16px",
                sm: "16px 16px 8px 16px",
              },
              mb: 1,
            }}
            variant="subtitle2"
          >
            {t('src.pages.MainPage.tariffTitle')}
          </Typography>
          <Divider />
          <Box sx={{p:2}}>
            <RowStack>
              <ColumnStack customspace={0.5}>
                <InfoAboutComponent
                  label="Текущий баланс"
                  text=""
                />
                <InfoAboutComponent
                  label="Тарифный план"
                  text={details?.Tariffs?.TariffName}
                />
                <InfoAboutComponent
                  label="Тарификация"
                  text={details?.Tariffs?.TariffPaymentType}
                />
                <InfoAboutComponent
                  label="Стоимость тарифа"
                  text={details?.Tariffs?.TariffPrice}
                />
              </ColumnStack>
              <ColumnStack customspace={0.5}>
                <InfoAboutComponent
                  label="Стоимость в текущем календарном месяце"
                  text=""
                />
                <InfoAboutComponent
                  label="Количество каналов"
                  text=""
                />
                <InfoAboutComponent
                  label="Количество интеллектов"
                  text=""
                />
                <InfoAboutComponent
                  label="Объем данных"
                  text=""
                />
              </ColumnStack>
            </RowStack>
          </Box>
        </Card>

        <Card>
          <Stack>
            {loading && <LoadingIndicator/>}
            <Stack>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 1,
                  padding: {
                    xs: "16px 16px 16px 16px",
                    sm: "16px 16px 8px 16px",
                  },
                }}
              >
                <Typography variant="subtitle2">
                  Статистика
                </Typography>

                <Stack
                  alignItems="center"
                  direction="row"
                  sx={(theme) => ({
                    // backgroundColor:'common.white',
                    borderRadius: "8px",
                    border: `1px solid ${theme.palette.divider}`,
                    px: 1,
                    py: 0.5,
                    // boxShadow: theme.customShadows.card,
                  })}
                >
                  <IconButton onClick={handlePrev} sx={{ padding: 0 }}>
                    <NavigateBeforeIcon color="secondary" />
                  </IconButton>
                  <ButtonGroup color="secondary" size="small" variant="text">
                    <Button
                      color="secondary"
                      onClick={handleClickMonth}
                      sx={{
                        paddingRight: 1,
                        paddingTop: 0,
                        paddingBottom: 0,
                        borderRightWidth: "2px !important",
                      }}
                    >
                      {dayjs(month).format("MMMM")}
                    </Button>
                    <Button
                      color="secondary"
                      onClick={handleClickYear}
                      sx={{ paddingLeft: 1, paddingTop: 0, paddingBottom: 0 }}
                    >
                      {dayjs(year).format("YYYY")}
                    </Button>
                  </ButtonGroup>
                  <IconButton onClick={handleNext} sx={{ padding: 0 }}>
                    <NavigateNextIcon color="secondary" />
                  </IconButton>
                  <Popover
                    anchorEl={anchorMonth}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    onClose={handleCloseMonth}
                    open={!!anchorMonth}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MonthCalendar
                        onChange={(v) => setMonth(v)}
                        value={month}
                      />
                    </LocalizationProvider>
                  </Popover>
                  <Popover
                    anchorEl={anchorYear}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    onClose={handleCloseYear}
                    open={!!anchorYear}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <YearCalendar onChange={(v) => setYear(v)} value={year} />
                    </LocalizationProvider>
                  </Popover>
                </Stack>
              </Stack>
              <Divider />
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  p:2
                }}
              >
                <ReactApexChart options={options}
                                series={Array.isArray(metriks.resource_metriks) ? metriks.resource_metriks : []}
                                type="bar" height={350}/>


                <MaterialTable data={Array.isArray(metriks.day_metriks) ? metriks.day_metriks : []} columns={tableColumns}
                               enableTopToolbar={false} loading={loading}/>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export default MainPage;
