import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Controller, useFormContext } from 'react-hook-form'

const CustomTextFieldAutocomplete = ({ name, label, options, rules, multiple, size, sx, ...rest }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const error = errors[name]
  const req = !!rules?.required

  return (
    <div style={{
        position: 'relative',
        // marginBottom: '12px',
        width: '100%'
    }}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            value={options.find(option => option.value === value) || null}
            id={name}
            options={options && options}
            getOptionLabel={option => option.name}
            onChange={(_, data) =>
              onChange(data?.value || '')
            }
            size={size}
            {...rest}
            renderInput={params => (
              <TextField
                {...params}
                {...rest}
                variant="outlined"
                label={`${label}${req ? ' *' : ''}`}
                error={!!error}
                size={size}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={(theme) => ({
                  '& .MuiOutlinedInput-root':{
                    '& fieldset': {
                      borderColor: `${theme.palette.divider}`,
                    },
                  },
                  width:'100%',
                  '& input': {
                    fontSize:'15px'
                  },
                  '& .MuiInputBase-inputMultiline':{
                    fontSize:'15px'
                  },
                  ...sx
                })}
              />
            )}
          />
        )}
      />
      <span
        style={{
          position: 'absolute',
          bottom: '4px',
          fontSize: '12px',
          color: 'red',
          left: 14,
        }}
      >
          {error && (error?.message || 'Unknown error!')}
        </span>
    </div>
  )
}

export default CustomTextFieldAutocomplete
