import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import ListAltIcon from "@mui/icons-material/ListAlt";
import useResponsive from "../../hook/useResponsive";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import {useTheme} from "@mui/material/styles";
import {FolderOutlined, FolderSpecialOutlined} from "@mui/icons-material";
import {Icon} from "@iconify/react";

export const TypeIcon = (props) => {
    const isMobile = useResponsive('down', 'md');
    const theme = useTheme();

    const {
        isMoveMode,
        moveNode
    } = useSelector(state => state.docsReducer)

    if (props.droppable) {
        return(
          <Icon
            icon="material-symbols-light:folder-outline-rounded"
            height={isMobile ? 24 : 28}
            color="default"
            width={isMobile ? 24 : 28}
            sx={{ mr: isMobile ? 0 : 2 }}
          />
        )
    }
    return <Icon
      icon="material-symbols-light:docs-outline-rounded"
      height={isMobile ? 24 : 28}
      width={isMobile ? 24 : 28}
      color={isMoveMode && moveNode.id !== props.id ? 'disabled': 'default'}
      sx={{ color: `${theme.palette.primary.dark}`,mr: isMobile ? 0 : 2}}
    />;

};