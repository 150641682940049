import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router'
import Header from './header/Header'
import { useSelector } from 'react-redux'
import Nav from './nav/Nav'
import {LoginPreview, Main, StyledRoot} from '../theme/standarts_styles'
import { useActions } from '../hook/useActions'
import { useMediaQuery } from "react-responsive";

export default function DashboardLayout () {

  const { pathname } = useLocation()
  const isTabletPortraitOrMobile = useMediaQuery({ query: "(max-width: 790px)"});
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width:790px)"});

  const { status } = useSelector(state => state.authReducer)
  const { openNav } = useSelector(state => state.serviceReducer)
  const {
    setOpenNav,
    toggleOpenNav,
  } = useActions()

  useEffect(() => {  //закрываем боковое меню при навигации с мобильного устройства
    if (openNav && !isTabletPortraitOrMobile) {
      toggleOpenNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    //закрываем боковое меню при входе в режим мобильного устройства
    if (!isTabletPortraitOrMobile) {
      setOpenNav(false);
    }
  }, [isTabletPortraitOrMobile, setOpenNav]);

  return (
    <StyledRoot>
      {status === 'Active' && !isDesktopOrLaptop && <Header />}
      {status === 'Active' && <Nav />}
      {status === 'Active' ? (
        <Main isAuth={status === 'Active'}>
          <Outlet />
        </Main>
      ) : (
        <LoginPreview>
          <Outlet />
        </LoginPreview>
      )}
    </StyledRoot>
  )
}
