import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  IconButton,
  Stack,
  Switch, Tooltip,
  Typography,
} from '@mui/material';
import Iconify from '../components/iconify';
import {$authHost} from "../http";
import {useActions} from "../hook/useActions";
import {useSelector} from "react-redux";
import {useNavigate} from 'react-router';
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingSpinner from "../components/modals/loadingSpinner";
import {TableCellsWrap} from "../components/TableCellsWrap";
import MaterialTable from "../components/MaterialTable";
import {RowOptionsStack, TitleStack} from "../theme/standarts_styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import TitleAndButtonComponent from "../components/TitleAndButtonComponent";
import TableActions from "../components/TableActions";

export default function Channels() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [confirmationId, setConfirmationId] = useState(null);

  const tableColumns = [
    {
      accessorKey: 'name',
      header: t('src.pages.Channels.name'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intellect',
      header: t('src.pages.Channels.intellect'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'channel_type',
      header: t('src.pages.Channels.channelType'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'token',
    //   header: t('src.pages.Channels.token'),
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: 'introduction',
      header: t('src.pages.Channels.introduction'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({renderedCellValue, row}) => (
        <TableActions
          confirmationId={confirmationId}
          deleteFn={handleDeleteRow}
          editFn={() => handleGoToEditPage(row.original.id)}
          row={row}
          setConfirmationId={setConfirmationId}
          showDelete={true}
          customElement={
            <Tooltip title={row.original.is_run ? t('src.pages.Channels.turnOff') : t('src.pages.Channels.turnOn')}>
              <Switch
                checked={String(row.original.is_run) === 'true'} size="small"
                disabled={!row.original.intellect}
                color="secondary"
                onChange={(event, checked) => handleIsRun(row.original.id, checked)}/>
            </Tooltip>
          }
        />)
    },
  ]

  const [loading, setLoading] = useState(false);

  const {
    setChannels,
    setChannelsTypeFormIsAdd,
    setSelectedChannel,
    setSelectedQuestions
  } = useActions();

  const {
    channels,
    channelsTypeFormIsAdd,
    selectedChannel,
    questions,
    selectedQuestions
  } = useSelector(state => state.channelsReducer)

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get('/channel/');
        return response.data;
      }
      newChannelList().then((data) => {
        setChannels(data)
      });
    } catch (e) {
    }
  }, []);

  function handleGoToAddPage(selectedChannel = null) {
    navigate('/channels/new/general')
  }

  async function handleIsRun(id_row, is_run) {
    setLoading(true);
    try {
      await $authHost.patch(`/channel/set_is_run?id=${id_row}&bool=${is_run}`)
        .then(response => {
          loadData();
        })
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteRow(id_row) {
    setLoading(true);
    try {
      await $authHost.delete(`/channel/delete_row?id=${id_row}`)
        .then(response => {
          loadData();
          setConfirmationId(null);
        })
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  function handleGoToEditPage(selectedChannel) {
    navigate(`/channels/edit/${selectedChannel}`);
    setSelectedChannel(selectedChannel);
    setSelectedQuestions(selectedChannel);
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Box sx={{pb:2}}>
      {loading && <LoadingSpinner/>}
      <TitleAndButtonComponent
        action={handleGoToAddPage}
        back={() => {
          navigate("/");
        }}
        backTitle={t('main.routes.home')}
        name={t('src.pages.Channels.newChannel')}
        title={t('src.pages.Channels.channel')}
        type={true}
      />
      <Card>
        <MaterialTable data={channels} columns={tableColumns}/>
      </Card>
    </Box>
  );
};
