import React, { useEffect, useState } from 'react'
// @mui
import { alpha } from '@mui/material/styles'
import {Box, Grid, IconButton, ListItemText, MenuItem, Popover, Stack, Typography} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {NavLink as RouterLink} from 'react-router';
import {useSelector} from "react-redux";
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import useResponsive from "../../hook/useResponsive";
import {Icon} from "@iconify/react";
import {StyledNavItem} from "../../theme/standarts_styles";

export default function LanguagePopover (props) {

  const { t, i18n } = useTranslation();
  const { openNav } = useSelector(state => state.serviceReducer)
  const [open, setOpen] = useState(null);
  const isMobile = useResponsive('down', 'sm')
  const LANGS = [
    {
      value: 'ru',
      shortLabel: t('main.lang.ru.shortLabel'),
      label: t('main.lang.ru.label'),
      // icon: '/assets/icons/ic_flag_ru.svg',
    },
    {
      value: 'en',
      shortLabel: t('main.lang.en.shortLabel'),
      label: t('main.lang.en.label'),
      // icon: '/assets/icons/ic_flag_en.svg',
    },
  ]
  const [selectedLang, setSelectedLang] = useState('')
  // const [selectedLang, setSelectedLang] = useState(LANGS[LANGS.findIndex((locale) => locale.value === i18n.resolvedLanguage)]);
  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleChangeLanguage = (locale) => {
    i18n.changeLanguage(locale.value)
    // setSelectedLang(locale)
    setOpen(null)
  }

  useEffect(() => {
    setSelectedLang(LANGS[LANGS.findIndex((locale) => locale.value === i18n.resolvedLanguage)])
  }, [i18n.resolvedLanguage])

  return (
    <>
      {props?.inNav ? (
        <Box sx={{width:'100%'}}>
          {openNav ? (
            <StyledNavItem
              onClick={handleOpen}
              sx={{
                color: "text.primary",
                mx:0.5,
              }}
            >
              <Stack
                direction="row" px={1} spacing={1}
              >
                <Icon height="20" icon="ph:translate" width="20"  />                      <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2">
                    Язык
                  </Typography>
                }
              />
              </Stack>
            </StyledNavItem>
          ) : (
            <Box
              sx={{
                width:'100%',
                display:'flex',
                alignItems:'center',
                direction:'column',
                justifyContent:'center',
              }}
            >
              <IconButton
                onClick={handleOpen}
                color="primary"
                sx={{
                  color: 'layout.element'
                }}
                size='small'
              >
                <Icon height="20" icon="ph:translate" width="20"  />
              </IconButton>
            </Box>
          )}
        </Box>
      ): (
        <IconButton
          onClick={handleOpen}
          color="primary"
          sx={{
            color: 'layout.element'
          }}
          size='small'
        >
          <Icon height="20" icon="ph:translate" width="20"  />
        </IconButton>
      )}


      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ zIndex: '10000' }}
        PaperProps={{
          sx: {
            backgroundColor: 'background.neutral',
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((locale) => (
            <MenuItem key={locale.value} selected={locale.value === selectedLang.value}
                      onClick={() => handleChangeLanguage(locale)}>
              {/*<Box component="img" alt={locale.label} src={locale.icon} sx={{ width: 28, mr: 2 }} />*/}

              {locale.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  )
}
