import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  IconButton, Typography
} from '@mui/material'
import AccountPopover from './AccountPopover'
import LanguagePopover from './LanguagePopover'
import Logo from '../../components/Logo'
import SearchFieldInAppBar from './SearchFieldInAppBar'
import useResponsive from '../../hook/useResponsive'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, {useRef} from 'react'
import { StyledRootAppBar, StyledToolbar} from '../../theme/standarts_styles'
import SearchIcon from "@mui/icons-material/Search";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";


Header.propTypes = {
  onOpenNav: PropTypes.func,
}

export default function Header () {
  const { t } = useTranslation()

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const containerRef = useRef(null);
  const { sseManagerError, sseChannelError, sseChannelStatus } = useSelector(state => state.serviceReducer)
  const { status } = useSelector(state => state.authReducer)

  const currentLocation = status === 'Active' || status === 'Locked'
  const isDesktop = useResponsive('up', 'lg')



  return (
    <StyledRootAppBar>
      <StyledToolbar>
        <Logo/>

        <Box sx={{ flexGrow: 1 }}/>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            width:'100%',
          }}
        >
          <Box/>
          <Box>
            <LanguagePopover/>
          </Box>
          <Box>{currentLocation && <AccountPopover />}</Box>
        </Stack>
      </StyledToolbar>
    </StyledRootAppBar>
  )
}
