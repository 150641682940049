import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

const CustomAutocomplete = ({
                              name,
                              label,
                              options,
                              required,
                              rules,
                              InputLabelProps,
                              inputProps,
                              sx,
                              value: customValue,
                              getOptionLabel = (option) => option,
                              isOptionEqualToValue = (option, value) => option === value,
                              ...props
                            }) => {
  const {t} = useTranslation();

  const {control} = useFormContext();

  if (required) {
    rules = {required: t("main.another.requiredField"), ...rules};
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
                 field: {onChange, value, ...field},
                 fieldState: {error},
               }) => {
        return (
          // TODO: Добавить переводы
          <Autocomplete
            {...field}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            noOptionsText="Нет доступных вариантов"
            onChange={(e, value) => {
              if (customValue && value) {
                onChange(value[customValue])
              } else {
                onChange(value)
              }
            }}
            options={options || []}
            sx={{
              mb: 2,
              ...sx,
            }}
            value={(customValue ? options.find(option => option[customValue] === value) : options.find(option => option === value)) || null}
            {...props}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={(theme) => ({
                  '& .MuiOutlinedInput-root':{
                    '& fieldset': {
                      borderColor: `${theme.palette.divider}`,
                    },
                  },
                  width:'100%',
                  '& input': {
                    fontSize:'15px'
                  },
                  '& .MuiInputBase-inputMultiline':{
                    fontSize:'15px'
                  },
                  '& .MuiFormHelperText-root':{
                    fontSize:'12px'
                  },
                  ...sx
                })}
                InputLabelProps={{
                  shrink: true,
                  ...InputLabelProps,
                }}
                error={!!error}
                fullWidth
                helperText={error && (error?.message || "Unknown error!")}
                label={`${label}${required || !!rules?.required ? " *" : ""}`}
                size="small"
                variant="outlined"
                {...inputProps}

              />
            )}
          />
        );
      }}
      rules={rules}
    />
  );
};

CustomAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  rules: PropTypes.object,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  sx: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
};

export default CustomAutocomplete;
