import {Box, Button, Grid, Stack, Tooltip} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'
import React from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import useResponsive from "../../hook/useResponsive";
import {Icon} from "@iconify/react";
const AddEditButtonForm = ({
                                    action,
                                    handleClose,
                                    handleDeleteRow,
                                    confirmationForEditForm,
                                    setConfirmationForEditForm, selectedRow, baseGroups
                                }) => {
    const { t } = useTranslation()
    const isMobile = useResponsive('down', 'sm')
    const {
        handleClearFields,
    } = useFormContext()

    return (
        <>
            {isMobile ?
                <Stack
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                  spacing={1}
                  sx={{ width: "100%", mt: 0 }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{width: '100%'}}
                  >
                    {action === 'Add'
                      ? t('main.another.add')
                      : t('main.another.save')}
                  </Button>
                  <Button
                    size="small"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    color="primary"
                    onClick={handleClearFields}
                  >
                      {action === 'Add'
                          ? t('src.components.Intelligence.clear')
                          : t('src.components.Intelligence.reset')
                      }
                  </Button>

                  {(confirmationForEditForm)
                      ?
                      <Stack width="100%" direction="row" justifyContent="center"
                             alignItems="center"
                             spacing={2}>
                          <Tooltip title="Подтвердить">
                            <IconButton
                              color="success"
                              onClick={() => handleDeleteRow}
                              size="small"
                              sx={(theme) => ({
                                borderRadius: "180px",
                                border: `1px solid ${theme.palette.success.main}`,
                              })}
                            >
                              <Icon height="20" icon="material-symbols-light:check" width="20" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Отмена">
                            <IconButton
                              color="error"
                              onClick={() => setConfirmationForEditForm(null)}                              size="small"
                              sx={(theme) => ({
                                borderRadius: "180px",
                                border: `1px solid ${theme.palette.error.main}`,
                              })}
                            >
                              <Icon height="20" icon="material-symbols-light:close" width="20" />
                            </IconButton>
                          </Tooltip>
                      </Stack>
                      : (action === 'Update' && handleDeleteRow) &&
                      <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          startIcon={<DeleteIcon />}
                          sx={{
                              width: '100%'
                          }}
                          disabled={baseGroups?.includes(selectedRow?.name) || false}
                          onClick={() => setConfirmationForEditForm(true)}
                      >
                          {t('src.components.Intelligence.delete', 'Удалить элемент')}
                      </Button>
                  }
                </Stack>
                :
                <Stack
                  direction='row'
                  spacing={1} alignItems="center" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleClearFields}
                  >
                    {action === 'Add'
                      ? t('src.components.Intelligence.clear')
                      : t('src.components.Intelligence.reset')
                    }
                  </Button>

                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      alignItems:'center',
                    }}
                  >
                    {(confirmationForEditForm)
                      ? <>
                        <Tooltip title="Подтвердить">
                          <IconButton
                            color="success"
                            onClick={handleDeleteRow}
                            size="small"
                            sx={(theme) => ({
                              borderRadius: "180px",
                              border: `1px solid ${theme.palette.success.main}`,
                            })}
                          >
                            <Icon height="20" icon="material-symbols-light:check" width="20" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Отмена">
                          <IconButton
                            color="error"
                            onClick={() => setConfirmationForEditForm(null)}
                            size="small"
                            sx={(theme) => ({
                              borderRadius: "180px",
                              border: `1px solid ${theme.palette.error.main}`,
                            })}
                          >
                            <Icon height="20" icon="material-symbols-light:close" width="20" />
                          </IconButton>
                        </Tooltip>
                      </>
                      : (action === 'Update' && handleDeleteRow) &&
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        startIcon={<DeleteIcon />}
                        disabled={baseGroups?.includes(selectedRow?.name) || false}
                        onClick={() => setConfirmationForEditForm(true)}
                      >
                        {t('src.components.Intelligence.delete', 'Удалить элемент')}
                      </Button>
                    }
                    <Button
                      variant="contained"
                      type="submit"
                      size="small"
                    >
                      {action === 'Add'
                        ? t('src.components.Intelligence.add')
                        : t('main.another.save')}
                    </Button>
                  </Stack>
                </Stack>
            }
        </>
    )
}

export default AddEditButtonForm