import {useState} from 'react';
import {TextField} from "@mui/material";
import {SearchInput, StyledLink} from "../../theme/standarts_styles";
import {useNavigate} from 'react-router';
import {getRoutes} from "../../routes";


const SearchFieldInAppBar = (props) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  const routes = getRoutes();
  const searchList = [];

  const forEachArr = (arr) => {
    arr.forEach((item) => {
      if (item.searchText) {
        searchList.push(item);
      }
      if (Array.isArray(item.children)) {
        forEachArr(item.children)
      }
    });
  }

  forEachArr(routes);

  return (
    <>
      <div
        style={{
          width: '100%',
          display: "flex",
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <SearchInput
          value={autocompleteValue}
          options={searchList}
          size="small"
          sx={{
            width: `${props.width}`,
          }}
          blurOnSelect={true}
          forcePopupIcon={false}
          isOptionEqualToValue={(option, value) => option.searchText === value}
          getOptionLabel={(option) => option.searchText}
          noOptionsText='Нет доступных вариантов'
          onChange={(event, newValue) => {
            if (newValue) {
              navigate(newValue.path);
            }
            setAutocompleteValue(null);
            setInputValue('');
          }}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          inputValue={inputValue}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                disableUnderline: true,
                sx: {
                  color: "text.secondary",
                },
                ...params.InputProps,
              }}
              color="secondary"
              placeholder="Поиск..."
              size="small"
              sx={(theme) => ({
                '& .MuiOutlinedInput-root':{
                  '& fieldset': {
                    borderColor: `${theme.palette.divider}`,
                  },
                  backgroundColor: `${theme.palette.background.default}`,

                },
                '& input': {
                  fontSize:'12px'
                },
              })}
              type="search"
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
            >
              <StyledLink
                sx={{ zIndex: "50000 !important", fontSize:"12px", color: "text.secondary" }}
                to={`/${option.path}`}
              >
                {option.searchText}
              </StyledLink>
            </li>
          )}
        />
      </div>
    </>
  );
};

export default SearchFieldInAppBar;
