import React from 'react'
import CustomTextField from '../../Fields/CustomTextField'
import { useTranslation } from 'react-i18next'
import CustomMultiTextFieldAutocompleteForObject from '../../Fields/CustomMultiTextFieldAutocompleteForObject'
import {ColumnStack, RowStack} from "../../../theme/standarts_styles";

const AccessUserFields = ({ autocompleteOptions }) => {

  const { t } = useTranslation()

  return (
    <RowStack sx={{my:1}}>
      <ColumnStack full={true} customspace={0.5}>
        <CustomTextField
          name="email"
          label={t('src.components.Forms.FormFields.AccessGroupsFields.table_email', 'E-mail')}
          registerOptions={{
            required: t('main.another.requiredField'),
          }}
          disabled={true}
          size="small"
        />
        <CustomTextField
          name="name"
          label={t('src.components.Forms.FormFields.AccessGroupsFields.table_name', 'Имя')}
          registerOptions={{
            required: t('main.another.requiredField'),
          }}
          size="small"
        />
        <CustomTextField
          name="last_name"
          label={t('src.components.Forms.FormFields.AccessGroupsFields.table_lastName', 'Фамилия')}
          registerOptions={{
            required: t('main.another.requiredField'),
          }}
          size="small"
        />
          {/*<CustomTextField*/}
          {/*    name="department"*/}
          {/*    label={t('src.components.Forms.FormFields.AccessGroupsFields.table_lastName', 'Отдел')}*/}
          {/*    sx={{ width: '100%', fontSize: '12px', mb: 3 }}*/}
          {/*    size="small"*/}
          {/*/>*/}
          {/*<CustomTextField*/}
          {/*    name="job_title"*/}
          {/*    label={t('src.components.Forms.FormFields.AccessGroupsFields.table_lastName', 'Должность')}*/}
          {/*    sx={{ width: '100%', fontSize: '12px', mb: 3 }}*/}
          {/*    size="small"*/}
          {/*/>*/}
        <CustomMultiTextFieldAutocompleteForObject
          multiple={true}
          name="groups"
          label={t('src.components.Forms.FormFields.AccessGroupsFields.table_groups', 'Группа')}
          options={autocompleteOptions}
          rules={{
            required: t('main.another.requiredField'),
          }}
          size="small"
        />
      </ColumnStack>
    </RowStack>
  )
}

export default AccessUserFields