import React, {useCallback, useEffect, useRef} from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  Fab,
  Grid,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Iconify from '../components/iconify'
import SendIcon from '@mui/icons-material/Send'
import {useTranslation} from 'react-i18next'
import {$authHost} from '../http'
import {useActions} from '../hook/useActions'
import {useSelector} from 'react-redux'
import {BoxMessage, ButtonStack, PaperDiv, StyledMessage, TitleStack} from '../theme/standarts_styles'
import Chip from "@mui/material/Chip";
import MessageBubble from "../components/Messages/MessageBubble";
import {useTheme} from "@mui/material/styles";
import useResponsive from "../hook/useResponsive";
import TitleAndButtonComponent from "../components/TitleAndButtonComponent";
import {useNavigate} from "react-router";

const Sandbox = () => {

  const navigate = useNavigate();
  const {t} = useTranslation()
  const listRefSand = useRef(null)
  const isMobile = useResponsive('down', 'md')


  const {
    setChannels,
    setSandboxDialog,
    selectSandboxChannel,
  } = useActions()
  const {
    sandboxDialog,
    sandboxChannel,
  } = useSelector(state => state.sandboxReducer)

  const {channels} = useSelector(state => state.channelsReducer)
  const {userId} = useSelector(state => state.authReducer)
  const {sseChannelError} = useSelector(state => state.serviceReducer)

  // console.log("sandboxChannel ", sandboxChannel)

  const sendMessage = async (text) => {
    try {
      const message = {
        'text': text,
        'recipient': 'prompt_playground',
        'sender': userId,
        'channel_id': sandboxChannel.id
      }
      return await $authHost.post(`/intellect_playground/send_message_to_prompt_playground`, message)
    } catch (e) {
    }
  }
  const handleClearChat = async () => {
    try {
      const response = await $authHost.post(`/intellect_playground/clear_chat_prompt?channel_id=${sandboxChannel.id}`)
      if (response.status === 201) {
        setSandboxDialog([])
      }
    } catch (e) {
    }
  }
  const handleSendMessage = async () => {
    const messageSandboxText = document.getElementById('draftMessageField').value //временное решение проблемы перерендера
    const response = await sendMessage(messageSandboxText)
    if (response.status === 201) {
      // setSandboxDialog({text: messageSandboxText, side: 'left'}) // убрал т.к дублируется сообщение через SSE
      // changeDraftMessage("");
      document.getElementById('draftMessageField').value = ''
      // answerAdded(selectedCustomer.telegram_id)
    }
  }

  const loadData = useCallback(async () => {

    if (sandboxChannel !== null) {
      try {
        const newSandboxDialog = async () => {
          const responseChannelList = await $authHost.get(`/intellect_playground/get_messages_prompt_playground?channel_id=${sandboxChannel?.id}`)
          return responseChannelList.data
        }
        newSandboxDialog().then((data) => {
          setSandboxDialog(data)
        })
      } catch (e) {
      }
    }

    try {
      const newChannels = async () => {
        const responseChannels = await $authHost.get(`/channel/get_all_for_combobox`)
        return responseChannels.data
      }
      newChannels().then((data) => {
        setChannels(data)
      })
    } catch (e) {
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    try {
      const newSandboxDialog = async () => {
        const responseChannelList = await $authHost.get(`/intellect_playground/get_messages_prompt_playground?channel_id=${sandboxChannel?.id}`)
        return responseChannelList.data
      }
      newSandboxDialog().then((data) => {
        setSandboxDialog(data)
      })
    } catch (e) {
    }
  }, [sandboxChannel])

  let previousDateSand = null // Переменная для хранения предыдущей даты


  useEffect(() => {
    listRefSand.current.scrollTop = listRefSand.current.scrollHeight;
  }, [sandboxDialog])

  const theme = useTheme()

  return (
    <Box sx={{pb:2}}>
      <TitleAndButtonComponent
        back={() => {
          navigate("/");
        }}
        backTitle={t('main.routes.home')}
        title={t('src.pages.Sandbox.sandbox')}
        type={true}
      />
      <Stack
        direction={{xs:'column',md:'row'}}
        spacing={2}
        sx={{
          alignItems:'center',
          justifyContent:'space-between',
          mb:2
        }}
      >
        <Autocomplete
          value={sandboxChannel}
          id="sandboxChannel"
          size="small"
          sx={{width: isMobile? '100%': 300}}
          options={channels && channels}
          getOptionLabel={(option) => option.name ?? option}
          isOptionEqualToValue={(option, value) => String(option.name) === String(value)}
          onChange={(event, data) => {
            selectSandboxChannel(data)
          }
          }
          renderInput={(params) => (
            <TextField
              variant="outlined"
              size="small"
              label={t('src.pages.Sandbox.connectedChannel')}
              {...params}
              sx={(theme) => ({
                '& .MuiOutlinedInput-root':{
                  '& fieldset': {
                    borderColor: `${theme.palette.divider}`,
                  },
                },
                width:'100%',
                '& input': {
                  fontSize:'15px'
                },
                '& .MuiInputBase-inputMultiline':{
                  fontSize:'15px'
                }
              })}
              // error={!!errorsUpdate.intellect}
            />
          )}
        />
        <Stack
          direction="row"
          justifyContent='flex-end'
          spacing={1}
          width={isMobile && '100%'}
        >
          <Button
            variant="contained"
            disabled={sseChannelError || !sandboxChannel}
            onClick={() => sendMessage('/separator')}
            size="small"
          >
            {t('src.pages.Sandbox.newSession')}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClearChat}
            size='small'
            disabled={sseChannelError || !sandboxChannel}
          >
            {t('src.pages.Sandbox.clearChat')}
          </Button>
        </Stack>

      </Stack>


        <Card>
          <div className="ContainerDiv" style={{border: '8px'}}>
            <PaperDiv>
              <div className="MessagesBodyDiv"
                   ref={listRefSand}
                // onScroll={handleScroll}
              >
                <div style={{position: 'relative'}}>
                  {sandboxChannel ?
                    <>
                      {sandboxDialog.map((value, index) => {
                        const {text, sender, created_at} = value
                        const separator = text === '/separator'
                        const side = sender === userId ? 'right' : 'left'
                        const date = new Date(created_at)
                        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
                        const day = String(localDate.getDate()).padStart(2, '0')
                        const month = String(localDate.getMonth() + 1).padStart(2, '0')
                        const year = String(localDate.getFullYear()).slice(-2)
                        const formattedDate = `${day}.${month}.${year}г.`
                        const hours = localDate.getHours().toString().padStart(2, '0')
                        const minutes = localDate.getMinutes().toString().padStart(2, '0')
                        // Проверяем, отличается ли предыдущая дата от текущей
                        const isDifferentDate = previousDateSand !== formattedDate
                        previousDateSand = formattedDate  // Обновляем предыдущую дату

                        return (
                          <>
                            {isDifferentDate &&
                              <Grid item xs={12} sx={{margin: '24px 0'}}>
                                <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}>
                                  <Chip
                                    label={
                                      <Typography variant="body2">
                                        {formattedDate}
                                      </Typography>
                                    }
                                    size="small"
                                    sx={{
                                      color: 'white',
                                      backgroundColor: 'rgba(0,0,0,0.6)'
                                    }}
                                  />
                                </Box>
                              </Grid>
                            }
                            {
                              separator
                                ? <Grid item xs={12}>
                                  <Box
                                    id="newMessagesSeparator"
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      backgroundColor: 'rgba(118, 176, 241, 0.3)',
                                      my: '8px',
                                    }}
                                  >
                                    <Typography variant="body1">
                                      {t('src.pages.Sandbox.newSession')}
                                    </Typography>
                                  </Box>
                                </Grid>
                                : <ListItem key={index} sx={{pt: 0, pb: 1}}>
                                  <Grid container className="sandboxDialog"
                                        sx={{
                                          overflowY: 'auto',
                                          // maxHeight: 'calc(100vh - 230px)'
                                        }}
                                  >
                                    {/*{isDifferentDate &&*/}
                                    {/*    <Grid item xs={12} sx={{ margin: '24px 0' }}>*/}
                                    {/*      <Box sx={{*/}
                                    {/*        display: 'flex',*/}
                                    {/*        justifyContent: 'center',*/}
                                    {/*      }}>*/}
                                    {/*        <Chip*/}
                                    {/*            label={*/}
                                    {/*              <Typography variant="body2">*/}
                                    {/*                {formattedDate}*/}
                                    {/*              </Typography>*/}
                                    {/*            }*/}
                                    {/*            size="small"*/}
                                    {/*            sx={{*/}
                                    {/*              color: 'white',*/}
                                    {/*              backgroundColor: 'rgba(0,0,0,0.6)'*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*      </Box>*/}
                                    {/*    </Grid>*/}
                                    {/*}*/}
                                    <Grid
                                      item
                                      xs={12}
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                      flexDirection={{xs: 'column', sm: 'row'}}
                                      sx={{fontSize: '12px'}}
                                      // ref={(el) => { messageRefs[index] = el }}
                                    >
                                      <MessageBubble
                                        sand={true}
                                        message={value}
                                        side={side}
                                        time={`${hours}:${minutes}`}
                                        thisref={listRefSand}
                                        index={index}
                                      />
                                      {/*<BoxMessage*/}
                                      {/*    id={index}*/}
                                      {/*    className="color"*/}
                                      {/*    side={side}*/}
                                      {/*>*/}
                                      {/*  <StyledMessage*/}
                                      {/*      side={side}*/}
                                      {/*      sx={{*/}
                                      {/*        maxWidth: '80%'*/}
                                      {/*      }}*/}
                                      {/*  >*/}
                                      {/*    <p*/}
                                      {/*        dangerouslySetInnerHTML={{__html: text}}*/}
                                      {/*        style={{*/}
                                      {/*          marginTop: '0px',*/}
                                      {/*          marginBottom: '0px',*/}
                                      {/*          whiteSpace: 'pre-line'*/}
                                      {/*        }}*/}
                                      {/*    />*/}
                                      {/*  </StyledMessage>*/}
                                      {/*</BoxMessage>*/}
                                    </Grid>
                                  </Grid>
                                </ListItem>
                            }
                          </>
                        )
                      })}
                    </>
                    :
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '30vh'}}>
                      <Chip
                        // onClick={() => handleOpenDialogsList('block', 'none')}
                        label={t('src.pages.Sandbox.selectChannel')}
                        variant="outlined"
                        color="primary"
                      />
                    </div>
                  }

                </div>
              </div>
              <div id="down_to_last_mess"
                   style={{position: 'relative', width: '100%', display: 'none'}}>
                <button
                  // onClick={handleScrollButtonClick}
                  id="showButton"
                  style={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px',
                    zIndex: '99',
                    cursor: 'pointer',
                    boxShadow: '1px 3px 9px #b9b9b9',
                    borderRadius: '50%',
                    height: '50px',
                    border: '0px',
                    width: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Iconify icon="material-symbols:keyboard-arrow-down" color="#9e9e9e"
                           sx={{width: 43, height: 50}}/>
                </button>
              </div>
              <Grid
                container
                sx={{
                  backgroundColor: `${theme.palette.background.paper}`,
                  px: 2, py: 1, width: '100%', borderTop: '1px solid', borderColor: 'divider'
                }}>
                <Grid
                  item
                  xs={10} sm={10} md={11} lg={11} xl={11}
                  sx={{
                    paddingRight: '8px',
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    id="draftMessageField"
                    //value={draftMessage}
                    variant="standard"
                    //onChange={handleChangeDraftMessage}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') {
                        handleSendMessage()
                      }
                    }}
                    placeholder={t('src.pages.Sandbox.draftMessageField', 'Отправка текста')}
                    fullWidth
                    multiline
                    maxRows={3}
                    InputProps={{
                      disableUnderline: true,
                      style: {fontSize: 18}
                    }}
                    sx={{width: '100%'}}
                    disabled={sseChannelError || !sandboxChannel}
                  />
                </Grid>
                <Grid
                  item xs={2} sm={2} md={1} lg={1} xl={1}
                  align="right"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'flex-end'
                  }}
                >
                  <Fab color="primary"
                       onClick={handleSendMessage}
                       type="button"
                       disabled={sseChannelError || !sandboxChannel}
                  >
                    <SendIcon/>
                  </Fab>
                </Grid>
              </Grid>
            </PaperDiv>
          </div>
        </Card>
    </Box>
  )
}

export default Sandbox