import PropTypes from 'prop-types'
import {Avatar, Badge, Box, Button, Divider, Drawer, Grid, Link, ListItemText, Typography} from '@mui/material'
import useResponsive from '../../hook/useResponsive'
import { useTranslation } from 'react-i18next'
import Scrollbar from '../../components/scrollbar/Scrollbar'
import NavSection from '../../components/nav-section/NavSection'
import SearchFieldInAppBar from '../header/SearchFieldInAppBar'
import {AspexIcon, NAV_WIDTH, NAV_WIDTH_CLOSE} from '../../constants'
import {RenderedContent, StyledAccount, StyledBox, StyledNavItem} from '../../theme/standarts_styles'
import { useSelector } from 'react-redux'
import { useActions } from '../../hook/useActions'
import {useEffect, useState} from 'react'
import React from "react";
import AccountCompany from "./AccountCompany";
import {useMediaQuery} from "react-responsive";
import {useAuth} from "../../hook/useAuth";
import {useTheme} from "@mui/material/styles";
import {Icon} from "@iconify/react";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguagePopover from "../header/LanguagePopover";



export default function Nav () {

  const { t } = useTranslation()
  const theme = useTheme();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 790px)'
  })

  const isDesktop = useResponsive('up', 'lg')
  const isMobile = useResponsive("down", "sm");
  const { openNav } = useSelector(state => state.serviceReducer)
  const { signOut } = useAuth();

  const {
    toggleOpenNav,
    setOpenNav,
  } = useActions()

  const handleLogout = () => {
    signOut();
  };

  const {
    last_name,
    email,
    avatar,
  } = useSelector(
    (state) => state.authReducer,
  );

  useEffect(() => {
    // if (!isDesktop && openNav) {
    //   toggleOpenNav()
    // }
    if (!isDesktopOrLaptop) {
      setOpenNav(false);
    }
  }, [isDesktopOrLaptop, setOpenNav]);
  const {sseChannelError} = useSelector(state => state.serviceReducer)

  const [isOpen, setIsOpen] = useState(true)

  const renderContent = (
    <RenderedContent openNav={openNav}>
      <Box>
        <Stack
          direction="row"
          sx={{
            px: 1,
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {openNav ? (
            <Box
              sx={{
                width:'100%',
                display:'flex',
                justifyContent: 'flex-start',
                alignItems:"center",
              }}
            >
              <Button
                onClick={() => toggleOpenNav()}
                sx={(theme) => ({
                  py: 1,
                  px: 1.5,
                  color: "text.primary",
                })}
                variant="text"
              >
                <Icon
                  height="24"
                  icon="material-symbols-light:menu-open-rounded"
                  sx={{ color: "text.primary" }}
                  width="24"
                />
              </Button>
              <Link href="/">
                <Box
                  sx={{
                    ml: 0,
                    cursor: "pointer",
                  }}
                >
                  <AspexIcon
                    color={theme.palette.text.primary}
                    height={isMobile ? 10 : 11}
                  />
                </Box>
              </Link>
            </Box>
          ) : (
            <Button
              onClick={() => toggleOpenNav()}
              sx={(theme) => ({
                p: 1,
                // m:1,
                color: "text.primary",
              })}
              variant="text"
            >
              <Icon
                height="24"
                icon="material-symbols-light:menu-rounded"
                sx={{ color: "text.primary" }}
                width="24"
              />
            </Button>
          )}
        </Stack>
        <Box sx={{ my:1.5}}>
          {openNav ? (
            <Stack
              direction="row"
              sx={{
                px: 1.5,
                my: 1,
              }}
            >
              <SearchFieldInAppBar width="100%" />
            </Stack>
          ) : (
            <Box
              sx={{
                color: "text.primary",
                m: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon
                height="22"
                icon="material-symbols-light:search"
                onClick={() => toggleOpenNav()}
                sx={{ color: "text.secondary" }}
                width="22"
              />
            </Box>
          )}
        </Box>
        <NavSection />
      </Box>

      {isDesktopOrLaptop &&
        <Box
          sx={{
            px: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              mb:1
            }}
          >
            <LanguagePopover inNav={true}/>
          </Box>
          <Divider/>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box sx={{width:'100%'}}>
              <StyledNavItem
                onClick={() => setIsOpen(!isOpen)}
                sx={{
                  px: openNav && 1,
                  mb:1,
                  mt:1,
                  height: "40px",
                }}
              >
                {openNav ? (
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Badge
                      color={sseChannelError ? 'error' : 'success'}
                      variant='dot'
                      overlap='circular'
                      badgeContent=' '
                      sx={{mr: isDesktop ? 1 : 0}}
                    >
                      <Avatar
                        alt="photoURL"
                        src={avatar}
                        sx={{
                          width: "28px",
                          height: "28px",
                          mr: isDesktop ? 1 : 0,
                        }}
                      />
                    </Badge>
                    <Stack
                      direction="column"
                      sx={{
                        width: "60%",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          color: "text.primary",
                          textAlign: "start",
                          fontWeight: "bold",
                        }}
                        variant="body3"
                      >
                        {last_name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          textAlign: "start",
                          fontSize: "10px",
                        }}
                        variant="body3"
                      >
                        {email}
                      </Typography>
                    </Stack>
                    <ExpandMoreIcon
                      fontSize="small"
                      sx={{
                        color: "text.primary",
                        transform: `${!isOpen && 'rotate(180deg)'}`,
                        transition: 'transform .2s ease-out',
                      }}
                    />
                  </Stack>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      direction: "column",
                    }}
                  >
                    <Badge
                      color={sseChannelError ? 'error' : 'success'}
                      variant='dot'
                      overlap='circular'
                      badgeContent=' '
                      sx={{mr: isDesktop ? 1 : 0}}
                    >
                      <Avatar
                        alt="photoURL"
                        src={avatar}
                        sx={{
                          width: "32px",
                          height: "32px",
                          border: !openNav && "2px solid #fafafa",
                        }}
                      />
                    </Badge>
                  </Box>
                )}
              </StyledNavItem>
            </Box>
            <Collapse in={!isOpen} sx={{ width:'100%' }}>
              <>
                <StyledNavItem
                  href='/account'
                  sx={{color: "text.primary",}}>
                  {openNav ? (
                    <Stack
                      direction="row" px={1} spacing={1}
                    >
                      <Icon height="20" icon="material-symbols-light:account-circle-outline" width="20"  />
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="body2">
                            {t("src.layouts.dashboard.header.AccountPopover.munuOption1")}
                          </Typography>
                        }
                      />
                    </Stack>
                  ) : (
                    <Box
                      sx={{
                        width:'100%',
                        display:'flex',
                        alignItems:'center',
                        direction:'column',
                        justifyContent:'center',
                      }}
                    >
                      <Icon height="20" icon="material-symbols-light:account-circle-outline" width="20"  />
                    </Box>

                  )}
                </StyledNavItem>
                <Divider sx={{ borderStyle: "dashed", color: "divider" }} />
                <StyledNavItem onClick={handleLogout} sx={{color:'error.darker'}}>
                  {openNav ? (
                    <Stack
                      direction="row"
                      px={1}
                      spacing={1}
                    >
                      <Icon height="20" icon="material-symbols-light:logout-rounded" width="20"  />
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="body2">
                            {t("src.layouts.dashboard.header.AccountPopover.exit")}
                          </Typography>
                        }
                      />
                    </Stack>
                  ) : (
                    <Box
                      onClick={handleLogout}
                      sx={{
                        display: "flex",
                        alignItems:'center',
                        justifyContent: 'center',
                        flexDirection:'column',
                        width:'100%',
                      }}
                    >
                      <Icon height="20" icon="material-symbols-light:logout-rounded" width="20"  />
                    </Box>
                  )}
                </StyledNavItem>
              </>
            </Collapse>
          </Box>

        </Box>
      }
    </RenderedContent>
  )

  return (
    <Box
      component="nav"
      sx={(theme) => ({
        flexShrink:  0 ,
        height:'100vh',
        backgroundColor: "transparent",
        boxShadow: 'none',
        [theme.breakpoints.up("790")]: {
          width: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE,
        },
        [theme.breakpoints.down("790")]: {
          width: openNav && NAV_WIDTH,
        },
      })}
    >
      <Drawer
        PaperProps={(theme) => ({
          sx: {
            width: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE,
            overflow: "hidden",
            boxShadow: 'none',
            [theme.breakpoints.up("790")]: {
              visibility: "visible",
            },
            [theme.breakpoints.down("790")]: {
              visibility: "hidden",
            },
          },
        })}
        onClose={toggleOpenNav}
        open={openNav}
        sx={(theme)=>({
          '& .MuiDrawer-paper':{
            width: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE,
            height:'100vh',
            backgroundColor: 'transparent',
            transition: "all 0.2s ease",
            p:0
          },
        })}
        variant={ isDesktopOrLaptop ? "permanent" : "temporary"}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}
