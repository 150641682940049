import React, {useState} from 'react'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography
} from '@mui/material'
import {useAuth} from '../../hook/useAuth'
import {NavLink, useLocation} from 'react-router'
import {useTranslation} from 'react-i18next'
import useResponsive from '../../hook/useResponsive'
import {desiredLocations} from '../../constants'
import {useSelector} from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ThemeSwitch from "../../components/ThemeSwitch";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function AccountPopover() {
  const {t} = useTranslation()

  const MENU_OPTIONS = [
    {
      label: t('src.layouts.dashboard.header.AccountPopover.munuOption1'),
      icon: 'eva:settings-2-fill',
      path: '/account',
    },
  ]
  const [open, setOpen] = useState(null)
  const isDesktop = useResponsive('up', 'lg')
  const isMobile = useResponsive("down", "sm");

  const {signOut} = useAuth()
  const location = useLocation()
  const currentLocation = desiredLocations.includes(location.pathname)

  const {sseChannelError} = useSelector(state => state.serviceReducer)

  const {
    last_name,
    email,
    avatar,
  } = useSelector(state => state.authReducer)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    signOut()
    setOpen(false)
  }

  return (
    <>
      <Button
        color="primary"
        onClick={handleOpen}
        sx={(theme) => ({
          paddingLeft: 1,
          paddingRight: 1,
        })}
        variant="text"
      >
        <Stack alignItems="center" direction="row" spacing={isMobile ? 0.5 : 1}>
          <Badge
            color={sseChannelError ? 'error' : 'success'}
            variant='dot'
            overlap='circular'
            badgeContent=' '
            sx={{mr: isDesktop ? 1 : 0}}
          >
            <Avatar
              src={avatar}
              alt='photoURL'
              sx={{
                width: "24px",
                height: "24px",
                mr: isDesktop ? 1 : 0,
              }}
            />
          </Badge>
          {!isMobile && (
            <Stack direction="column">
              <Typography
                sx={{
                  color: "text.primary",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
                variant="body3"
              >
                {last_name}
              </Typography>
            </Stack>
          )}
          <MoreVertIcon
            fontSize="small"
            sx={{
              color: "text.primary",
            }}
          />
        </Stack>
      </Button>


      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        sx={{zIndex: '10000'}}
        PaperProps={{
          sx: {
            zIndex: 5001,
            backgroundColor: 'background.neutral',
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle2" noWrap>
            {last_name}
          </Typography>
          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: 'dashed', color: 'divider'}}/>

        {/*more navigation*/}
        {!currentLocation &&
          <>
            <Stack sx={{p: 1}}>
              {MENU_OPTIONS.map((option, index) => (
                <NavLink to={option.path} style={{fontStyle: 'none', textDecoration: 'none', color: 'inherit'}}
                         onClick={handleClose} key={index}>
                  <MenuItem key={option.label}>
                    {option.label}
                  </MenuItem>
                </NavLink>
              ))}
            </Stack>
            <Divider sx={{borderStyle: 'dashed', color: 'divider'}}/>
          </>
        }
        {/*<MenuItem sx={{m: 1}}>*/}
        {/*  <ThemeSwitch/>*/}
        {/*</MenuItem>*/}
        {/*<Divider sx={{borderStyle: 'dashed', color: 'divider'}}/>*/}

        <MenuItem onClick={handleLogout} sx={{m: 1}}>
          {t('src.layouts.dashboard.header.AccountPopover.exit', 'Выйти', 'ru')}
        </MenuItem>
      </Popover>
    </>
  )
}
