import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  isWarningModalOpen: false,
  isInitAuth: false,
  userId: null,
  last_name: null,
  email: null,
  startTime: null,
  avatar: null,
  name: null,
  roles: null,
  status: null,
  locked_flags: null,
  groups: null,
  token: null,
  refreshToken: null,
  tokenLifetime: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut() {
      return initialState
    },
    // logIn (state, action) {
    //   const { userId, last_name, token, email, startTime, avatar, name, role, status, locked_flags, groups} = action.payload
    //   return {
    //     ...state,
    //     userId, last_name, token, email, startTime, avatar, name, role, status, locked_flags, groups,
    //     isInitAuth: true,
    //   }
    // },
    updateUser(state, action) {
      const {userId, last_name, email, avatar, name, roles, status, locked_flags, groups} = action.payload
      return {...state, userId, last_name, email, avatar, name, roles, status, locked_flags, groups}
    },
    setWarningModalOpen(state, {payload}) {
      state.isWarningModalOpen = payload
    },
    setToken(state, {payload}) {
      state.token = payload;
    },
    setRefreshToken(state, {payload}) {
      state.refreshToken = payload;
    },
    setTokenLifetime(state, {payload}) {
      state.tokenLifetime = payload;
    },
    setStartTime(state, {payload}) {
      state.startTime = payload;
    },
  },
})

export const {actions: authActions, reducer: authReducer} = authSlice
export default authSlice.reducer
