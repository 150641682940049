import React, {Suspense} from 'react'
import {AuthProvider} from './hoc/authProvider'
import {LoadProvider} from './hoc/loadProvider'
import ThemeProvider from './theme'
import LoadingSpinner from './components/modals/loadingSpinner'
import Router, {getRoutes} from './routes';

import {createBrowserRouter} from 'react-router';
import {RouterProvider} from 'react-router/dom';


const router = createBrowserRouter(getRoutes());

function App() {
  return (
    <ThemeProvider>
      <Suspense fallback={<LoadingSpinner/>}>
        {/*<AuthProvider>*/}
        {/*  <LoadProvider>*/}
        <RouterProvider router={router}/>
        {/*    <Router/>*/}
        {/*  </LoadProvider>*/}
        {/*</AuthProvider>*/}
      </Suspense>
    </ThemeProvider>
  )
}

export default App
