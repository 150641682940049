import React from 'react'
import { styled } from '@mui/material/styles'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import { useActions } from '../../hook/useActions'
import Iconify from '../iconify'
import { $authHost } from '../../http'
import MessageWrapper from './MessageWrapper'
import { BoxMessage, StyledMessage } from '../../theme/standarts_styles'

const MessageBubble = (props) => {
  const { message, side, time, thisref, index } = props
  const { is_read: isRead, id: id, is_favorite: flag, text } = message
  const { changeMessageFlag } = useActions()

  const TimeStamp = styled('p')(({ theme }) => ({
    width: '100%',
    textAlign: side,
    marginBottom: 2,
    marginTop: 1,
    fontSize: '10px',
    padding: '0 auto',
    color: theme.palette.grey[600],
  }))

  // const BookmarkBorderIcon = styled('div')(({ theme }) => ({
  //     // width: '100%',
  //     textAlign: side,
  //     // marginBottom: 2,
  //     // marginTop: 1,
  //     // fontSize: '12px',
  //     color: dark
  // }));

  // const Flag = () => {
  //     return flag
  //         ? <BookmarkIcon />
  //         : <BookmarkBorderIcon />
  // }

  const handleFlagClick = async () => {
    try {
      await $authHost.post(`/dialogues/message/add_to_favorite/${id}?bool=${!flag}`).then(response => {
        if (response.status === 201) {
          changeMessageFlag(id)
        }
      })
    } catch (e) {
    }
  }

  return (
    <>
      {/*<TimeStamp>{user}</TimeStamp>*/}
      <BoxMessage id={id} className="color" side={side}>
        <MessageWrapper message={message} sandbox={props.sand}>
          <StyledMessage side={side}>
            <p
                dangerouslySetInnerHTML={{ __html: text}}
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  whiteSpace: 'pre-line'
                }}
            />
          </StyledMessage>
        </MessageWrapper>
        {props.sand === false &&
            <>
              {String(flag) === 'true'
                  ? <BookmarkIcon onClick={handleFlagClick} color="disabled"/>
                  : <BookmarkBorderIcon onClick={handleFlagClick} color="disabled"/>}
            </>
        }
      </BoxMessage>
      {
        side === 'right' ?
          <TimeStamp>
            {props.sand === false &&
                <>
                  {
                    isRead ?
                        <Iconify icon="eva:done-all-fill" sx={{ verticalAlign: '-4px', ml: 1 }}/>
                        :
                        <Iconify icon="eva:checkmark-fill" sx={{ verticalAlign: '-4px', ml: 1 }}/>
                  }
                </>
            }
            {time}
          </TimeStamp>
          :
          <TimeStamp>
            {time}
            {props.sand === false &&
                <>
                  {
                    isRead ?
                        <Iconify icon="eva:done-all-fill" sx={{ verticalAlign: '-4px', ml: 1 }}/>
                        :
                        <Iconify icon="eva:checkmark-fill" sx={{ verticalAlign: '-4px', ml: 1 }}/>
                  }
                </>
            }

          </TimeStamp>
      }
    </>
  )
}

export default React.memo(MessageBubble)
