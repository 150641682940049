import React from 'react'
import TextField from '@mui/material/TextField'
import { useFormContext } from 'react-hook-form'

const CustomTextField = ({ label, name, required, registerOptions, secondErrorText, helperText, sx, ...rest }) => {

  const {
    register,
    formState: {
      errors,
    },
  } = useFormContext()

  const error = errors[name]
  const req = !!registerOptions?.required  // || required

  return (
    <div style={{
      position: 'relative',
      marginBottom: '24px',
      width: '100%'
    }}>
      <TextField
        {...register(name, registerOptions)}
        {...rest}
        label={`${label}${req ? ' *' : ''}`}
        variant="outlined"
        error={!!error} // Установите error на основе наличия ошибки из react-hook-form
        InputLabelProps={{
          shrink: true,
        }}
        sx={(theme) => ({
          '& .MuiOutlinedInput-root':{
            '& fieldset': {
              borderColor: `${theme.palette.divider}`,
            },
          },
          width:'100%',
          '& input': {
            fontSize:'15px'
          },
          '& .MuiInputBase-inputMultiline':{
            fontSize:'15px'
          },
          ...sx
        })}
      />
      {!error &&
        <span
          style={{
            position: "absolute",
            bottom: "-18px",
            fontSize: "12px",
            color: '#bcbcbc',
            left: 14, // Выравнивание по левому краю
          }}
        >
          {helperText && helperText}
        </span>
      }
      <span
        style={{
          position: "absolute",
          bottom: "-18px",
          fontSize: "12px",
          color: "red",
          left: 14, // Выравнивание по левому краю
        }}
      >
        {error && (error?.message || 'Unknown error!')}
      </span>
    </div>
  )
}

export default CustomTextField
