import React from 'react';
import {Avatar} from "@mui/material";
import {useTheme} from "@mui/material/styles";

const AvatarUser = ({user, ...rest}) => {
    const theme = useTheme()

    return (
        <Avatar
            sx={{
                backgroundColor: `${theme.palette.secondary.lighter}`,
            }}
            alt={user}
            {...rest}
        >
        </Avatar>
    );
};

export default AvatarUser;