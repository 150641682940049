import React from 'react'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { baseUrl } from '../../http/baseRoute'

function MuiBreadcrumbs ({ currentLocation }) {

  const { t } = useTranslation()
  let params = useParams()


  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    const paths = location.pathname.split('/').filter((path) => path !== '')
    // Добавляем главную страницу
    breadcrumbs.push({ name: t('main.routes.home', 'Главная'), path: '/' })

    paths.reduce((prevPath, currentPath, index) => {
      const path = `${prevPath}/${currentPath}`
      breadcrumbs.push({ name: t(`main.routes.${path.replace(/^\//, '')}`), path, currentPath })
      return path
    }, '')

    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <Breadcrumbs separator="-" aria-label="breadcrumb" sx={{ mb: 2 }}>
      {breadcrumbs.map((breadcrumb, index) => {

          if (breadcrumb.path.endsWith('/edit') || breadcrumb.path.endsWith('/availability') || breadcrumb.path.endsWith('/general') || params?.id === breadcrumb.currentPath) {
            return
          }
          return (
            <Link
              key={breadcrumb.path}
              color="inherit"
              underline="hover"
              href={breadcrumb.path}
            >
              <Typography color="text.secondary" variant="body2">
                {breadcrumb.name}
              </Typography>
            </Link>)
        },
      )}
    </Breadcrumbs>
  )

}

export default React.memo(MuiBreadcrumbs)
