import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import {Box, Typography} from '@mui/material'
import DynamicTabs from '../../components/Tabs/DynamicTabs'
import AccessUserPage from './AccessUserPage'
import AccessGroupsPage from './AccessGroupsPage'
import AccessSsoSettingsPage from './AccessSsoSettingsPage'
import {TitleStack} from "../../theme/standarts_styles";
import TitleAndButtonComponent from "../../components/TitleAndButtonComponent";
import {useNavigate} from "react-router";

const AccessControl = () => {

  const { t } = useTranslation()
  const navigate = useNavigate();

  return (
    <Box sx={{mb:2}}>
      <TitleAndButtonComponent
        // action={handleGoToAddPage}
        back={() => {
          navigate("/");
        }}
        backTitle={t('main.routes.home')}
        // name={t('src.pages.Channels.newChannel')}
        title={t('src.pages.AccessControl.head')}
        type={true}
      />
      <DynamicTabs
        components={[
          { label: t('src.pages.AccessControl.tab1'), component: <AccessUserPage/> },
          { label: t('src.pages.AccessControl.tab2'), component: <AccessGroupsPage/> },
          { label: t('src.pages.AccessControl.tab3'), component: <AccessSsoSettingsPage/> },
        ]}
      />
    </Box>
  )
}

export default AccessControl