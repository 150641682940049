import React, { forwardRef } from 'react';
import {Box, Button, Link} from '@mui/material';
import useResponsive from "../hook/useResponsive";
import {useSelector} from "react-redux";
import {AspexIcon, blackLogo, lightLogo, NAV_WIDTH} from "../constants";
import {useActions} from "../hook/useActions";
import Stack from "@mui/material/Stack";
import {Icon} from "@iconify/react";
import {useTheme} from "@mui/material/styles";

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

    const { status } = useSelector(state => state.authReducer)
    const isDesktop = useResponsive('up', 'lg')
    const { isDarkTheme, sseManagerStatus } = useSelector(state => state.serviceReducer)
    const srcLogo = isDarkTheme ? lightLogo : blackLogo
    const theme = useTheme();

    const { openNav } = useSelector(state => state.serviceReducer)
    const {
        toggleOpenNav,
    } = useActions()

    const isMobile = useResponsive('down', 'sm')

    return (
      <>
          {status === 'Active' ? (
            <Box
              sx={{
                  p: 0,
                  ml:1,
                  width: "max-content",
              }}
            >
                <Stack
                  direction="row"
                  sx={{
                      py: 0,
                      // width: "max-content",
                      alignItems:'center',
                      justifyContent: 'flex-start',
                  }}
                >
                    <Button
                      color="primary"
                      onClick={() => toggleOpenNav()}
                      sx={(theme) => ({
                          px: 1,
                          color:'text.primary',
                      })}
                      variant="text"
                    >
                        {openNav ?
                          <Icon
                            height="22"
                            icon="material-symbols-light:menu-open-rounded"
                            width="24"
                          />
                          :
                          <Icon
                            height="22"
                            icon="material-symbols-light:menu-rounded"
                            width="24"
                          />
                        }

                    </Button>

                    <Link href="/">
                        <Box
                          sx={{
                              // ml: isMobile ? 0 : 3,
                              cursor: "pointer",
                              ...sx,
                          }}
                        >
                            <AspexIcon
                              color={theme.palette.text.primary}
                              height={isMobile ? 10 : 11}
                            />
                        </Box>
                    </Link>
                </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                  p: 0,
                  width: NAV_WIDTH,
              }}
            >
                <Box
                  component="img"
                  src={srcLogo}
                  sx={{
                      height: 20,
                      ml: 1,
                      cursor: "pointer",
                      ...sx,
                  }}
                />
            </Box>
          )}
      </>
    );
});


export default Logo;
