import {useCallback, useState} from 'react';
import { Handle, Position } from 'reactflow';
import {Grid, TextField, Typography} from "@mui/material";
import i18next from "i18next";

function InfoNode({ data, isConnectable }) {

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="info-node-puzzle"
        >
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div>
                <Typography variant="body25" sx={{fontSize: '4pt'}} align="center">
                    {i18next.t(data.label)}
                </Typography>
            </div>
            {/*<Handle type="target" id="L" position={Position.Left} isConnectable={isConnectable} />*/}
            {/*<Handle type="target" id="R" position={Position.Right} isConnectable={isConnectable} />*/}
            <Handle type="source" id="B" position={Position.Bottom} isConnectable={isConnectable} />
        </Grid>
    );
}

export default InfoNode;
