import React, {createContext, useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useActions} from '../hook/useActions'
import TimeoutWarning from '../components/modals/TimeoutWarning'
import {useNavigate} from 'react-router'
import {$authHost} from '../http'

export const AuthContext = createContext(null)

export const AuthProvider = ({children}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    logOut,
    setWarningModalOpen,
    setChats,
  } = useActions()

  const {
    isWarningModalOpen,
    token,
    tokenLifetime,
    email,
    userId,
    last_name,
    avatar,
    name,
    roles,
    status,
    startTime,
  } = useSelector(state => state.authReducer)

  const {selectedChannels} = useSelector(state => state.dialoguesReducer)

  const {sseChannelStatus} = useSelector(state => state.serviceReducer)

  const signOut = () => {
    logOut()
    // localStorage.clear() // Чистим истирию redux-persist пока не удалять
    navigate('/login')
    dispatch({type: 'STOP_SSE_WORKER'})
  }

  const sessionLifeTime = tokenLifetime; //время через которое сессия должна быть окончена
  const warningTime = 600000; //за какое время перед окончанием сессии нужно предупредить пользователя


  useEffect(() => {
    // Запуск таймеров проверки жизни сессии
    if (startTime !== null) {
      const now = new Date().getTime();
      const remainingSessionLifeTime = sessionLifeTime - (now - startTime);
      const remainingWarningTime =
        sessionLifeTime - warningTime - (now - startTime);
      const timerSessionLifeTime = setTimeout(() => {
        signOut();
      }, remainingSessionLifeTime);
      const timerWarningTime = setTimeout(() => {
        if (isWarningModalOpen === false) {
          setWarningModalOpen(true);
        }
      }, remainingWarningTime);
      return () => {
        clearTimeout(timerSessionLifeTime);
        clearTimeout(timerWarningTime);
      };
    }
  }, [token])

  const loadData = useCallback(() => {
    try {
      const newChatList = async () => {
        let response;
        switch (selectedChannels.length >= 0) {
          case false:
            response = await $authHost.get(`dialogues/`);
            break;
          default:
            response = await $authHost.get(`dialogues/?${selectedChannels.map(function (el) {
              return `channel_ids=${el.id}`;
            }).join('&')}`);
            break;
        }
        return response.data
      }
      newChatList().then((data) => {
        setChats(data)
      })
    } catch (e) {
    }
  }, [])

  useEffect(() => { // Запуск SSE и загрузка диалогов с проверкой на статус
    const onUnload = () => dispatch({type: 'STOP_SSE_WORKER'})
    if (status === 'Active' && !sseChannelStatus) {
      // запускаем SSE и загружаем данные диалогов при монтировании компонента
      dispatch({type: 'START_SSE_WORKER'})
      loadData()
    }
    window.addEventListener('beforeunload', onUnload)
    return () => {
      // закрываем SSE при размонтировании компонента
      onUnload()
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [status])

  const value = {
    signOut,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
      {isWarningModalOpen &&
        <TimeoutWarning
          isOpen={isWarningModalOpen}
          onRequestClose={() => {
            setWarningModalOpen(false)
          }}
        />
      }
    </AuthContext.Provider>
  )
}