import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
//mui
import {
  Autocomplete,
  Avatar,
  Box, Button,
  Card,
  Container,
  Divider,
  Fab,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  TextField,
  Typography,
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Checkbox from '@mui/material/Checkbox'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import EmailIcon from '@mui/icons-material/Email'
import {useSelector} from 'react-redux'
import {$authHost} from '../http'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Iconify from '../components/iconify'
import {alpha, useTheme} from '@mui/material/styles'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import HourglassFullIcon from '@mui/icons-material/HourglassFull'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import BackHandIcon from '@mui/icons-material/BackHand'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined'
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread'
import MessageBubble from '../components/Messages/MessageBubble'
import {useTranslation} from 'react-i18next'
import {useActions} from '../hook/useActions'
import Chip from '@mui/material/Chip'
import SearchResult from '../components/searchResult'
import CloseIcon from '@mui/icons-material/Close'
import {PaperDiv, StyledChipForChats, StyledListItemForChats, TitleStack} from "../theme/standarts_styles";
import Collapse from "@mui/material/Collapse";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {generateColor, generateManagerColor} from "../utils/ColoredChips";
import ChatListElement from "../components/ChatListElement";
import AvatarUser from "../components/AvatarUser";
import useResponsive from "../hook/useResponsive";
import {ArrowBack} from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TitleAndButtonComponent from "../components/TitleAndButtonComponent";
import {useNavigate} from "react-router";
import {Icon} from "@iconify/react";

const MemoizedCheckbox = React.memo(Checkbox)

const Dialogs = () => {

  //перетащить в sse
  const [anchorEl, setAnchorEl] = useState(null)
  const {t} = useTranslation()
  const listRef = useRef(null)
  const isMobile = useResponsive('down', 'md')
  const navigate = useNavigate();

  const {
    setSelectedCustomer,
    setDialog,
    setChats,
    changeFavoriteChat,
    changeDraftMessage,
    setShowScrollButton,
    setFoundChats,
    setFoundMessages,
    setSearchValue,
    setSearchInsideValue,
    setOpenSearchInsideChat,
    setSearchMessage,
    setFlagFilter,
    setFavoriteFilter,
    setWaitFilter,
    setHandFilter,
    setArchFilter,
    setUnreadFilter,
    setUnreadMessageIndex,
    setChannels,
    setSelectedChannels,
    seIsViewChat,
    setShowSearch
  } = useActions()

  const {
    dialog,
    chats,
    searchValue,
    searchInsideValue,
    selectedCustomer,
    OpenSearchInsideChat,
    searchMessage,
    flagFilter,
    favoriteFilter,
    waitFilter,
    handFilter,
    unreadFilter,
    archFilter,
    selectedChannels,
    showSearch
  } = useSelector(state => state.dialoguesReducer)

  const { sseChannelError,  isViewChat } = useSelector(state => state.serviceReducer)
  const {channels} = useSelector(state => state.channelsReducer)
  const {userId} = useSelector(state => state.authReducer)

  let previousDate = null // Переменная для хранения предыдущей даты

  function handleOpenDialogsList(dialogsList, dialog, searchInside=null) {
    if (document.getElementById('dialogsListContainer') && document.getElementById('dialogContainer') &&
      window.innerWidth <= 900) {
      document.getElementById('dialogsListContainer').style.display = dialogsList
      document.getElementById('dialogContainer').style.display = dialog
    }
    switch (true) {
      case searchInside:{
        setShowSearch(true)
        setOpenSearchInsideChat(true)
        break;
      }
      case !OpenSearchInsideChat:{
        
      }

    }

    if(searchInside === true || OpenSearchInsideChat){
      setShowSearch(true)
      setOpenSearchInsideChat(true)
      // if (!OpenSearchInsideChat) {
      // document.getElementById('searchChatValue').focus();
      // }
    }else {
      setSelectedCustomer(null);
    }

    // if (document.getElementById('channelFilter') && document.getElementById('dialogsListContainer') && document.getElementById('dialogContainer') &&
    //     window.innerWidth <= 900) {
    //   dialog === 'block' ? seIsViewChat(true) : seIsViewChat(false);
    //   document.getElementById('dialogsListContainer').style.display = dialogsList
    //   document.getElementById('dialogContainer').style.display = dialog
    //   document.getElementById('channelFilter').style.display = dialogsList
    //
    // }
    // setSelectedCustomer(null);

  }

  // const handlerChat = (SelectCustomer, selectMessage = null) => {
  //   try {
  //     const newChatList = async () => {
  //       const response = await $authHost.get(`/dialogues/by_id`, { params: { customer_id: SelectCustomer.id } })
  //       return response.data
  //     }
  //     newChatList().then((data) => {
  //       setSelectedCustomer(SelectCustomer)
  //       setDialog(data)
  //       if (window.innerWidth <= 900) {
  //         handleOpenDialogsList('none', 'block')
  //       }
  //       listRef.current.scrollTop = listRef.current.scrollHeight
  //       if (null === selectMessage) {
  //         setShowScrollButton(false)
  //       } else {
  //         setSearchMessage(selectMessage)
  //       }
  //       // listRef.current.scrollTop = listRef.current.scrollHeight
  //
  //     })
  //   } catch (e) {
  //   }
  // }

  const handleChoiceManager = async () => {
    try {
      await $authHost.post(`/dialogues/connect_manager`, {
        manager_id: userId,
        customer_id: selectedCustomer.id
      }).then(response => {
        if (response.status === 201) {
          console.log("ehf")
        }
      })
    } catch (e) {
    }
  }

  const handleSendMessage = async (text = null) => {
    const messagesText = document.getElementById('draftMessageField').value //временное решение проблемы перерендера
    try {
      let timeStamp = new Date()
      let UTC_timeStamp = new Date(timeStamp.getTime() + timeStamp.getTimezoneOffset() * 60 * 1000).toISOString()
      const message = {
        'text': typeof text === 'string' || text instanceof String ? text : messagesText,
        'recipient': selectedCustomer.id,
        'sender': userId,
        'is_favorite': false,
        'is_read': true,
        'is_error': false,
        'channel_id': selectedCustomer.channel_id
      }
      await $authHost.post(`/dialogues/send_message_to_customer_tg`, message).then(response => {
        if (response.status === 201) {
          // addNewMessage(message) // убрал т.к дублируется сообщение через SSE
          changeDraftMessage('')
          document.getElementById('draftMessageField').value = ''
        }
      })
    } catch (e) {
    }
  }

  const handleChangeDraftMessage = (event) => {
    changeDraftMessage(event.target.value)
  }

  const handleFavorite = async () => {
    try {
      await $authHost.post(`dialogues/set_is_favorite?id=${selectedCustomer.id}&bool=${!selectedCustomer.is_favorite}`).then(response => {
        if (response.status === 201) {
          changeFavoriteChat(selectedCustomer.id)
        }
      })
    } catch (e) {
    }
  }

  const getChannels = useCallback(() => {
    try {
      const newChannelsList = async () => {
        const response = await $authHost.get('/channel/')
        return response.data
      }
      newChannelsList().then((data) => {
        setChannels(data)
      })
    } catch (e) {
    }
  }, [])

  const loadData = useCallback(() => {
    try {
      const newChatList = async () => {
        let response;
        switch (selectedChannels.length >= 0) {
          case false:
            response = await $authHost.get(`dialogues/`);
            break;
          default:
            response = await $authHost.get(`dialogues/?${selectedChannels.map(function (el) {
              return `channel_ids=${el.id}`;
            }).join('&')}`);
            break;
        }
        return response.data
      }
      newChatList().then((data) => {
        setChats(data)
      })
    } catch (e) {
    }
  }, [])

  const handleSetFlagFilter = () => {
    setFlagFilter(!flagFilter)
  }
  const handleSetFavoriteFilter = () => {
    setFavoriteFilter(!favoriteFilter)
  }
  const handleSetWaitFilter = () => {
    setWaitFilter(!waitFilter)
  }
  const handleSetHandFilter = () => {
    setHandFilter(!handFilter)
  }
  const handleSetUnreadFilter = () => {
    setUnreadFilter(!unreadFilter)
  }
  const handleSetArchFilter = () => {
    setArchFilter(!archFilter)
  }

  const onSearchChange = async ({target: {value}}) => {
    setSearchValue(value)
    if (value === '') {
      setFoundChats([])
      setFoundMessages([])
      setSearchMessage(null)
    } else {
      try {
        await $authHost.get(`/find_in_customers`, {params: {query: value}}).then(response => {
          setFoundChats(response.data)
        })
      } catch (e) {
      }
      try {
        await $authHost.get(`find_in_messages`, {params: {query: value}}).then(response => {
          setFoundMessages(response.data)
        })
      } catch (e) {
      }
    }
  }

  const onSearchInsideChange = async ({target: {value}}) => {
    setSearchInsideValue(value)
    if (value === '') {
      setFoundChats([])
      setFoundMessages([])
      setSearchMessage(null)
    } else {
      try {
        await $authHost.get(`find_in_messages`, {params: {query: value}}).then(response => {
          const result = response.data.filter(
            message => message.sender === selectedCustomer.id || message.recipient === selectedCustomer.id)
          setFoundMessages(result)
        })
      } catch (e) {
      }
    }
  }

  useEffect(() => {
    const list = listRef.current
    if (list) {
      if (searchMessage === null) {

        const separator = document.getElementById('newMessagesSeparator')
        separator
          ? separator.scrollIntoView({behavior: 'auto', block: 'start'})
          : list.scrollTop = list.scrollHeight
        setShowScrollButton(false)
      } else {
        if (document.getElementById(`${searchMessage}`)) {
          const el = document.getElementById(`${searchMessage}`)
          el.style.backgroundColor = `${alpha(theme.palette.primary.light, 0.3)}`;
          el.scrollIntoView({behavior: 'auto', block: 'center'})
          const timer = setTimeout(function () {
            el.style.backgroundColor = 'inherit'
          }, 2000)
          return () => {
            clearTimeout(timer)  // Сброс таймера при удалении компонента или изменении зависимостей
          }
        }
      }
    }
  }, [selectedCustomer])

  useEffect(() => {   // поиск первого индекса непрочитанных сообщений
    if (dialog && selectedCustomer?.unreadMessageIndex === null) {
      const index = dialog.findIndex((obj) => obj.is_read === false)
      if (index !== -1) {
        setUnreadMessageIndex(index)
      }
    } else if (dialog && dialog[dialog.length - 1]?.recipient === selectedCustomer?.id && listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight
    }
  }, [dialog])

  const handleScrollButtonClick = useCallback(() => {
    listRef.current.scrollTop = listRef.current.scrollHeight
    setShowScrollButton(false)
  }, [])

  const handleOpenSearchInput = () => {
    if(isMobile){
      setAnchorEl(null);
      handleOpenDialogsList('block', 'none', true)
    }else{
      if (!OpenSearchInsideChat) {
        document.getElementById('searchChatValue').focus();
      }
    }
    setFoundChats([])
    setFoundMessages([])
    setSearchValue('')
    setSearchInsideValue('')
    setOpenSearchInsideChat(!OpenSearchInsideChat)




  }

  const handleClearSearchInput = () => {
    setFoundChats([])
    setFoundMessages([])
    setSearchValue('')
    setSearchInsideValue('')
    setSearchMessage(null)
    document.getElementById('searchChatValue').value = ''
  }

  useEffect(() => {
    loadData(); // перенес в логин
    getChannels()

    if(window.innerWidth <= 900){
      seIsViewChat(true);
    }

    //     const SSEChat = connectSSEChat();
    //     // const SSEDialogues = connectSSEDialogues();
    return () => {
      setSelectedCustomer(null)
      setDialog([])
      // SSEChat.close();
      // SSEDialogues.close();
    }
  }, [])

  useEffect(() => {
    try {
      const newChatList = async () => {
        let response;
        switch (selectedChannels.length >= 0) {
          case false:
            response = await $authHost.get(`dialogues/`);
            break;
          default:
            response = await $authHost.get(`dialogues/?${selectedChannels.map(function (el) {
              return `channel_ids=${el.id}`;
            }).join('&')}`);
            break;
        }
        return response.data
      }
      newChatList().then((data) => {
        setChats(data)
      })
    } catch (e) {
    }
    return () => {
      setSelectedCustomer(null)
      setDialog([])
    }
  }, [selectedChannels])


  const unreadChats = useMemo(() => (
    chats.filter(chat => chat.msg_count > 0).length
  ), [chats])

  const handleScroll = event => {
    const scrollY = event.currentTarget.scrollHeight - event.currentTarget.scrollTop
    if (document.getElementById('down_to_last_mess') && (event.currentTarget.scrollTop > 0)) {
      scrollY > event.currentTarget.clientHeight * 2 ?
        document.getElementById('down_to_last_mess').style.display = 'block'
        :
        document.getElementById('down_to_last_mess').style.display = 'none'
    }
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const theme = useTheme()


  return (
    <Box>
      <TitleAndButtonComponent
        back={() => {
          navigate("/");
        }}
        backTitle={t('main.routes.home')}
        title={t('src.pages.Dialogs.head')}
      />
      {((!selectedCustomer && isMobile) || !isMobile) && (
        <Stack
          id="channelFilter"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{mb: 2}}
            className="listdialogsdiv"
        >
          <Autocomplete
            multiple={true}
            size="small"
            id="channels"
            isOptionEqualToValue={(option, value) => option.name === value}
            getOptionLabel={(option) => option.name ?? option}
            value={selectedChannels}
            onChange={(event, data) => {
              setSelectedChannels(data);
            }
            }
            options={channels && channels}
            fullWidth
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                placeholder={t('src.pages.Dialogs.channelPlaceholder')}
                sx={(theme) => ({
                  '& .MuiOutlinedInput-root':{
                    '& fieldset': {
                      borderColor: `${theme.palette.divider}`,
                    },
                  },
                  width:'100%',
                  '& input': {
                    fontSize:'15px'
                  },
                  '& .MuiInputBase-inputMultiline':{
                    fontSize:'15px'
                  }
                })}
              />
            )}
          />
        </Stack>
      )}

      <Card sx={{mt: {xs: 1,  md: 2, lg: 2, xl: 2} }}>
        {/*<Grid container flexDirection="row" sx={{ height: 'calc(99vh - 77px - 70px - 70px)' }}>*/}
        <Grid container flexDirection="row"
              sx={{
                height: {
                  xs:  isViewChat === true ? 'calc(99vh - 50px - 70px)' : 'calc(99vh - 50px - 77px - 50px)' ,
                  md: 'calc(99vh - 77px - 70px - 70px)',
                  lg: 'calc(99vh - 77px - 70px - 70px)',
                  xl: 'calc(99vh - 77px - 70px - 70px)'
                }
              }}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}
                id="dialogsListContainer"
                container={false}
                className="listdialogsdiv"
                // sx={{height: '100%', pb: 1, pt: 0}}
                sx={{ height: '100%', pb: 1, pt: 1 }}
          >
            {((OpenSearchInsideChat && isMobile) || (showSearch && isMobile) || !isMobile) && (
              <Grid item sx={{p: 1, display: 'flex', justifyContent: 'space-between', height: '60px'}}>
                <OutlinedInput
                  key="searchChatValue"
                  id="searchChatValue"
                  placeholder={t('src.pages.Dialogs.searchChatValue')}
                  value={OpenSearchInsideChat ? searchInsideValue : searchValue}
                  onChange={OpenSearchInsideChat ? onSearchInsideChange : onSearchChange}
                  defaultValue={chats.length}
                  fullWidth
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill"
                               sx={{color: 'text.disabled', width: 20, height: 20}}/>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearchInput}>
                        <CloseIcon/>
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            )}
            {((!OpenSearchInsideChat && isMobile) || !isMobile) &&
                <Stack
                    direction="row" alignItems="center" justifyContent="space-around"
                    sx={{px: 1, pb: 1, pt: 0}}
                >
                  {isMobile &&
                      <>
                        <IconButton onClick={() => setShowSearch(!showSearch)}>
                          <SearchIcon color={showSearch ? 'primary' : ''}/>
                        </IconButton>
                        <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                        />
                      </>
                  }
                  <MemoizedCheckbox
                    checked={favoriteFilter} onClick={handleSetFavoriteFilter}
                    icon={<Icon height="24" width="24" icon="material-symbols-light:star-outline-rounded"/>}
                    checkedIcon={<Icon height="24" width="24" icon="material-symbols-light:star-rounded"/>}
                  />
                  <MemoizedCheckbox
                    checked={waitFilter}
                    onClick={handleSetWaitFilter}
                    icon={<Icon height="24" width="24" icon="material-symbols-light:hourglass-empty-rounded"/>}
                    checkedIcon={<Icon height="24" width="24" icon="material-symbols-light:hourglass-rounded"/>}
                  />
                  <Checkbox
                    checked={handFilter}
                    onClick={handleSetHandFilter}
                    icon={<Icon height="24" width="24" icon="material-symbols-light:back-hand-outline-rounded"/>}
                    checkedIcon={<Icon height="24" width="24" icon="material-symbols-light:back-hand"/>}
                  />
                  {
                    unreadChats > 0 ?
                        <Checkbox
                          checked={unreadFilter}
                          onClick={handleSetUnreadFilter}
                          icon={<Icon height="24" width="24" icon="material-symbols-light:mark-email-unread-outline"/>}
                          checkedIcon={<Icon height="24" width="24" icon="material-symbols-light:mark-email-unread"/>}/>
                        :
                        <Icon height="24" width="24" icon="material-symbols-light:mail-outline"/>
                  }
                </Stack>
            }
            <Divider/>
            <List
              sx={{
                bgcolor: 'background.paper',
                // height: 'calc(100% - 50px - 60px)',
                height: {
                  xs:  'calc(100% - 50px)',
                  md: 'calc(100% - 50px - 60px)',
                  lg:'calc(100% - 50px - 60px)',
                  xl: 'calc(100% - 50px - 60px)'
                },
                overflowY: 'auto',
                p: 0,
              }}
            >
              {((searchValue !== '') || OpenSearchInsideChat) ?
                <SearchResult
                  favoriteFilter={favoriteFilter}
                  waitFilter={waitFilter}
                  handFilter={handFilter}
                  unreadFilter={unreadFilter}
                  listRef={listRef}
                />
                :
                chats.map((chat, index) => (
                  ((!favoriteFilter || (String(chat['is_favorite']) === 'true'))
                    && (!waitFilter || chat['is_waiting_response'])
                    && (!handFilter || !chat['is_handler_bot'])
                    && (!unreadFilter || chat['msg_count'] > 0)
                    && (!archFilter || chat['is_archive'])
                  )
                  &&
                  <ChatListElement index={index} chat={chat} listRef={listRef} searchMess={null}/>
                ))
              }
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9} container={false} id="dialogContainer"
                sx={{borderLeft: '1px solid', borderColor: theme.palette.divider}}>
            {selectedCustomer ?
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid',
                    borderColor: `${theme.palette.divider}`,
                    py: isMobile? 0.5 : 1,
                    px: isMobile? 1 : 2
                  }}
                >
                  <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', overflowX: "hidden"}}>
                    <IconButton color="primary" size="small" className="listdialogsdivOpen" onClick={() => handleOpenDialogsList('block', 'none')}>
                      <ArrowBackIosIcon size="small"/>
                    </IconButton>
                    <AvatarUser
                      user={selectedCustomer.full_name}
                      sx={(theme) => ({
                        backgroundColor: `${theme.palette.secondary.light}`,
                        width:'32px', height:'32px',
                        mr:1
                      })}
                    />
                    <Typography variant="body25">{selectedCustomer.full_name}</Typography>
                    {/*<Chip label={dialog.filter(el => el.question !== "" && el.isRead === false).length} color="secondary" size="small"  />*/}
                    <Checkbox
                      checked={(selectedCustomer && (String(selectedCustomer.is_favorite) === 'true')) ? true : false}
                      onClick={handleFavorite}
                      icon={
                        <Icon height="25" width="25" icon="material-symbols-light:star-outline-rounded"/>
                    }
                      checkedIcon={
                        <Icon height="25" width="25" icon="material-symbols-light:star-rounded"/>
                    }
                    />
                    {selectedCustomer.channel_type !== "" && selectedCustomer.channel_name !== "" &&
                      <Chip
                        color={generateColor(selectedCustomer.channel_type)}
                        variant="outlined"
                        size="small"
                        label={
                          selectedCustomer.channel_name !== "" ?
                            `${selectedCustomer.channel_type}: ${selectedCustomer.channel_name}`
                            :
                            `${selectedCustomer.channel_type}`
                        }
                      />
                    }
                  </div>
                  <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                    {isMobile ?
                      <>
                        <IconButton onClick={handleOpenMenu}>
                          <MoreVertIcon/>
                        </IconButton>
                        <Popover
                            id="opendialogOption"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            sx={{zIndex: '10000'}}
                            PaperProps={{
                              sx: {
                                p: 1,
                                mt: 1.5,
                                ml: 0.75,
                                width: 180,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                        >
                          <MenuItem onClick={handleOpenSearchInput}>
                            <SearchIcon sx={{fontSize: '20px', marginRight:'10px'}}/>
                            {t('src.pages.Dialogs.searchInChatValue')}
                          </MenuItem>
                          <MenuItem selected={flagFilter} onClick={handleSetFlagFilter}>
                            {flagFilter ?
                              <BookmarkIcon sx={{fontSize: '20px', marginRight:'10px'}}/>
                              :
                              <BookmarkBorderIcon sx={{fontSize: '20px', marginRight:'10px'}}/>
                            }
                            {t('src.pages.Dialogs.Bookmark')}
                          </MenuItem>
                        </Popover>
                      </>
                      :
                      <>
                        <IconButton onClick={handleOpenSearchInput}>
                          {OpenSearchInsideChat ?
                            <Icon color="#067bf8" icon="material-symbols-light:search-rounded" />
                            :
                            <Icon icon="material-symbols-light:search-rounded" />
                          }
                        </IconButton>
                        <Checkbox
                          checked={flagFilter}
                          onClick={handleSetFlagFilter}
                          icon={<Icon height="25" width="25" icon="material-symbols-light:bookmark-outline"/>}
                          checkedIcon={<Icon height="25" width="25" icon="material-symbols-light:bookmark"/>}/>
                        <IconButton disabled="true">
                          <MoreHorizIcon/>
                        </IconButton>
                      </>
                    }
                  </div>
                </Box>
                <div className="ContainerDiv">
                  <PaperDiv>
                    <div className="MessagesBodyDiv"
                         ref={listRef}
                         onScroll={handleScroll}
                    >
                      <div style={{position: 'relative'}}>
                        {dialog.map((value, index) => {
                          const {id, created_at, text, sender, is_read, is_favorite} = value
                          const switch_to_bot = text === '/switch_to_bot'
                          const no_response = text === '/no_response'
                          const date = new Date(created_at)
                          const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
                          const day = String(localDate.getDate()).padStart(2, '0')
                          const month = String(localDate.getMonth() + 1).padStart(2, '0')
                          const year = String(localDate.getFullYear()).slice(-2)
                          const formattedDate = `${day}.${month}.${year}г.`
                          const hours = localDate.getHours().toString().padStart(2, '0')
                          const minutes = localDate.getMinutes().toString().padStart(2, '0')

                          // Проверяем, отличается ли предыдущая дата от текущей
                          const isDifferentDate = previousDate !== formattedDate
                          previousDate = formattedDate  // Обновляем предыдущую дату

                          // Проверяем, отличается ли значение свойства is_read от предыдущего сообщения
                          const isDifferentIsRead = index > 0 && dialog[index - 1].is_read === true && is_read ===
                            false

                          return (
                            (!flagFilter || is_favorite) &&
                            <ListItem
                                key={index}
                                sx={{
                                  pt: 0,
                                  pb: 1,
                                  px:{
                                    xs: 1,
                                    md: 1,
                                    xl: 2,
                                    lg: 2
                                  }
                                }}
                            >
                              <Grid container className="dialog" sx={{
                                overflowY: 'auto',
                                maxHeight: 'calc(99vh - 230px)',
                              }}>
                                {isDifferentDate &&
                                  <Grid item xs={12} sx={{margin: '24px 0'}}>
                                    <Box sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}>
                                      <Chip
                                        label={
                                          <Typography variant="body3" color="#fff">
                                            {formattedDate}
                                          </Typography>
                                        }
                                        size="small"
                                        sx={{
                                          color: '#fff',
                                          backgroundColor: 'rgba(0,0,0,0.3)'
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                }
                                {selectedCustomer.unreadMessageIndex === index &&
                                  <Grid item xs={12}>
                                    <Box
                                      id="newMessagesSeparator"
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        backgroundColor: 'rgba(118, 176, 241, 0.3)',
                                        my: '8px',
                                      }}
                                    >
                                      <Typography variant="body2">
                                        {t('src.pages.Dialogs.unreadMessages')}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                }
                                {(switch_to_bot || no_response) &&
                                  <Grid item xs={12} sx={{margin: '24px 0'}}>
                                    <Box
                                      id="MessagesSeparator"
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        backgroundColor: 'action.hover',
                                        my: '8px',
                                      }}
                                    >
                                      <Typography variant="body2">
                                        {t('src.pages.Dialogs.switchToChat')}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                }
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  justifyContent="flex-end"
                                  alignItems="center"
                                  flexDirection={{xs: 'column', sm: 'row'}}
                                  sx={{
                                    fontSize: {
                                      xs: '9px',
                                      md: '12px',
                                      lg: '12px',
                                      xl: '12px'
                                    }
                                  }}
                                >
                                  {text && !no_response && !switch_to_bot &&
                                    <MessageBubble
                                      sand={false}
                                      message={value}
                                      side={selectedCustomer.id === sender ? 'left' : 'right'}
                                      time={`${hours}:${minutes}`}
                                      thisref={listRef}
                                      index={index}
                                    />
                                  }
                                  {!selectedCustomer.is_handler_bot && selectedCustomer.id !== sender &&
                                    !no_response && !switch_to_bot && index === dialog.length - 1 &&
                                    <Button
                                      sx={{mr: 3}}
                                      variant="text"
                                      onClick={() => handleSendMessage('/switch_to_bot')}
                                      startIcon={<SmartToyIcon/>}
                                      color="inherit"
                                      size="small"
                                    >
                                      {t('src.pages.Dialogs.switchToBot')}
                                    </Button>
                                  }
                                </Grid>
                              </Grid>
                            </ListItem>
                          )
                        })}
                      </div>
                    </div>
                    <div id="down_to_last_mess"
                         style={{position: 'relative', width: '100%', display: 'none'}}>
                      <button
                        onClick={handleScrollButtonClick}
                        id="showButton"
                        style={{
                          backgroundColor: '#ffffff',
                          position: 'absolute',
                          bottom: '16px',
                          right: '16px',
                          zIndex: '99',
                          cursor: 'pointer',
                          boxShadow: '1px 3px 9px #b9b9b9',
                          borderRadius: '50%',
                          height: '40px',
                          border: '0px',
                          width: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Iconify icon="material-symbols:keyboard-arrow-down" color="#9e9e9e"
                                 sx={{width: 33, height: 40}}/>
                      </button>
                    </div>
                    <Grid container sx={{
                      backgroundColor: `${theme.palette.background.paper}`,
                      px: 2,
                      py: 1,
                      width: '100%',
                      borderTop: '1px solid',
                      borderColor: `${theme.palette.divider}`
                    }}>
                      <Grid
                        item
                        xs={10} sm={10} md={11} lg={11} xl={11}
                        sx={{
                          paddingRight: '8px',
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <TextField
                          id="draftMessageField"
                          //value={draftMessage}
                          variant="standard"
                          //onChange={handleChangeDraftMessage}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              handleSendMessage()
                            }
                          }}
                          multiline
                          maxRows={3}
                          placeholder={t('src.pages.Dialogs.draftMessageField')}
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            style: {fontSize: 18}
                          }}
                          sx={{width: '100%'}}
                          disabled={sseChannelError}
                        />
                      </Grid>
                      <Grid
                        item xs={2} sm={2} md={1} lg={1} xl={1}
                        align="right"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent:'flex-end'
                        }}
                      >
                        <IconButton
                          color="primary"
                          onClick={handleSendMessage}
                          disabled={sseChannelError}
                          sx={(theme) => ({
                            backgroundColor:`${alpha(theme.palette.primary.light, 0.8)}`,
                            color:'common.white'
                          })}
                        >
                          <SendIcon/>
                        </IconButton>
                        {/*<Fab color="primary"*/}
                        {/*     onClick={handleSendMessage}*/}
                        {/*     type="button"*/}
                        {/*     disabled={sseChannelError}*/}
                        {/*>*/}
                        {/*  <SendIcon/>*/}
                        {/*</Fab>*/}
                      </Grid>
                    </Grid>
                  </PaperDiv>
                </div>
              </>
              :
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '30vh'}}>
                <Chip
                  onClick={() => handleOpenDialogsList('block', 'none')}
                  label={t('src.pages.Dialogs.selectDialog')}
                  variant="outlined"
                  color="primary"
                />
              </div>
            }

          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default React.memo(Dialogs)
