import CustomTextField from '../../Fields/CustomTextField'
import {useTranslation} from 'react-i18next'
import {Box, Divider, Typography} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {Controller, useFormContext} from "react-hook-form";
import React from "react";
import Stack from "@mui/material/Stack";
import {ColumnStack, RowStack} from "../../../theme/standarts_styles";

const AccessSsoSettingsFields = () => {
  const {t} = useTranslation();

  const {
    control,
    setValue,
      getValues
  } = useFormContext()


  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 2,
        }}
      >
        <Typography variant="subtitle2">
          {t('src.pages.AccessSsoPage.accessSSO')}
        </Typography>
        <Controller
          name="is_on"
          control={control}
          render={({ field }) => {
            return (
              <ToggleButtonGroup
                {...field}
                disabled={true}
                onChange={(
                  event,
                  value
                ) => {
                  if (value !== null) {
                    setValue(field.name, value);
                  }
                }}
                exclusive
                color="primary"
                size="small"

              >
                <ToggleButton
                  size="small"
                  value={true}
                  sx={{px:2}}
                >
                  Вкл.
                </ToggleButton>
                <ToggleButton
                  size="small"
                  value={false}
                  sx={{px:2}}
                >
                  Выкл.
                </ToggleButton>
              </ToggleButtonGroup>
            );
          }}
        />
      </Stack>
      <Divider/>
      <Box
        sx={{
          px: 2, pt:2
        }}
      >
        <RowStack>
          <ColumnStack customspace={0.5} full={true}>
            <Typography
              align="left"
              component="h2"
              pb={2}
              variant="miniHeader"
            >
              Подробнее
            </Typography>
            <CustomTextField
              name="client_id"
              label={t('src.pages.AccessSsoPage.clientID')}
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              size="small"
              disabled={getValues("is_on") !== true}
              fullWidth
            />
            <CustomTextField
              name="adfs_urls"
              label={t('src.pages.AccessSsoPage.providerUrl')}
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              size="small"
              disabled={getValues("is_on") !== true}
              fullWidth
            />
            <CustomTextField
              name="signout_url"
              label='SSO Sign Out URL'
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              size="small"
              disabled={getValues("is_on") !== true}
              fullWidth
            />
            <CustomTextField
              name="callback_url"
              label={t('src.pages.AccessSsoPage.CallbackUrl')}
              size="small"
              fullWidth
              disabled
            />
          </ColumnStack>
        </RowStack>

      </Box>


    </>
  )
}

export default AccessSsoSettingsFields;