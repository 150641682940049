import {useRoutes} from 'react-router'
// layouts
import DashboardLayout from './layouts/DashboardLayout'
//
import NotFoundPage from './pages/NotFoundPage'
import Dialogs from './pages/Dialogs'
import Sandbox from './pages/Sandbox'
import Login from './pages/login'
import DocLoading from './pages/notUsePages/DocLoading'
import Register from './pages/Register'
import MainPage from './pages/MainPage'
import ChannelsPage from './pages/Channels'
import AccountPage from './pages/AccountPage'
import Clients from './pages/Clients'
import {RequireAuth} from './hoc/requireAuth'
import Intelligence from './pages/Intelligence'
import ModelManagement from './pages/forms/ModelManagement'
import ClientsForm from './pages/forms/ClientsForm'
import {RequireRedirect} from './hoc/requireRedirect'
import CompletionRegistration from './pages/CompletionRegistration'
import {useSelector} from 'react-redux'
import NewEula from './pages/NewEula'
import ChangePassword from './pages/ChangePassword'
import AccessControl from './pages/AccessControl/AccessControl'
import KnowledgeBase from "./pages/KnowledgeBase";
import DownloadReports from "./pages/DownloadReports";
import ModelManagementNew from "./pages/forms/ModelManagementNew";
import {store} from "./store/store";
import KnowledgeManagement from "./pages/KnowledgeManagement";
import i18next from "i18next";
import {AuthProvider} from "./hoc/authProvider";
import TestDiagramm from "./pages/testDiagramm";
import promptConstruct from "./pages/promptConstruct";
import PromptConstruct from "./pages/promptConstruct";
import ChannelPage from "./pages/ChannelPage";
import ChannelsFormAvailability from "./pages/forms/ChannelsFormAvailability";
import ChannelsForm from "./pages/forms/ChannelsForm";
import Knowledges from "./components/Intelligence/Knowledges";
import Settings from "./components/Intelligence/Settings";
import Education from "./components/Intelligence/Education";
import UserData from "./components/Account/UserData";

// ----------------------------------------------------------------------

export const getRoutes = () => {
    // const {
    //   status,
    //   locked_flags
    // } = store.getState().authReducer;

    // const renderBasedOnFlag = (flagName, componentIfTrue) => {
    //   return (status === 'Locked' && locked_flags?.name) === flagName ? componentIfTrue : <NotFoundPage/>
    // }

    return (
      [
        {
          path: '/',
          locked_flags: [{ name: 'CompleteRegistration' }, { name: 'ResetPassword' }],
          element: <AuthProvider><DashboardLayout/></AuthProvider>,
          roles: [
            { name: 'base' },
            { name: 'BotManager' },
            { name: 'AccessManager' },
            { name: 'WithoutRoles' }, // для доступа к страницам, на которые роли не нужны
          ],
          children: [
            {
              path: '',
              searchText: i18next.t('main.routes.home'),
              element: <RequireAuth><MainPage/></RequireAuth>,
              roles: [
                { name: 'base' },
                { name: 'WithoutRoles' }, // для доступа к страницам, на которые роли не нужны
              ],
            },
            // {path: 'clients', element: <RequireAuth><Clients/></RequireAuth>},
            // {path: 'clients/new', element: <RequireAuth><ClientsForm/></RequireAuth>},
            // { path: 'users', element: <RequireAuth><AccessUserPage/></RequireAuth> },
            {
              path: 'access-control',
              searchText: i18next.t('main.routes.accessControl'),
              element: <RequireAuth><AccessControl/></RequireAuth>,
              roles: [{ name: 'AccessManager' },  { name: 'WithoutRoles' },],
            },
            {
              path: 'dialogs',
              searchText: i18next.t('main.routes.dialogue'),
              element: <RequireAuth><Dialogs/></RequireAuth>,
              roles: [{ name: 'BotManager' }],
            },
            {
              path: 'sandbox',
              searchText: i18next.t('main.routes.sandbox'),
              element: <RequireAuth><Sandbox/></RequireAuth>,
              roles: [{ name: 'BotManager' }],
            },
            // {path: 'doc-loading', element: <RequireAuth><DocLoading/></RequireAuth>},
            {
              path: 'intelligence',
              searchText: i18next.t('main.routes.intelligence'),
              element: <RequireAuth><Intelligence/></RequireAuth>,
              roles: [{ name: 'botManager' }],
            },
            // {
            //   path: 'knowledgeBase',
            //   searchText: i18next.t('main.routes.knowledgeBase'),
            //   element: <RequireAuth><KnowledgeBase/></RequireAuth>,
            //   roles: [{ name: 'BotManager' }],
            // },
            {
              path: 'model-management/edit/:id',
              element: <RequireAuth><ModelManagement/></RequireAuth>,
              roles: [{ name: 'BotManager' }],
              children: [
                {
                  path: 'settings',
                  element: (
                      <RequireAuth>
                        <Settings/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
                {
                  path: 'knowledge',
                  element: (
                      <RequireAuth>
                        <Knowledges/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
                {
                  path: 'training',
                  element: (
                      <RequireAuth>
                        <Education/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
              ],
            },
            {
              path: 'model-management/new',
              searchText: i18next.t('main.routes.intelligence/new-model-management'),
              element: <RequireAuth><ModelManagement/></RequireAuth>,
              roles: [{ name: 'BotManager' }],
              children: [
                {
                  path: 'settings',
                  element: (
                      <RequireAuth>
                        <Settings/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
                {
                  path: 'knowledge',
                  element: (
                      <RequireAuth>
                        <Knowledges/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
                {
                  path: 'training',
                  element: (
                      <RequireAuth>
                        <Education/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
              ],
            },
            {
              path: 'channels',
              searchText: i18next.t('main.routes.channels'),
              element: <RequireAuth><ChannelsPage/></RequireAuth>,
              roles: [{ name: 'BotManager' }],
            },
            {
              path: 'channels/new',
              searchText: i18next.t('main.routes.channels/new'),
              element: <RequireAuth><ChannelPage/></RequireAuth>,
              roles: [{ name: 'BotManager' }],
              children: [
                {
                  path: 'general',
                  element: (
                      <RequireAuth>
                        <ChannelsForm/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
                {
                  path: 'availability',
                  element: (
                      <RequireAuth>
                        <ChannelsFormAvailability/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
              ],
            },
            {
              path: 'channels/edit/:id',
              element: <RequireAuth><ChannelPage/></RequireAuth>,
              roles: [{ name: 'BotManager' }],
              children: [
                {
                  path: 'general',
                  element: (
                      <RequireAuth>
                        <ChannelsForm/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
                {
                  path: 'availability',
                  element: (
                      <RequireAuth>
                        <ChannelsFormAvailability/>
                      </RequireAuth>
                  ),
                  roles: [{ name: 'BotManager' }],
                },
              ],
            },
            {
              path: 'knowledgeManagement',
              searchText: i18next.t('main.routes.knowledgeManagement'),
              element: <RequireAuth><KnowledgeManagement visualChoice={false}/></RequireAuth>,
              roles: [{ name: 'BotManager' }],
            },
            // {
            //   path: 'diagram',
            //   searchText: i18next.t('main.routes.diagram'),
            //   element: <RequireAuth><TestDiagramm/></RequireAuth>,
            //   roles: [{ name: 'base' }],
            // },
            // {
            //   path: 'prompt-constructor',
            //   searchText: i18next.t('main.routes.diagram'),
            //   element: <RequireAuth><PromptConstruct/></RequireAuth>,
            //   roles: [{ name: 'base' }],
            // },
            {
              path: 'account',
              searchText: i18next.t('main.routes.profile'),
              element: <RequireAuth><AccountPage/></RequireAuth>,
              roles: [{ name: 'base' }],
            },
            {
              path: "account/edit/me",
              element: (
                <RequireAuth>
                  <UserData />
                </RequireAuth>
              ),
              roles: [{ name: 'base' }],
            },
            {
              path: 'primary-registration',
              locked_flags: [{ name: 'CompleteRegistration' }],
              element: <RequireAuth><CompletionRegistration/></RequireAuth>,
              roles: [{ name: 'base' }],
            },
            {
              path: 'new-eula',
              locked_flags: [{ name: 'NewEULA' }],
              element: <RequireAuth><NewEula/></RequireAuth>,
              roles: [{ name: 'base' }],
            },
            { //это какаято хрень
              path: 'change-password',
              locked_flags: [{ name: 'ResetPassword' }],
              element: <RequireAuth><ChangePassword/></RequireAuth>,
              roles: [{ name: 'base' }],
            },
            {
              path: 'login',
              element: <RequireRedirect><Login/></RequireRedirect>,
              roles: [{ name: 'WithoutRoles' }],
            },
            {
              path: 'register',
              element: <RequireRedirect><Register/></RequireRedirect>,
              roles: [{ name: 'WithoutRoles' }],
            },
            {
              path: 'reset-password',
              element: <RequireRedirect> <Register/> </RequireRedirect>,
              roles: [{ name: 'WithoutRoles' }],
            },

            {
              path: '*',
              element: <NotFoundPage/>,
              locked_flags: [{ name: 'CompleteRegistration' }, { name: 'ResetPassword' }],
              roles: [
                { name: 'base' },
                { name: 'BotManager' },
                { name: 'AccessManager' },
                { name: 'WithoutRoles' },
              ],
            },
          ],
        },
      ]
    )
  }
;

export const getFilteredRoutes = () => {
  const { locked_flags, roles } = store.getState().authReducer
  const routes = getRoutes()

  const filterRoutesRecursively = (route) => {
    if (route.roles && route.roles.length > 0) {
      const shouldIncludeWithoutRoles = roles === null && route.roles?.some(r => r.name === 'WithoutRoles')  // отображаем рут если роль пользователя отсутствует и в route есть роль 'WithoutRoles'
      const shouldIncludeWithRoles = route.roles?.some(r => roles?.some(userRole => r.name === userRole))  // отображаем рут если пользователь имеем хотя бы одну роль, которая совпадает с route ролью
      const hasMatchingLockedFlag = route.locked_flags?.some(flag => locked_flags?.name === flag.name)  // отображаем рут если пользователь имеем locked_flags, который совпадает с route locked_flags

      if ((shouldIncludeWithoutRoles || shouldIncludeWithRoles) && (!locked_flags || hasMatchingLockedFlag)) {
        if (Array.isArray(route.children)) {
          const filteredChildren = route.children.map(childRoute => filterRoutesRecursively(childRoute)).
          filter(Boolean)
          return { ...route, children: filteredChildren }
        }
        return route
      }
    }
    return null
  }

  return routes.map(route => filterRoutesRecursively(route)).filter(Boolean)
}

export default function Router() {

  const filteredRoutes = getFilteredRoutes()

  // const routes = getRoutes();

  return useRoutes(filteredRoutes);
}