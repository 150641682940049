import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {Grid, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useResponsive from "../../hook/useResponsive";

const AddEditForm = ({
                             children,
                             selectedRow,
                             onSubmit,
                             fieldsRef,
                             handleClose,
                             action
                         }) => {

    const methods = useForm({
        mode: 'onBlur',
    })
    const {
        handleSubmit,
        reset,
        getValues,
    } = methods

    const isMobile = useResponsive('down', 'sm')


    const handleClearFields = () => {
        const fieldValues = getValues()
        const fieldsToClear = Object.keys(fieldValues).reduce((acc, fieldName) => {
            // if (Array.isArray(fieldValues[fieldName])) {
            //   acc[fieldName] = []
            // } else {
            acc[fieldName] = ''
            // }
            return acc
        }, {})
        if (selectedRow) {
            reset(selectedRow) // устанавливаем значения полей из выбранной строки
        } else {
            reset(fieldsToClear) // очистка полей формы согласно имеющимся полям
        }
    }

    useEffect(() => {
        handleClearFields()
    }, [selectedRow])

    return (
        <>
            {/*{isMobile &&*/}
            {/*    <Grid*/}
            {/*        container*/}
            {/*        direction="row"*/}
            {/*        justifyContent="space-between"*/}
            {/*        alignItems="center"*/}
            {/*        sx={{mb: 2}}*/}
            {/*    >*/}
            {/*        <Grid item>*/}
            {/*            <Typography variant='subtitle1' color="primary">*/}
            {/*                {action === 'Add' ?*/}
            {/*                    'Добавить'*/}
            {/*                    :*/}
            {/*                    'Редактировать'*/}
            {/*                }*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid item>*/}
            {/*            <IconButton*/}
            {/*                onClick={handleClose}*/}
            {/*            >*/}
            {/*                <CloseIcon/>*/}
            {/*            </IconButton>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*}*/}
            <FormProvider {...methods} handleClearFields={handleClearFields}>
                <form onSubmit={handleSubmit(onSubmit)} ref={fieldsRef}>
                    {children}
                </form>
            </FormProvider>
        </>
    )
}

export default AddEditForm