import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Card, IconButton, Switch, Tooltip} from '@mui/material'
import Iconify from '../iconify'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {useActions} from '../../hook/useActions'
import {useSelector} from 'react-redux'
import {$authHost} from '../../http'
import LoadingSpinner from '../modals/loadingSpinner'
import {TableCellsWrap} from '../TableCellsWrap'
import MenuImportExport from '../Buttons/MenuImportExport'
import MaterialTable from '../MaterialTable'
import AlertMessage from '../Alert/AlertMessage'
import {ButtonStack, RowOptionsStack} from "../../theme/standarts_styles";
import EducationAddForm from "./EducationAddForm";
import EducationEditForm from "./EducationEditForm";
import Label from "../label/Label";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from "react-router";
import {Icon} from "@iconify/react";
const Education = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const tableColumns = [
    {
      accessorKey: 'question',
      header: t('src.pages.ModelManagement.question'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'answer',
      header: t('src.pages.ModelManagement.answer'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'assistant_type',
      header: t('src.pages.ModelManagement.assistant_type'),
      Cell: ({renderedCellValue, row}) => (
        // <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
        <Label color="secondary">{renderedCellValue === "breaker" ? `${t('src.pages.Intelligence.breaker')}`: `${t('src.pages.Intelligence.stylist')}`}</Label>
      ),
    },
    {
      accessorKey: 'is_studied',
      header: t('src.pages.ModelManagement.education'),
      Cell: ({renderedCellValue, row}) => (
          // <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
          <>{renderedCellValue === true ? <DoneAllIcon color="success" /> : <CloseIcon color="error"/> }</>
      ),
    },
    {
      accessorKey: 'actions',
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({renderedCellValue, row}) => (
        <RowOptionsStack>
          <Tooltip title="Редактировать">
            <IconButton
              color="secondary"
              onClick={() => {
                handleEditRow(row.original.id)
              }}
              size="small"
              sx={(theme) => ({
                borderRadius: "8px",
                border: `1px solid ${theme.palette.secondary.light}`,
              })}
            >
              <Icon
                height="20"
                icon="material-symbols-light:edit-outline-rounded"
                width="20"
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Удалить">
            <IconButton
              color="secondary"
              onClick={() => handleDeleteRow(row.original.id)}
              size="small"
              sx={(theme) => ({
                borderRadius: "8px",
                border: `1px solid ${theme.palette.secondary.light}`,
              })}
            >
              <Icon
                height="20"
                icon="material-symbols-light:delete-outline-rounded"
                width="20"
              />
            </IconButton>
          </Tooltip>
        </RowOptionsStack>
      ),
    },
  ]

  const [loading, setLoading] = useState(false)
  const [openForm, setOpenForm] = useState('')

  const {
    resetSandboxFieldForm,
    setSelectedQuestionAndAnswer,
    setQuestionsAndAnswers
  } = useActions()

  const {
    selectedManufacturers,
    questionsAndAnswers,
    selectedQuestionAndAnswer,
    selectedIntellegence
  } = useSelector(state => state.intelligensReducer)

  function handleEditRow(id_row) {
    setSelectedQuestionAndAnswer(id_row)
    setOpenForm('Update')
  }

  async function handleDeleteRow(id_row) {
    setLoading(true)
    try {
      await $authHost.delete(`/model_management/delete_row?id=${id_row}`)
      loadData()
      if (id_row === selectedManufacturers.id) {
        setOpenForm('')
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const handleAddUser = () => {
    setSelectedQuestionAndAnswer(null)
    setOpenForm(prevState => {
      return prevState === 'Add' ? '' : 'Add'
    })
  }

  const handleClose = () => {
    setOpenForm('');
  }


  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get(
            `/model_management/?breaker_id=${selectedIntellegence.breaker_assistant.id}&output_id=${selectedIntellegence.output_assistant.id}`
        )
        return response.data
      }
      newChannelList().then((data) => {
        setQuestionsAndAnswers(data)
      })
    } catch (e) {
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (!openForm && selectedManufacturers) {
      setSelectedQuestionAndAnswer(null)
      resetSandboxFieldForm()
    }
  }, [openForm])

  return (
    <>
      {loading && <LoadingSpinner/>}
      <>
        <ButtonStack>
          <Button
            onClick={handleAddUser}
            aria-haspopup="true"
            variant="outlined"
            size="small"
            startIcon={<Iconify icon="eva:plus-fill"/>}
            sx={{mb:2}}
          >
            {t('main.another.add')}
          </Button>
          {/*<MenuImportExport*/}
          {/*  exportLink={`/data_tables/manufacturer/import_file`}*/}
          {/*  importLink={`/data_tables/manufacturer/export_file`}*/}
          {/*  loadData={loadData}*/}
          {/*  setLoading={setLoading}*/}
          {/*  setOpenForm={setOpenForm}*/}
          {/*/>*/}
        </ButtonStack>
        <AlertMessage/>
        {openForm === 'Add' &&
          <EducationAddForm  handleClose={handleClose} loadData={loadData}/>
        }
        {openForm === 'Update' &&
          <EducationEditForm handleClose={handleClose} loadData={loadData}/>
        }
        <Card>
          <MaterialTable data={questionsAndAnswers} columns={tableColumns}/>
        </Card>
      </>
    </>
  )
}

export default Education;