import React from 'react'
import {useTranslation} from 'react-i18next'
import Modal from 'react-modal'
import '../../index.css'
import {useAuth} from '../../hook/useAuth'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {$authHost} from '../../http'
import {useActions} from '../../hook/useActions'
import {useSelector} from 'react-redux'
import Stack from '@mui/material/Stack'
import {jwtDecode} from "jwt-decode";


const TimeoutWarning = ({isOpen, onRequestClose}) => {
  const {
    setToken, setRefreshToken, setTokenLifetime, setStartTime,
  } = useActions()

  const {t} = useTranslation()
  const {signOut} = useAuth()
  const {isDarkTheme} = useSelector(state => state.serviceReducer)
  const {refreshToken} = useSelector(state => state.authReducer)

  const handleRefreshToken = async () => {
    try {
      const response = await $authHost.post(`/user/refresh_token`, {
        refresh_token: refreshToken,
      });
      const {
        data: {access_token, refresh_token},
        headers: {date},
      } = response;

      const startTime = new Date(date).getTime(); //localTimeMilliseconds

      const decodedToken = jwtDecode(access_token);
      const tokenLifetime =
        decodedToken.expires * 1000 - (Date.parse(date) || Date.now());

      setToken(access_token)
      setRefreshToken(refresh_token)
      setTokenLifetime(tokenLifetime)
      setStartTime(startTime)

      onRequestClose()
    } catch (e) {
      console.log(e);
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '550px',
      width: '100%',
      padding: '30px',
      fontSize: '16px',
      backgroundColor: isDarkTheme ? '#272931' : '#ffffff',
      border: 'none'
    },
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0,0,0,0.6)',
      display: 'flex',
      zIndex: '10000',
    },
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        style={customStyles}
      >
        <Typography
          variant="h5"
          sx={{mb: 2}}
        >
          {t('src.components.modals.TimeoutWarning.header')}
        </Typography>
        <Typography
          variant="body1"
        >
          {t('src.components.modals.TimeoutWarning.timeWarning')}
        </Typography>
        <Typography
          variant="body1"
        >
          {t('src.components.modals.TimeoutWarning.reauthorize',
            'Советуем переавторизоваться во избежании потери данных.')}
        </Typography>
        <br/>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0}
        >
          <Button
            variant="text"
            onClick={() => signOut()}
          >
            {t('src.components.modals.TimeoutWarning.logoutNow')}
          </Button>
          <div>
            <Button
              variant="contained"
              sx={{marginLeft: 'auto', mx: 2}}
              onClick={handleRefreshToken}
            >
              {t('src.components.modals.TimeoutWarning.extendSession', 'Продлить сессию')}
            </Button>
            <Button
              variant="text"
              onClick={onRequestClose}
            >
              {t('src.components.modals.TimeoutWarning.ok')}
            </Button>

          </div>
        </Stack>


      </Modal>
    </div>
  )
}

export default React.memo(TimeoutWarning)